import {ref} from 'vue';

export function useRefsManager() {
    const collection = ref({});

    // Установить ref для элемента
    function set(id) {
        return (el) => {
            collection.value[id] = el;
        };
    }

    // Получить ref по id
    function get(id) {
        return collection.value[id] || null;
    }

    // Удалить ref по id
    function remove(id) {
        delete collection.value[id];
    }

    function getKeys(){
        return Object.keys(collection.value);
    }

    return {
        collection,
        get,
        set,
        remove,
        getKeys
    };
}