<template>
  <tr class="sc-project-keyword-desktop table-keyword"
      :data-group-id="useKeywordsGroup.groupId"
      :class="{'is-inactive':!useKeywordsGroup.is_active && !useKeywordsGroup.is_deleted, 'is-secondary': !useKeywordsGroup.isMainGroup}">
    <td class="table-keyword__checkbox-col">
      <v-form-checkbox v-model="checkedGroup"/>
    </td>
    <td class="table-keyword__keyword-col">

      <v-sc-keyword-title :project-id="projectId" :keywords-ids="keywordsIds" @open-modal="refTooltipSettings.openModal()"/>

      <v-modal ref="refTooltipSettings" reset-default-params>
        <sc-project-keyword-group-tooltip-settings :project-id="projectId" :keywords-ids="useKeywordsGroup.keywordsIdsWithRelated"/>
      </v-modal>

    </td>
    <td class="table-keyword__recommendations-col">
      <recommendation-top100
          v-if="!useKeywordsGroup.is_deleted && !useKeywordsGroup.is_top100"
          :keyword-id="useKeywordsGroup.keywordsIds"
          :project-id="projectId"
          class="recommendation-top-100"
      />
      <recommendation-daily-limit
          v-else-if="!useKeywordsGroup.is_deleted && useKeywordsGroup.hasRecomDaily"
          :top1="useKeywordsGroup.recom_daily_top1"
          :top3="useKeywordsGroup.recom_daily_top3"
          :top10="useKeywordsGroup.recom_daily_top10"
          @choose-daily="chooseDaily"
          class="recommendation-daily-limit"
      />

    </td>
    <td class="table-keyword__chart-col">

      <chart-seo-clicks
          v-show="!useKeywordsGroup.is_deleted"
          :chart="useKeywordsGroup.chart_data"
          :no-if-null="true"
          class="chart"
          :scale-hover="!!useKeywordsGroup.is_active"
          is-blue
          is-blue-background
          is-rounded
      />

    </td>
    <td class="table-keyword__daily-limit-col">
      <div class="daily-limit-wrap" v-show="!useKeywordsGroup.is_deleted">
        <v-form-input-number-edit-v2
            type="events"
            class="daily-limit"
            :value="useKeywordsGroup.max_clicks_per_day"
            @save="saveMaxClicksPerDay"
            :filter-data="(val) => {return val >= 1;}"
            ref="inputMaxClicksPerDay"
        />
        <v-icon-svg name="delete-icon-blue" class="delete-icon" v-if="!useKeywordsGroup.is_active" @click="deleteModalConfirm.confirm()"/>

        <v-modal-confirm ref="deleteModalConfirm" @confirm="useKeywordsGroup.updateIsDeleted(true, true)" without-loading>
          <template v-slot:title>
            Delete keyword
          </template>
          <template v-slot:description>
            Do you really want to delete a keyword ? <br>Keyword will be lost
          </template>
        </v-modal-confirm>

      </div>
      <span v-show="!!useKeywordsGroup.is_deleted" class="label-is-deleted">Deleted</span>

    </td>
    <td class="table-keyword__status-col">

      <v-form-checkbox-slider
          v-show="!useKeywordsGroup.is_deleted"
          type="events"
          :value="!!useKeywordsGroup.is_active"
          @save="saveCheckboxStatus"
          ref="checkboxStatus"
          size="40"
      />
      <v-link href="#" v-show="!!useKeywordsGroup.is_deleted" class="restore" @click.prevent="useKeywordsGroup.updateIsDeleted(false, true)">Restore</v-link>

    </td>
  </tr>
</template>

<script setup>

import {useSeoClicksKeywords, useSeoClicksKeywordsGroup} from "@app-vue/stores/useSeoClicks";
import RecommendationTop100 from "@app-vue/components/Blocks/RecommendationTop100.vue";
import ChartSeoClicks from "@app-vue/components/Chart/ChartSeoClicks.vue";
import {computed, ref, watch} from "vue";
import VFormCheckboxSlider from "@app-vue/components/VForm/VFormCheckboxSlider.vue";
import VLink from "@app-vue/components/Base/VLink.vue";
import RecommendationDailyLimit from "@app-vue/components/Blocks/RecommendationDailyLimit.vue";
import ScProjectKeywordGroupTooltipSettings from "@app-vue/components/SeoClicks/ScProjectKeywordGroupTooltipSettings.vue";
import VModal from "@app-vue/components/Base/VModal.vue";
import VFormInputNumberEditV2 from "@app-vue/components/VForm/VFormInputNumberEditV2.vue";
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import VScKeywordTitle from "@app-vue/components/Base/SeoClicks/VScKeywordTitle.vue";
import VFormCheckbox from "@app-vue/components/VForm/VFormCheckbox.vue";
import VModalConfirm from "@app-vue/components/Base/VModalConfirm.vue";

const props = defineProps({
  projectId:{},
  keywordsIds:{},
});

const refTooltipSettings = ref();
const deleteModalConfirm = ref();

const useKeywordsGroup = useSeoClicksKeywordsGroup(props.projectId,props.keywordsIds);
const useKeywords = useSeoClicksKeywords(props.projectId);

const checkedGroup = ref(false);
const hasRealCheckedKeyword = computed(() => {
  return useKeywordsGroup.keywordsIds.some((keywordId) => useKeywords._listChecked[keywordId])
});
watch(() => hasRealCheckedKeyword.value, (hasCheckedKeywordId) => {
  checkedGroup.value = hasCheckedKeywordId
});
watch(() => checkedGroup.value, (checkedGroup) => {
  if(checkedGroup !== hasRealCheckedKeyword.value){
    useKeywords.$patch((state) => {
      useKeywordsGroup.keywordsIdsWithRelated.forEach((keywordId) => {
        state._listChecked[keywordId] = checkedGroup;
      });
    });
  }
});



const checkboxStatus = ref();
const saveCheckboxStatus = async (value) => {
  let [status,message] = await useKeywordsGroup.updateIsActive(value, true);
  if(!checkboxStatus.value) return;
  if(status){
    checkboxStatus.value.saveWithSuccess();
  }else{
    checkboxStatus.value.saveWithError();
  }
}

const inputMaxClicksPerDay = ref();
const saveMaxClicksPerDay = async (value) => {
  if(value === '' || value < 1){
    inputMaxClicksPerDay.value.saveWithError();
    return;
  }
  let [status] = await useKeywordsGroup.updateDailyLimit(value, true);
  if(!inputMaxClicksPerDay.value) return;
  if(status){
    inputMaxClicksPerDay.value.saveWithSuccess();
  }else{
    inputMaxClicksPerDay.value.saveWithError();
  }
}

const chooseDaily = (number) => {
  inputMaxClicksPerDay.value.setContentEditable(number);
}

</script>

<style lang="scss" scoped>
.sc-project-keyword-desktop{

  &.table-keyword{
    transition-duration: 0.2s;
    transition-property: background-color;

    > td{
      vertical-align: middle;
      padding:10px 0px;
      height:67px;
    }

    .table-keyword__checkbox-col{
      padding-left:30px;
      padding-right:10px;
    }
    .table-keyword__keyword-col{
      padding-right:10px;
    }
    .table-keyword__recommendations-col{
      padding-right:10px;
    }
    .table-keyword__chart-col{
      padding-right:16px;
    }
    .table-keyword__daily-limit-col{
      padding-right:16px;
    }
    .table-keyword__status-col{
      padding-right:30px;
    }

    &:hover{
      //background-color: #F4F9FD;
    }
  }

  .chart{
    width:120px;
    height:44px;
    @media(max-width: 1190px){
      width:111px;
    }
  }

  .label-is-deleted{
    color: #AEB7C7;
  }
  .restore{
    position: relative;
    left:-11px;
  }

  .daily-limit-wrap{
    @include flex(row,between,center,16px);
    width:100%;
  }

  .delete-icon{
    width:18px;
    flex-shrink: 0;
    cursor: pointer;
    transition-duration: 0.2s;
    &:hover{
      stroke:$secondary-blue-100;
    }
  }

  &.is-inactive{
    > td{
      background:$dark-3;
    }
    .recommendation-top-100{
      opacity:0.5;
      position:relative;
      &:after{
        content:'';
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
      }
    }
    .recommendation-daily-limit{
      opacity:0.5;
    }
    .chart{
      opacity:0.5;
    }
    .daily-limit{
      opacity:0.5;
    }
  }

  &.is-secondary{
    .table-keyword__checkbox-col,
    .table-keyword__chart-col,
    .table-keyword__daily-limit-col,
    .table-keyword__status-col{
      position:relative;
      > *{
        opacity:0;
      }
      &:after{
        content:'';
        display:block;
        position:absolute;
        width:100%;
        height:100%;
        top:0;
        left:0;
      }
    }
  }

}
</style>