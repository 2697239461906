<template>
  <div class="reco-block-more-info" ref="tooltip">
    <div class="reco-block-more-info__title">
      {{recoStore.label}}
    </div>
    <div class="reco-block-more-info__description" v-html="recoStore.recommendation"></div>
    <div class="reco-block-more-info__stats_top" v-if="recoStore.countTopValues">
      <top-sites-better :reco-metric="recoMetric" :project-uniquekey="projectUniquekey"/>
    </div>
  </div>
</template>

<script>
import RecoBlockTable from "@app-vue/components/Reco/RecoBlockTable.vue";
import ChartScale from "@app-vue/components/Blocks/ChartScale.vue";
import {chartTypes} from "@app-vue/constants/recoConstants";
import {useRecsStore} from "@app-vue/stores/useRecsStore";
import TopSitesBetter from "@app-vue/components/Blocks/TopSitesBetter.vue";

export default {
  components:{
    TopSitesBetter,
    RecoBlockTable, ChartScale
  },
  props:{
    recoMetric: {type: String, required:true},
    projectUniquekey: {},
  },
  computed:{
    viewTable(){
      return chartTypes.default === this.recoStore.chartScaleType;
    },
    recoStore: (state)=>{
      return state.recsStore.recoStoreByMetric(state.recoMetric);
    }
  },
  setup(props){
    return {
      recsStore: useRecsStore(props.projectUniquekey)
    }
  }
}
</script>

<style lang="scss" scoped>
.reco-block-more-info__title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-right:7px;
}
.reco-block-more-info__description {
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 20px;
  white-space:normal;
}
.reco-block-more-info > div:last-child{
  margin-bottom:0;
}
</style>