<template>
  <div class="lb-orders-backlinks-v2">

    <template v-if="linkBuildOrdersStore.ordersManager().ordersSortedByCreatedAt.length > 0">
      <v-table-info class="backlinks-table" is-sticky-head v-if="!isMobile">
        <thead>
        <tr>
          <th class="th-created-id">Created/ID</th>
          <th class="th-order-info">Type/Keyword/Website</th>
          <th class="th-info-blog-article">Blog Articles</th>
          <th class="th-order-status">Status</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in linkBuildOrdersStore.ordersManager().ordersSortedByCreatedAt"
            :class="['is-'+item?.statusConstant, item.is_new ? 'is-new' : '', ]">

          <td>
            <div class="order-created-id">
              <div class="order-created-id__created">{{item.createdAtFormatted}}</div>
              <div class="order-created-id__id">{{item.id}}</div>
            </div>
          </td>

          <td>
            <div class="order-info">
              <span>{{item.typeTitleShort}}</span>
              <v-link :href="item.correctUrl"
                      :text="item.urlShort"
                      is-ends-ellipsis
                      is-inherit-style
                      is-outer/>
              <span>{{item.keyword}}</span>
            </div>
          </td>

          <td>
            <div class="info-blog-article">
              <v-link v-if="item.result_url && item.statusConstant==='delivered'"
                      :href="item.result_url"
                      target="_blank"
                      is-ends-ellipsis
                      is-outer
                      :text="item.resultUrlShort"/>
              <lb-order-review-v2 v-if="item.statusConstant==='delivered'"
                                  :project-uniquekey="projectUniquekey"
                                  :order-id="item.id"
                                  can-leave-report-problem/>
            </div>
          </td>

          <td>
            <div class="order-status">
              <v-lb-order-status :status-constant="item.statusConstant"/>
            </div>
          </td>

        </tr>
        </tbody>
      </v-table-info>

      <div class="backlinks-items" v-if="isMobile">
        <div class="backlinks-item" v-for="item in linkBuildOrdersStore.ordersManager().ordersSortedByCreatedAt" :class="item.is_new ? 'is-new' : ''">

          <div class="backlinks-item__top">
            <div>

              <div class="order-created-id">
                <div class="order-created-id__created">{{item.createdAtFormatted}}</div>
                <div class="order-created-id__id">{{item.id}}</div>
              </div>

            </div>
            <div>

              <div class="order-status">
                <v-lb-order-status :status-constant="item.statusConstant"/>
              </div>

            </div>
          </div>

          <div class="order-info">
            <span>{{item.typeTitleShort}}</span>
            <v-link :href="item.correctUrl"
                    :text="item.urlShort"
                    is-ends-ellipsis
                    is-inherit-style
                    is-outer/>
            <span>{{item.keyword}}</span>
          </div>

          <div class="info-blog-article" v-if="item.result_url && item.statusConstant==='delivered'">
            <v-link :href="item.result_url"
                    target="_blank"
                    is-ends-ellipsis
                    is-outer
                    :text="item.resultUrlShort"/>
            <lb-order-review-v2 v-if="item.statusConstant==='delivered'"
                                :project-uniquekey="projectUniquekey"
                                :order-id="item.id"
                                can-leave-report-problem/>
          </div>

        </div>
      </div>
    </template>

    <v-not-found-projects v-else
                          :show-button="useLinkBuildCart(props.projectUniquekey).countWithServerId === 0"
                          :link-action="[() => useLinkBuildCart(props.projectUniquekey).goToEditingCart()]"
                          link-text="Create backlinks"
                          message="No backlinks yet"/>
  </div>
</template>

<script setup>

import VTableInfo from "@app-vue/components/Base/VTableInfo.vue";
import {useLinkBuildCart, useLinkBuildProjectOrders} from "@app-vue/stores/useLinkBuilding";
import VLink from "@app-vue/components/Base/VLink.vue";
import VLbOrderStatus from "@app-vue/components/Base/LinkBuilding/VLbOrderStatus.vue";
import LbOrderReviewV2 from "@app-vue/components/LinkBuilding/LbOrderReviewV2.vue";
import {computed, getCurrentInstance} from "vue";
import VNotFoundProjects from "@app-vue/components/Base/VNotFoundProjects.vue";

const props = defineProps({
  projectUniquekey: {},
});

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const linkBuildOrdersStore = useLinkBuildProjectOrders(props.projectUniquekey);

</script>

<style lang="scss" scoped>
.lb-orders-backlinks-v2{
  .backlinks-table{
    thead{
      th{
        background:#fff;
        border-top:1px solid;
        &.th-created-id{width:152px;}
        &.th-order-info{width:247px;}
        &.th-info-blog-article{width:378px;}
        &.th-order-status{width:86px;}
      }
    }
    tbody{
      tr{
        td{
          padding-top:18px;
          padding-bottom:18px;
          border-bottom:1px solid $dark-10;
          vertical-align: top;
          &:first-child{
            padding-left:10px;
          }
          &:last-child{
            padding-right:10px!important;
          }
        }
        &.is-canceled{
          .order-created-id, .order-info, .info-blog-article{
            opacity:0.5;
          }
        }
        &.is-new{
          td{
            background:#F7F9FF;
          }
        }
      }
    }
  }

  .backlinks-items{
    .backlinks-item{
      @include flex(column,start,stretch,10px);
      border-bottom:1px solid $dark-10;
      padding:18px 0px;
      &__top{
        @include flex(row,between,center);
      }
      &.is-new{
        padding-left:10px;
        padding-right:10px;
        background:#F7F9FF;
      }
    }
  }

  .order-created-id{
    @include flex(column,start,start,2px);
    &__created{
      font:$font-14-semi;
      color:$dark-80;
    }
    &__id{
      font:$font-mini-9-regu;
      color:#798CAE80;
    }
    @media(max-width:767px){
      @include flex(row,start,center,5px);
    }
  }

  .order-info{
    @include flex(column,start,start,2px);
    font:$font-13-regu;
    color:$dark-40;
  }
  .info-blog-article{
    @include flex(column,start,start,7px);
    @media(max-width:767px){
      gap:12px;
    }
  }

  .order-status{
    @include flex(row,end,start);
  }

}
</style>