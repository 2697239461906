<template>
  <div class="sc-project-info scpi">

    <div class="scpi__settings-column">

      <div class="scpi__column-title">
        <div class="scpi__column-title-left">
          <span class="scpi__column-title--bold">Settings</span>
        </div>
      </div>

      <div class="scpi__settings">

        <div class="scpi__setting">
          <div>
            <span data-tooltip-el="settings-project-status">Project Active</span>
          </div>
          <div>
            <v-form-checkbox-slider
                type="events"
                :value="useProject.status"
                @save="saveCheckboxStatus"
                ref="checkboxStatus"
            />
          </div>
        </div>

        <div class="scpi__setting">
          <div>
            <span data-tooltip-el="settings-devices">Devices</span>
          </div>
          <div>
            <v-form-string-select
                data-name="device-targeting"
                :options="deviceOptions"
                :value="deviceValue"
                @save="saveDeviceType"
                :tooltip-options="{placement:'bottom-end', style:{maxWidth:'194px'}}"
                ref="selectDeviceType"
            >
              <template v-slot:default>
                <span>{{deviceValue.name}}</span>
              </template>
            </v-form-string-select>
          </div>
        </div>

        <div class="scpi__setting">
          <div>
            <span data-tooltip-el="settings-recommendations">Recommendations</span>
          </div>
          <div>
            <sc-project-recommendations :project-id="projectId" @recommendation-group-click="$emit('recommendation-group-click')"/>
          </div>
        </div>

        <div class="scpi__setting" v-if="false">
          <div>
            <span>Total Daily Limit</span>
          </div>
          <div>
            <v-form-string-input-number
              :value="useProject.daily_limit"
              @save="saveDayliLimit"
              ref="inputDailyLimit"
            >
              <span class="scpi__setting-daily-limit">{{useProject.daily_limit}}</span>
            </v-form-string-input-number>
          </div>
        </div>

        <div class="scpi__setting">
          <div>
            <span data-tooltip-el="settings-search-engine">Search Engine</span>
          </div>
          <div>
            <v-form-string-select
                data-name="search-engine"
                :options="searchEnginesOptions"
                :value="searchEngineValue"
                :tooltip-options="{placement:'bottom-end'}"
                @save="searchEnginesSave"
                ref="selectSearchEngine"
            >
              <template v-slot:default>
                <div class="search-engine-value">
                  <v-icon-svg-wrapped v-if="getSearchEngineById(searchEngineValue?.value)?.icon"
                                      as-img
                                      as-block
                                      :inner-size="[null,15]"
                                      :outer-size="[20,20]"
                                      :name="getSearchEngineById(searchEngineValue?.value)?.icon"/>
                  <span>{{getSearchEngineById(searchEngineValue?.value)?.shortName}}</span>
                </div>
              </template>
            </v-form-string-select>
          </div>
        </div>

      </div>

    </div>

    <div class="scpi__chart-column">

      <div class="scpi__column-title">
        <div class="scpi__column-title-left">
          <div class="scpi__column-title-period-wrap">
            <div class="scpi__column-title-period-label">Period:</div>
            <v-period-simple
                :start-date="periodSelectedStart"
                :end-date="periodSelectedEnd"
                ref="inputPeriodSimple"
                @save="savePeriodChart"
            />
          </div>
        </div>
        <div class="scpi__column-title-right">
          <span class="scpi__column-title--bold">{{useProject?.chart_data?.totalHits}} Clicks</span>
        </div>
      </div>

      <div class="scpi__chart">
        <chart-seo-clicks :chart="useProject.chart_data" chart-size="normal" class="scpi__chart-inner" ref="canvasChart" is-blue/>
      </div>

    </div>

    <div class="scpi__realtime-column">

      <div class="scpi__column-title">
        <div class="scpi__column-title-left scpi__column-title-left--short-gap">
          <span class="scpi__column-title--bold scpi__column-title-realtime">
            <v-icon-realtime-pulse class="scpi__column-title-realtime-icon" :is-active="useProject.isActiveRealtime"/>
            Realtime
          </span>
          <div class="scpi__column-title--muted">
            Last 30 min
          </div>
        </div>
      </div>

      <div class="scpi__realtime">
        <chart-realtime-v2 :values="useRealtime.values" class="scpi__realtime-chart" show-scale-y :animate-last-bar="useProject.isActiveRealtime"/>
      </div>

      <v-link href="https://search.google.com/search-console" is-outer class="link-google-search-console" target="_blank">
        <v-icon-svg name="google-search-console" as-img width="16"/> Check on Google Search Console
      </v-link>

    </div>

  </div>
</template>

<script setup>
import {computed, defineProps, onMounted, ref, watch} from "vue";
import VFormCheckboxSlider from "@app-vue/components/VForm/VFormCheckboxSlider.vue";
import ChartSeoClicks from "@app-vue/components/Chart/ChartSeoClicks.vue";
import VLink from "@app-vue/components/Base/VLink.vue";
import VPeriodSimple from "@app-vue/components/Base/VPeriodSimple.vue";
import VFormStringSelect from "@app-vue/components/VForm/VFormStringSelect.vue";
import VFormStringInputNumber from "@app-vue/components/VForm/VFormStringInputNumber.vue";
import {useRealtimeSeoClicksProject, useSeoClicksProject} from "@app-vue/stores/useSeoClicks.js"
import {useStaticData} from "@app-vue/stores/useStaticData.js"
import VIconRealtimePulse from "@app-vue/components/Base/VIconRealtimePulse.vue";
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import VIconSvgWrapped from "@app-vue/components/Base/VIconSvgWrapped.vue";
import ScProjectRecommendations from "@app-vue/components/SeoClicks/ScProjectRecommendations.vue";
import ChartRealtimeV2 from "@app-vue/components/Chart/ChartRealtimeV2.vue";

const emit = defineEmits(['recommendation-group-click']);

const props = defineProps({
  projectId: {}
});

const useProject = useSeoClicksProject(props.projectId);
const staticData = useStaticData();

/* status project start */

const checkboxStatus = ref();
const saveCheckboxStatus = async (isActive) => {
  let [status,message] = await useProject.saveIsActive(isActive);
  status ? checkboxStatus.value.saveWithSuccess() : checkboxStatus.value.saveWithError(message);
}

/* status project end */

/* daily limit start */

const inputDailyLimit = ref();
const saveDayliLimit = async (dailyLimit) => {
  let [status,message] = await useProject.saveDailyLimit(dailyLimit);
  status ? inputDailyLimit.value.saveWithSuccess() : inputDailyLimit.value.saveWithError();
}

/* daily limit end */

/* chart, periods, all clicks start */

const canvasChart = ref();
const inputPeriodSimple = ref();

watch(() => useProject.chart_data, () => {
  canvasChart.value.update(useProject.chart_data);
}, {deep:true});

let startDays = new Date();
startDays.setDate(startDays.getDate() - 30);

const periodSelectedStart = ref((() => {
  let startDays = new Date();
  startDays.setDate(startDays.getDate() - 30);
  return startDays.getTime();
})());
const periodSelectedEnd = ref((new Date().getTime()));

const savePeriodChart = async (startDate, endDate) => {

  let [status,message] = await useProject.updateChartData(new Date(startDate), new Date(endDate));

  if(status){
    periodSelectedStart.value = startDate;
    periodSelectedEnd.value = endDate;
    inputPeriodSimple.value.saveWithSuccess();
  }else{
    inputPeriodSimple.value.saveWithError(message);
  }

}

/* chart, periods, all clicks end */

/* device type start */

const selectDeviceType = ref();
const deviceOptions = ref([
  {
    value: null,
    name: 'All'
  },
  {
    value: 'mobile',
    name: 'Mobile',
    label: '+ 1 Credit',
  },
  {
    value: 'desktop',
    name: 'Desktop',
    label: '+ 1 Credit',
  },
]);
const deviceValue = computed(() => {
  return deviceOptions.value.find(opt => opt.value === useProject.devicesType);
});
const saveDeviceType = async (deviceType) => {
  let [status,message] = await useProject.saveDeviceType(deviceType.value);
  status ? selectDeviceType.value.saveWithSuccess() : selectDeviceType.value.saveWithError(message);
}

/* device type end */


/* search engine start */

const selectSearchEngine = ref();
const searchEnginesOptions = computed(() => {
  return staticData.searchEngines.map((item) => {
    return {
      value:item.id,
      name:item.name,
      iconData:{
        name:item.icon,
        outerSize:24,
        innerSize:[null,18],
        asImg:true,
        asBlock:true,
      }
    }
  });
});
const searchEngineValue = computed(() => {
  return searchEnginesOptions.value.find(opt => opt.value === useProject.search_engines_id)
});
function getSearchEngineById(id){
  return staticData.searchEngines.find(item => item.id === id);
}
const searchEnginesSave = async (option) => {
  let [status,message] = await useProject.saveSearchEnginesId(option.value);
  status ? selectSearchEngine.value.saveWithSuccess() : selectSearchEngine.value.saveWithError(message);
}

/* search engine end */

const useRealtime = useRealtimeSeoClicksProject(props.projectId);
function realtimeUpdateStart(){
  useRealtime.update().then(() => {
    setTimeout(() => realtimeUpdateStart(), 1000*10);
  })
}
onMounted(() => {
  realtimeUpdateStart();
});

/* end:realtime */

</script>

<style scoped lang="scss">
.sc-project-info.scpi{
  display: flex;
  gap:30px;
  @media(max-width: 1329px){
    gap:30px;
  }

  @media(max-width: $mobile-width){
    flex-direction: column;
    gap:0px;
  }

  .scpi__column-title{
    font-size: 14px;
    line-height:18px;
    margin-bottom:24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media(max-width:$mobile-width){
      font-size:16px;
      line-height: 19px;
      margin-bottom:20px;
    }

    .scpi__column-title-realtime{
      display: flex;
      align-items: center;
      gap:12px;
      .scpi__column-title-realtime-icon{
      }
      .scpi__realtime-icon{
        flex-shrink: 0;
        width:14px;
        height:14px;
        background-color: #1A73E81A;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
      .scpi__realtime-icon:before{
        content:'';
        display: block;
        width:6px;
        height:6px;
        background-color: #1A73E8;
        border-radius: 50%;
      }
    }

    .scpi__column-title--bold{
      font-weight: 600;
    }
    .scpi__column-title--muted{
      font-size:10px;
      line-height:12px;
      color:#00000080;
      text-transform: uppercase;
    }

    .scpi__column-title-left{
      display: flex;
      align-items: center;
      gap:10px;
      &.scpi__column-title-left--short-gap{
        gap:5px;
      }
      .scpi__column-title-period-label{
        display: none;
      }
      @media(max-width: $mobile-width){
        .scpi__column-title-period-wrap{
          display: flex;
          justify-content: space-between;
          font-size:14px;
          width:100%;
        }
        .scpi__column-title-period-label{
          display:block;
        }
      }

      @media(max-width: $mobile-width){
        flex-direction: column;
        width:100%;
        align-items: flex-start;
      }

    }
    .scpi__column-title-right{
      @media(max-width:$mobile-width){
        display: none;
      }
    }

  }

  .scpi__settings-column{
    width:226px;
    flex-shrink: 0;
    @media(max-width: $mobile-width){
      width:100%;
    }
  }
  .scpi__chart-column{
    width:100%;
  }
  .scpi__realtime-column{
    width:100%;

    @media(max-width:$mobile-width){
      width:100%;
      max-width:100%;
      .scpi__column-title-left{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap:5px;
      }
    }
  }

  .scpi__settings{
    .scpi__setting{
      display: flex;
      justify-content: space-between;
      margin-bottom:20px;
      align-items: center;
      gap:10px;
      @media (max-width: $mobile-width) {
        margin-bottom:17px;
      }

      > div:first-child{
        color:#596C8F;
      }

      > div:last-child{
        text-align:right;
      }

      &:last-child{
        margin-bottom:0px;
      }

      .scpi__setting-daily-limit {
        font-weight: 600;
        font-size:16px;
        line-height: 19px;
      }

    }
  }

  .scpi__chart{
    .scpi__chart-inner{
      width:100%;
      height:166px;
    }
  }

  .scpi__realtime{
    .scpi__realtime-chart{
      width:100%;
      height:151px;
    }
  }

  .link-google-search-console{
    @include flex(row,start,center,5px,inline-flex);
    font:$font-mini-12-regu;
    margin-top:5px;
    @media(max-width:767px){
      margin-top:8px;
    }
  }

  .search-engine-value{
    @include flex(row,end,center,4px);
    font:$font-14-regu;
    color:#384967;
  }

  @media(max-width: $mobile-width){
    > div{
      margin-bottom:20px;
      padding-bottom:20px;
      border-bottom:1px solid #D6E3EF80;
      &:last-child{
        padding-bottom:0px;
        border-bottom:0px;
        margin-bottom:0px;
      }
    }
  }
}
</style>

<style lang="scss">
@mixin no_clicks{
  .scpi__column-title-right{
    display:none;
  }
  .scpi__column-title-left{
    justify-content: space-between;
    width:100%
  }
}

@media(min-width: $mobile-width-for-min-width){
  body:not(.sidebar-hide) {
    .sc-project-info.scpi {
      @media(max-width: 1260px) {
        @include no_clicks
      }
    }
  }

  body.sidebar-hide{
    .sc-project-info.scpi{
      @media(max-width:1070px){
        @include no_clicks
      }
    }
  }

}
</style>