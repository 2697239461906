<template>
  <div class="chart-scale" ref="refScale">
    <div class="chart-scale__text" ref="refTextItemsWrap" :style="{gridTemplateColumns: textItemsWrapGridTemplateColumns}">
      <template v-for="text in props.texts">
        <div class="chart-scale__text-item chart-scale__text-item--offset"></div>
        <div
            class="chart-scale__text-item chart-scale__text-item--value"
            :class="text.color"
            :data-position="text.position"
            ref="refTextItems"
        >
          <span
            class="chart-scale__text-item-value"
            :style="{fontWeight: text.fontWeight ? text.fontWeight : 700}"
          >{{text.value}}</span>
          <span
              v-if="text.valueName"
              class="chart-scale__text-item-value-name"
          >{{text.valueName}}</span>
        </div>
      </template>
    </div>
    <div class="chart-scale__stripes">
      <div
          v-for="stripe in props.stripes"
          class="chart-scale__stripe"
          :class="stripe.color"
          :style="{width:stripe.width+'%'}"
      ></div>
    </div>
    <div class="chart-scale__indicator">
      <div
          v-for="indicator in props.indicators"
          class="chart-scale__indicator-icon"
          :data-position="indicator.position"
          :style="{left: indicatorLeftStyle(indicator.position)}"
      >
        <v-icon-svg name="scale-point"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import {onMounted, ref} from "vue";
import {useStore} from "vuex";

const INDICATOR_WIDTH = 7;

const props = defineProps({
  /**
   * @param {Array} texts Массив текстов
   * @param {String} texts.value Выводимый текст
   * @param {String|null} props.texts.valueName Единица измерения
   * @param {Number} texts.position Позиция, значение от 0 до 100
   * @param {String} texts.color Класс цвета. Допустимые значения смотреть в <style>
   * @param {String} texts.fontWeight Значение жирности текста. По умолчанию 700 (для props.texts.valueName всегда 400)
   */
  texts: {type:Object},

  /**
   * @param {Array} indicators Массив индикаторов
   * @param {Number} indicators.position Позиция индикатора, значение от 0 до 100
   */
  indicators: {type:Object},

  /**
   * @param {Array} stripes Массив полосок
   * @param {Number} stripes.width Ширина полоски, значение от 0 до 100
   * @param {String} stripes.color Класс цвета полоски, Допустимые значения смотреть в <style>
   */
  stripes: {type:Object}
});

const store = useStore();

const refScale = ref();
const refTextItems = ref([]);
const refTextItemsWrap = ref();

const textItemsWrapGridTemplateColumns = ref('');
const cwGlob = ref();

const calcBoundaryValueTexts = () => {
  if(refTextItems.value === undefined || !refTextItemsWrap.value){return;}

  let grids = [];
  let lastPointRight = 0;
  let cw = refTextItemsWrap.value.offsetWidth; //container width
  cwGlob.value = cw;

  refTextItems.value.forEach((textItem) => {

    let width = null;

    if(!textItem.classList.contains('chart-scale__text-item--offset')){
      width = textItem.offsetWidth;
      let position = parseFloat(textItem.getAttribute('data-position')); //на основе props

      let offset = cw/100*position - lastPointRight - width/2;

      if(lastPointRight + offset + width > cw){
        offset = cw - width - lastPointRight;
      }

      offset = (offset >= 0) ? offset : 0;

      lastPointRight = offset+width;

      grids.push('minmax(auto,'+offset+'px) '+width+'px');

    }

  });
  textItemsWrapGridTemplateColumns.value = grids.join(' ');

}

const indicatorLeftStyle = (position) => {
  return 'min(max('+position+'%,'+(INDICATOR_WIDTH/2)+'px),calc(100% - '+(INDICATOR_WIDTH/2)+'px))'
}

onMounted(() => {
  refScale.value.observeUpdateFunc = () => {
    calcBoundaryValueTexts();
  }
  store.state.gResizeObserver.observer.observe(refScale.value);
});

</script>

<style lang="scss" scoped>
.chart-scale{
  background: transparent;
  height: 30px;
  width: 100%;
  position: relative;
}
.chart-scale__stripes{
  display: flex;
  height: 4px;
  position: absolute;
  bottom:4px;
  width:100%;
  overflow: hidden;
  border-radius: 10px;
}
.chart-scale__stripe{
  display:block;
  height:100%;
  position: relative;
}
.chart-scale__stripe:after{
  content:'';
  position: absolute;
  width:1px;
  height:100%;
  right:0px;
  background: transparent;
}
.chart-scale__stripe:first-of-type{
  border-radius: 10px 0px 0px 10px;
}
.chart-scale__stripe:last-of-type{
  border-radius: 0px 10px 10px 0px;
}
.chart-scale__stripe:last-of-type:after{
  display: none;
}
.chart-scale__stripe.color-super-danger{background-color:$recommendation-super-danger}
.chart-scale__stripe.color-danger{background-color:$recommendation-danger}
.chart-scale__stripe.color-warning{background-color:$recommendation-warning}
.chart-scale__stripe.color-success{background-color:$recommendation-success}
.chart-scale__stripe.color-default{background-color:#bbb}
.chart-scale__stripe.color-error{background-color:#E8F2FB}
.chart-scale__indicator{
  position: absolute;
  width: 100%;
  bottom: 3px;
  height: 14px;
}
.chart-scale__indicator-icon{
  position: absolute;
  left: 50%;
  display: block;
  height: 14px;
  width: 7px;
  bottom: 0px;
  margin-left: -3.5px;
}
.chart-scale__indicator-icon svg{
  display: block;
}
.chart-scale__text{
  display: grid;
  width: 100%;
  bottom: 13px;
  position: absolute;
}
.chart-scale__text-item{
  white-space: nowrap;
  text-align:center;
  width: min-content;
}
.chart-scale__text-item--value{
  padding:0px 3px;
}
.chart-scale__text-item:nth-of-type(2){
  padding-left:0px;
}
.chart-scale__text-item:last-child{
  padding-right:0px;
}
.chart-scale__text-item-value{
  font-family: $font-family-base;
  font-size:12px;
  line-height:18px;
  font-weight: 700;
}
.chart-scale__text-item-value-name{
  font-family: $font-family-base;
  font-size:11px;
  line-height:18px;
  font-weight: 400;
  margin-left: 2px;
}
.chart-scale__text-item.color-super-danger{color:$recommendation-super-danger}
.chart-scale__text-item.color-danger{color:$recommendation-danger}
.chart-scale__text-item.color-warning{color:$recommendation-warning}
.chart-scale__text-item.color-success{color:$recommendation-success}
.chart-scale__text-item.color-error{color:$recommendation-error}
.chart-scale__text-item.color-default{color:#bbb}
</style>