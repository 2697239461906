<template>
  <div class="lb-step-cart-coupon">

    <v-link v-if="status === 'start'" text="Apply coupon" @click="status = 'editing'"/>

    <div v-if="status === 'editing'" class="editing">
      <v-coupon-edit @close="status = 'start'" @saved-success="status = 'info'"/>
    </div>

    <div v-if="status === 'info'" class="info">
      Coupon applied -${{priceFormatted(discountPrice)}}
    </div>

  </div>
</template>

<script setup>
import VLink from "@app-vue/components/Base/VLink.vue";
import {onMounted, ref, watch} from "vue";
import VCouponEdit from "@app-vue/components/Base/VCouponEdit.vue";
import {priceFormatted} from "@app-vue/utils/utils";

const props = defineProps({
  /**
   * Примененная скидка в долларах
   */
  discountPrice: null,
});

/** @type {import('vue').Ref<'start'|'editing'|'info'>} */
const status = ref('start');

watch(() => props.discountPrice, (discountPrice) => {
  if(discountPrice > 0){
    status.value = 'info';
  }
});

</script>

<style lang="scss" scoped>
.lb-step-cart-coupon{

  font-size:12px;

  .info{
    color:$dark-50;
    font:$font-mini-12-regu;
  }

}
</style>