<template>
  <div class="lb-step-cart-packages">
    <lb-step-cart-packages-item :type="countPackagesInDropdown > 0 ? 'button' : 'simple'"
                                :dropdown-status="refTooltipDropdown?.dataShow ? 'open' : 'closed'"
                                class="package-selected__inner"
                                ref="refPackageSelected"
                                :quantity="useCart.packageQuantity < 1 ? 1 : useCart.packageQuantity"
                                @click="openDropdown()"/>
    <v-tooltip ref="refTooltipDropdown"
               :get-linked-block="() => refPackageSelected"
               :offset="[0,0]"
               reset-tooltip
               btn-close-off
               placement="bottom">
      <template #content>
        <lb-step-cart-packages-dropdown :project-uniquekey="projectUniquekey" :style="{width:`${widthTooltiDropdown}px`}" @change-quantity="changeQuantity"/>
      </template>
    </v-tooltip>
  </div>
</template>

<script setup>

import LbStepCartPackagesItem from "@app-vue/components/LinkBuilding/LbStepCartPackagesItem.vue";
import VTooltip from "@app-vue/components/Base/VTooltip.vue";
import LbStepCartPackagesDropdown from "@app-vue/components/LinkBuilding/LbStepCartPackagesDropdown.vue";
import {computed, ref, watch} from "vue";
import {useLinkBuildCart} from "@app-vue/stores/useLinkBuilding";
import {useUserBalance} from "@app-vue/stores/useStaticData";
import {useCreditTypes} from "@app-vue/stores/useBilling";

const props = defineProps({
  projectUniquekey:{},
});

const refPackageSelected = ref();
const refTooltipDropdown = ref();

const useCart = useLinkBuildCart(props.projectUniquekey);

watch(() => useCart.credits, (credits) => {

  useCart.$patch({packageQuantity: credits - useUserBalance().backlinkV2BalancesAmount});

},{immediate: true,});

const widthTooltiDropdown = ref();
function openDropdown(){
  widthTooltiDropdown.value = refPackageSelected.value.$el.offsetWidth;
  refTooltipDropdown.value.toggle();
}

function changeQuantity(quantity){
  useCart.$patch({
    packageQuantity: quantity,
  });
  refTooltipDropdown.value.hide();
}

const countPackagesInDropdown = computed(() => {
  let count = useCreditTypes().creditTypesBacklinkV2?.firstPlanOptions.plan_options_packages?.reduce((sum,option) => {
    return sum + (option.quantity > useCart.credits - useUserBalance().backlinkV2BalancesAmount) ? 1 : 0
  },0);
  return count ? count : 0;
});

</script>

<style lang="scss" scoped>
.lb-step-cart-packages{
}
</style>