<template>
  <div class="lb-order-report-problem">

    <div class="inner-container form" v-if="!order.has_report_problem">
      <div class="title">
        Report a problem
      </div>
      <div class="subtitle">
        Tell us about the problems with this backlink
      </div>
      <v-form-textarea v-model="message" class="form-message" none-resize placeholder="Write here ...."/>

      <v-form-message-error :message-error="order.errors.reportProblem.message" no-margin/>

      <v-button color="green"
                text="Report a problem"
                :click-action="[order.sendReportProblem, message]"/>
    </div>

    <div class="inner-container message" v-else>
      <span>Thank you for your request!</span>
      <span>We have already started working on your problem</span>
    </div>

  </div>
</template>

<script setup>

import VFormTextarea from "@app-vue/components/VForm/VFormTextarea.vue";
import VButton from "@app-vue/components/Base/VButton.vue";
import {ref} from "vue";
import {useLinkBuildOrder} from "@app-vue/stores/useLinkBuilding";
import VFormMessageError from "@app-vue/components/VForm/VFormMessageError.vue";

const props = defineProps({
  orderId: {},
  projectUniquekey: {},
});

const order = useLinkBuildOrder(props.projectUniquekey, props.orderId);
const message = ref('');

</script>

<style lang="scss" scoped>
.lb-order-report-problem{
  .inner-container{
    border-radius: 8px;
    box-shadow: $box-shadow-tooltip;
    background:$white-100;

    &.form{
      width:346px;
      padding:20px;
      @include flex(column,start,stretch,20px);
      .title{
        font:$font-h2-20-semi;
        color:$dark-80;
      }
      .subtitle{
        color:$dark-50;
      }
      .form-message{
        height:132px;
      }
    }

    &.message{
      width:250px;
      padding:12px;
      @include flex(column,start,stretch,10px);
      color:$dark-50;
    }

  }
}
</style>