<template>
  <v-container class="sc-projects-page">

    <v-breadcrumbs :items="breadcrumbs" no-padding/>

    <bc-balance :credit-type="useExperiments().isLinkBuildingOneCredit ? CREDIT_TYPE_BACKLINK_V2 : CREDIT_TYPE_BACKLINK"/>

    <v-head-title>My Projects</v-head-title>

    <div class="desktop-filters" v-if="useProjects.countAllProjects > 0" :class="{'is-loading':loadingList}">
      <div class="desktop-filters__head">
        <v-search-bar-v2 skin="rounded-white-on-gray"
                         class="desktop-filters__search-bar"
                         auto-height
                         v-model="filterSearchBuffer"
                         placeholder="Search here ..."/>
        <v-filter-simple :has-selected="false"
                         v-if="!isMobile"
                         skin="on-gray-bg"
                         v-model="filterDomains"
                         :options="filterDomainsOptions"
                         tooltip-display-as-input-checked
                         button-title="Websites"
                         size="big"
                         search-enable/>

        <v-filter-simple :has-selected="false"
                         v-if="!isMobile"
                         skin="on-gray-bg"
                         v-model="filterKeywords"
                         :options="filterKeywordsOptions"
                         tooltip-display-as-input-checked
                         button-title="Keywords"
                         size="big"
                         search-enable/>

        <v-filter-simple :has-selected="false"
                         v-if="!isMobile"
                         skin="on-gray-bg"
                         v-model="filterTypes"
                         :options="filterTypesOptions"
                         button-title="Types"
                         size="big"
                         tooltip-display-as-input-checked/>
        <v-filter-button v-if="isMobile"
                         is-circle
                         @click="refFilterSheetModal.open()"
                         :has-filters="numberFiltersApplied > 0"
                         size="big"
                         skin="on-gray-bg"/>
      </div>
      <div class="desktop-filters__foot" v-if="selectedItemsGroups.reduce((s,i) => i.model.length+s,0) > 0">
        <template v-for="selectedItemsGroup in selectedItemsGroups">
          <template v-for="(selectedItem,key) in selectedItemsGroup.model">
            <v-filter-simple-selected-item :option="selectedItem"
                                           :title-head="selectedItemsGroup.title"
                                           @remove="selectedItemsGroup.model.splice(key,1)"
                                           :skin="'on-gray-bg'"
            />
          </template>
        </template>
      </div>
    </div>

    <div class="controls" v-if="useProjects.countAllProjects > 0">

      <div class="controls__head">
        <v-button color="green"
                  tag="a"
                  :href="useRoutes().url('backlinks-project.add')"
                  text="+ Create project"/>
        <div class="filters">
          <v-sort-by-simple :options="typesSortBy"
                            v-model="sortBySimpleSelected"
                            v-model:sorting-direct="sortBySimpleDirect"
                            class="filter-sort"
                            :class="{'is-loading':loadingList}"
                            arrow-sorting-direct
                            :has-text="!isMobile"/>
        </div>
      </div>

      <v-sheet-modal ref="refFilterSheetModal" has-group-buttons v-if="isMobile">
        <template #content>
          <div class="sheet-modal-filters">
            <v-tabs v-model="filterSheetModalTab" class="tabs">
              <v-tabs-item class="item" hash="filters">Filters</v-tabs-item>
              <v-tabs-item class="item" hash="websites">Websites</v-tabs-item>
              <v-tabs-item class="item" hash="keywords">Keywords</v-tabs-item>
            </v-tabs>
            <div class="content">
              <v-tooltip-select-content v-model="filterTypes"
                                        v-if="filterSheetModalTab === 'filters'"
                                        :options="filterTypesOptions"
                                        button-title="Types"
                                        display-as-input-checked
                                        tooltip-display-as-input-checked
                                        multi-select/>
              <v-tooltip-select-content v-model="filterDomains"
                                        v-if="filterSheetModalTab === 'websites'"
                                        :options="filterDomainsOptions"
                                        button-title="Types"
                                        display-as-input-checked
                                        tooltip-display-as-input-checked
                                        multi-select
                                        search-title="Search Keywords"
                                        :search-enable="filterDomainsOptions.length >= 7"/>
              <v-tooltip-select-content v-model="filterKeywords"
                                        v-if="filterSheetModalTab === 'keywords'"
                                        :options="filterKeywordsOptions"
                                        button-title="Types"
                                        display-as-input-checked
                                        tooltip-display-as-input-checked
                                        multi-select
                                        search-title="Search Websites"
                                        :search-enable="filterKeywordsOptions.length >= 7"/>
            </div>
          </div>
        </template>
        <template #group-buttons>
          <v-button color="blue2-stroke"
                    class="w-50"
                    :disabled="!numberFiltersApplied"
                    @click="resetAppliedFilters()"
                    text="Reset All"/>
          <v-button color="green"
                    class="w-50"
                    :disabled="!numberFiltersApplied"
                    @click="refFilterSheetModal.close()"
                    v-text="`Apply ${numberFiltersApplied}`"/>
        </template>
      </v-sheet-modal>

    </div>

    <div class="list" v-show="countProjectsForShow > 0" :class="{'is-loading':loadingList}">
      <template v-for="project in useProjects.list" :key="project.uniquekey">
        <lb-project-preview :project-uniquekey="project.uniquekey"/>
      </template>
    </div>

    <v-not-found-projects v-if="useProjects.countAllProjects === 0"
                          is-link
                          show-button
                          :link="useRoutes().url('backlinks-project.add')"/>

    <div class="footer">
      {{countProjectsForShow}} Backlinks {{pluralize('project', countProjectsForShow)}}
    </div>

  </v-container>
</template>

<script setup>
import VContainer from "@app-vue/components/Base/VContainer.vue";
import VBreadcrumbs from "@app-vue/components/Base/VBreadcrumbs.vue";
import {CREDIT_TYPE_BACKLINK, CREDIT_TYPE_BACKLINK_V2} from "@app-vue/stores/useBilling";
import BcBalance from "@app-vue/components/BuyCredits/BcBalance.vue";
import VHeadTitle from "@app-vue/components/Base/VHeadTitle.vue";
import VButton from "@app-vue/components/Base/VButton.vue";
import {computed, getCurrentInstance, reactive, ref, watch} from "vue";
import {useRoutes} from "@app-vue/stores/useRoutes";
import VSortBySimple from "@app-vue/components/Base/VSortBySimple.vue";
import {useLinkBuildProjects} from "@app-vue/stores/useLinkBuilding";
import VFilterSimple from "@app-vue/components/Base/VFilterSimple.vue";
import VNotFoundProjects from "@app-vue/components/Base/VNotFoundProjects.vue";
import LbProjectPreview from "@app-vue/components/LinkBuilding/LbProjectPreview.vue";
import {groupUrlsByDomain, pluralize} from "@app-vue/utils/utils";
import VFilterButton from "@app-vue/components/Base/VFilterButton.vue";
import VSheetModal from "@app-vue/components/Base/VSheetModal.vue";
import VTabs from "@app-vue/components/Base/VTabs.vue";
import VTabsItem from "@app-vue/components/Base/VTabsItem.vue";
import VTooltipSelectContent from "@app-vue/components/Base/VTooltipSelectContent.vue";
import VFilterSimpleSelectedItem from "@app-vue/components/Base/VFilterSimpleSelectedItem.vue";
import {uniqBy} from "lodash/array";
import VSearchBarV2 from "@app-vue/components/Base/VSearchBarV2.vue";
import {useExperiments} from "@app-vue/stores/useExperiments";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const breadcrumbs = ref([
  {'url': useRoutes().url('backlinks-project.list'), 'title': 'Backlinks Projects'},
  { 'title': 'My Projects'}
]);

const useProjects = useLinkBuildProjects();
const countProjectsForShow = computed(() => {
  return useProjects.list.length;
});

const refFilterSheetModal = ref();
const filterSheetModalTab = ref('filters');
const filterSearchBuffer = ref('');
const filterSearch = ref('');

watch(() => filterSearchBuffer.value, (searchString) => {
  setTimeout(() => {
    if(searchString === filterSearchBuffer.value){
      filterSearch.value = searchString;
    }
  },1000);
});

/**
 * Массивы с примененными фильтрами
 */
const filterTypes = reactive(useLinkBuildProjects().getFilterSettings()?.types ?? []);
const filterDomains = reactive((() => {
  let listUrls = (useLinkBuildProjects().getFilterSettings()?.websites ?? []).map((item) => item.value);
  let listDomains = Object.entries(groupUrlsByDomain(listUrls))
  return listDomains.map(([key,value]) => {return {value:key,name:key,urls:value}});
})());
const filterKeywords = reactive(useLinkBuildProjects().getFilterSettings()?.keywords ?? []);


/**
 * Options для фильтров
 */
const filterDomainsOptions = computed(() => {
  return uniqBy(
      Object.entries(useLinkBuildProjects().filterOptionsDomains)
          .map(([key,value]) => {return {value:key,name:key,urls:value}})
          .concat(filterDomains),
      'value'
  ).sort((a, b) => a.name.localeCompare(b.name));
});
const filterKeywordsOptions = computed(() => {
  return uniqBy(
      useLinkBuildProjects().filterOptionsKeywords
        .map((item) => {return {value:item,name:item}})
        .concat(filterKeywords),
      'value'
  ).sort((a, b) => a.name.localeCompare(b.name));
});
const filterTypesOptions = reactive([
  {value: 'show_deleted', name: 'Show deleted',},
  {value: 'show_inactive', name: 'Show inactive',},
]);

const numberFiltersApplied = computed(() => {
  return filterDomains.length + filterKeywords.length + filterTypes.length;
});
function resetAppliedFilters(){
  filterDomains.splice(0,filterDomains.length);
  filterKeywords.splice(0,filterKeywords.length);
  filterTypes.splice(0,filterTypes.length);
}
const selectedItemsGroups = [
  {
    title: 'Websites',
    model: filterDomains,
  },
  {
    title: 'Keywords',
    model: filterKeywords,
  },
  {
    title: 'Type',
    model: filterTypes,
  },
];

const typesSortBy = reactive([
  {value: 'created_at', name: 'Date created',},
  {value: 'name', name: 'Name',},
]);
const sortBySimpleSelected = ref(useLinkBuildProjects().getFilterSettings()?.sort?.column ?? typesSortBy[0]);
const sortBySimpleDirect = ref(useLinkBuildProjects().getFilterSettings()?.sort?.direction ?? 'desc');

const loadingList = ref(false);

watch(
    () => {
      return {
        filterTypes,
        filterDomains,
        filterKeywords,
        sortBySimpleSelected: sortBySimpleSelected.value,
        sortBySimpleDirect: sortBySimpleDirect.value,
        filterTooltipIsOpened: isMobile.value ? refFilterSheetModal.value?.isOpened : false,
        filterSearch: filterSearch.value,
      }
    },
    (data) => {
      if(data.filterTooltipIsOpened) return;
      let requestFilters = {
        searchString: data.filterSearch,
        types: data.filterTypes.map(item => item.value),
        websites: data.filterDomains.map(item => item.urls).flat(),
        keywords: data.filterKeywords.map(item => item.value),
        sort: {
          column: data.sortBySimpleSelected.value,
          direction: data.sortBySimpleDirect,
        }
      }
      loadingList.value = true;
      useProjects.download({filters: requestFilters,}).finally(() => {
        loadingList.value = false;
      }).then(() => {
        let dateTo = new Date();
        let dateFrom = new Date(dateTo.getFullYear(), dateTo.getMonth() - 11, 1);
        useProjects.downloadProjectsOrders(dateFrom,dateTo);
      })
    },
    {
      deep:true,
      immediate:true,
    }
);

</script>

<style lang="scss" scoped>
.sc-projects-page{
  @include flex(column,start,stretch,24px);
  padding-bottom:100px!important;
  @media (max-width: 767px){
    gap:16px;
  }
  .list{
    @include flex(column,start,stretch,20px);
    &.is-loading{@include state-loading;}
    @media (max-width: 767px){
      gap:16px;
    }
  }
  .footer{
    color:$dark-30;
    text-align:center;
  }
  .controls{
    @include flex(column,start,stretch,16px);
    width:100%;
    .controls__head{
      @include flex(row,between,center);
    }
    .controls__selected-items{
      @include flex(column,start,start,7px);
    }
    .controls__selected-info{
      @include flex(row,between,center);
    }
  }
  .desktop-filters{
    &.is-loading{@include state-loading;}
    @include flex(column,start,stretch,10px);
    @media(max-width: 767px){
      gap:16px;
    }
    .desktop-filters__head{
      @include flex(row,start,stretch,10px);
      @media(max-width: 767px){
        @include flex(row,between,stretch,10px);
      }
    }
    .desktop-filters__foot{
      @include flex(row,start,center,8px);
      flex-wrap:wrap;
      @media(max-width:767px){
        @include flex(column,start,start,8px);
      }
    }
    .desktop-filters__search-bar{
      width:286px;
      @media(max-width:767px){
        width:initial;
        flex-grow:2;
      }
    }
  }
  .filters{
    @include flex(row,end,center,11px);
  }
  .filter-sort{
    color:$dark-50;
    &.is-loading{@include state-loading;}
  }
}

.sheet-modal-filters{
  @include flex(column,start,stretch);
  height:100%;
  .tabs{
    flex-shrink:0;
    padding-left:12px;
    padding-right: 12px;
    .item{
      font-size:14px;
      border-bottom-width: 2px;
      &.is-active{
        color:$dark-80;
        font-weight:600;
      }
    }
  }
  .content{
    flex-grow:10;
    overflow-y:auto;
  }

}
</style>