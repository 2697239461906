import {ref} from 'vue';

export default {
    install(app) {

        const isMobile = ref(false);

        const updateIsMobile = () => {
            isMobile.value = window.innerWidth <= 767;
        };

        window.addEventListener('resize', updateIsMobile);

        updateIsMobile();

        app.config.globalProperties.$isMobile = isMobile;
    }
};