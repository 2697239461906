<template>
  <v-st-panel
      class="recs-panel"
      :title="recsTitle"
      :show-content-when-loading="true"
      :loading-animation="recsStore.loading"
  >
    <template v-slot:content>
      <div class="recs-panel__list" v-if="!noRecsLoaded && !this.recsStore.queryError">
        <reco-block
            v-for="recommendation in recsStore.recommendations"
            :reco-metric="recommendation.metric"
        />
      </div>
      <div class="recs-panel__no-recommendations" v-if="noRecsLoaded && !this.recsStore.queryError">
        No recommendations for this project
      </div>
      <div class="recs-panel__no-recommendations" v-if="this.recsStore.queryError">
        An error occurred on the server when uploading recommendations
      </div>
    </template>
  </v-st-panel>
</template>

<script>
import VStPanel from "@app-vue/components/Base/VStPanel.vue";
import RecoBlock from "@app-vue/components/Reco/RecoBlock.vue";
import {useRecsStore} from "@app-vue/stores/useRecsStore";

export default {
  components:{
    VStPanel,
    RecoBlock
  },
  props:{
    projectUniquekey: {},
  },
  computed: {
    noRecsLoaded(){
      if(
          this.recsStore.status === 'not_process'
          || (this.recsStore.status === 'ready' && this.recsStore.countRecs === 0)
      ){
        return true;
      }else{
        return false;
      }
    },
    recsTitle(){
      if(this.noRecsLoaded || this.recsStore.queryError){
        return false;
      }else{
        return 'Recommendations';
      }
    }
  },
  methods: {
    getParamUrl(key){
      let queryString = location.search;
      let params = new URLSearchParams(queryString);
      return params.get(key);
    },
    getCurrentPageProjectId(){
      return JSON.parse(window.document.querySelector('#js-page-content').getAttribute('data-data')).projectId;
    },

  },
  setup(props){
    return {
      recsStore: useRecsStore(props.projectUniquekey)
    }
  },
  created(){

    this.recsStore.id = this.getCurrentPageProjectId();
    this.recsStore.fetchUntilNotLoaded();
  }
}
</script>

<style lang="scss" scoped>
.recs-panel{

}
.recs-panel__list {
  grid-gap: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media(max-width:500px){
  .recs-panel__list{
    grid-template-columns: 1fr;
  }
}
.recs-panel.js-disclosure-closed .recs-panel__list>div:nth-of-type(n+7){
  display: none
}
.recs-panel__no-recommendations{
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: #00000080;
}
</style>
