<template>
  <div class="bcp-tabs bcpt">

    <v-link class="bcpt__tab bcpt__tab--card" @click.prevent="setCurrentTab('#tab_cards')" :class="isActiveClass('#tab_cards')">
      <div>
        Cards
      </div>
    </v-link>

    <v-link class="bcpt__tab bcpt__tab--alternative" :class="isActiveClass('#tab_alternative')" @click.prevent="setCurrentTab('#tab_alternative')">
      <div>Alternative</div>
    </v-link>

    <v-link class="bcpt__tab bcpt__tab--paypal" :class="isActiveClass('#tab_paypal')" @click.prevent="setCurrentTab('#tab_paypal')">
      <div>
        PayPal
      </div>
    </v-link>

    <v-link class="bcpt__tab bcpt__tab--crypto" :class="isActiveClass('#tab_crypto')" @click.prevent="setCurrentTab('#tab_crypto')">
      <div>
        Crypto
      </div>
    </v-link>

    <v-link class="bcpt__tab bcpt__tab--bank" :class="isActiveClass('#tab_bank_transfer')" @click.prevent="setCurrentTab('#tab_bank_transfer')">
      <div>
        Bank Transfer
      </div>
    </v-link>


    <!-- </div> -->

  </div>
</template>

<script setup>
import VLink from "@app-vue/components/Base/VLink.vue";

const currentTab = defineModel();

const isActiveClass = (hash) => {
  if(currentTab.value === hash){
    return 'bcpt__tab--active';
  }else{
    return '';
  }
}

const setCurrentTab = (hash) => {
  currentTab.value = hash;
}

</script>

<style lang="scss">

.bcp-tabs.bcpt{
  list-style: none;
  padding:0px;
  margin:0px;
  display: flex;
  gap:0px;
  border-bottom: 1px solid rgba(214, 227, 239, .5);
  align-items:center;
  @media(max-width: 767px){
  }

  .bcpt__group-tabs{
    display: flex;
    gap:24px;
  }

  .bcpt__tab{
    margin: 0 0 -1px;
    height:50px;
    display:flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    padding:0px 8px;
    border-bottom:1px solid transparent;
    @media(max-width:767px){
      font-size: 14px;
    }

    &.bcpt__tab--active{
      border-bottom:1px solid #131D30;
      color:inherit;
    }

    &.bcpt__tab--card{
      gap:5px;
      img{
        width:38px;
        height:38px;
      }
    }
    &.bcpt__tab--cards{
      flex-direction: column;
      img{
        width:26px;
        height:26px;
      }
    }

  }
}

</style>