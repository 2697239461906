<template>
  <div class="chart-realtime-v2">

    <div class="canvas-wrap" ref="refWrapCanvas" :class="{'has-labels': !!showLabels}">
      <div v-if="countHorizontalLines > 0" class="lines">
        <div v-for="line in Array.from({length:countHorizontalLines+1})" class="line"></div>
      </div>
      <canvas ref="refCanvas"></canvas>
    </div>

    <div class="labels" v-if="showLabels === '3-items'">
      <div class="label">{{labels[0]}}</div>
      <div class="label" v-if="labels.length > 2">{{labels[Math.floor(labels.length/2)]}}</div>
      <div class="label">{{labels[labels.length-1]}}</div>
    </div>

  </div>
</template>

<script setup>

import {computed, onMounted, onUnmounted, ref} from "vue";
import {getScssVar} from "@app-vue/utils/utils";
import {ChartConfigBase, ChartControllerBase} from "@app-vue/utils/chartClasses";
import {calculateScaleYMaxForBar} from "@app-vue/utils/chartUtils";

const props = defineProps({
  /**
   * @type {Number[]}
   */
  values: {type: Array, default: [],},
  /**
   * @type {String[]}
   */
  labels: {type: Array, default: [],},
  type: {type: String, default: ''},
  countHorizontalLines: {type:Number, default:0,},
  /**
   * @type {'3-items'|null}
   */
  showLabels: {type:String, default:null},
});

const refWrapCanvas = ref();
const refCanvas = ref();

class ChartConfig extends ChartConfigBase{

  colors = ref({
    border: getScssVar('--seo-traffic-color'),
    background: getScssVar('--seo-traffic-color-dark-transparent'),
  });

  scaleYMax = computed(() => {
    return calculateScaleYMaxForBar(Math.max(...props.values));
  });

  result = computed(() => {
    if(!this.ready()){return null;}
    return {
      type: 'bar',
      data: {
        labels: props.labels,
        datasets: [{
          data: props.values,
          backgroundColor: this.colors.value.background,
          borderColor: this.colors.value.border,
          borderWidth: {
            top: 1,
            left: 0,
            right: 0,
            bottom: 0,
          },
          fill: true,
          tension: 0.4,
          pointRadius: 0,
          label:props.type,
        }],
      },
      options: {
        interaction:{
          intersect:false,
        },
        responsive: true,
        maintainAspectRatio:false,
        plugins: {
          tooltip:{
            enabled:true,
            borderWidth:0,
            boxPadding:0,
            multiKeyBackground: getScssVar('--optional-violet-10'),
          },
          legend: {display: false,},
          title:{display:false,},
          hover: {mode:null,},
          filter: {propagate:false,}
        },
        scales: {
          x: {display:false,},
          y: {
            display:false,
            max: this.scaleYMax.value,
            min: 0,
          },
        },
        hover: {mode: null},
      }
    }
  });
}

const chartController = new ChartControllerBase(refWrapCanvas, refCanvas, ChartConfig);

onMounted(() => {chartController.componentOnMounted();});
onUnmounted(() => {chartController.componentOnUnMounted();});

</script>

<style lang="scss" scoped>
.chart-realtime-v2{
  @include flex(column,start,stretch,8px);
  width:100%;
  position:relative;
  canvas{
    position:relative;
  }
  .canvas-wrap{
    width:100%;
    position:absolute;
    height:100%;
    &.has-labels{
      height:calc(100% - 18px - 4px);
    }
    &:after{
      content:'';
      width:100%;
      position:absolute;
      bottom:0px;
      border-bottom: 1px solid $dark-10;
    }
  }
  .lines{
    position:absolute;
    @include flex(column,between,stretch);
    width:100%;
    height:100%;
    top:0;
    left:0;
    .line{
      width:100%;
      height:1px;
      background: $dark-10;
      &:last-child{
        background:transparent;
      }
    }
  }
  .labels{
    @include flex(row,between,center);
    position:absolute;
    bottom:0px;
    height:18px;
    width:100%;
    .label{
      font:$font-mini-10-regu;
      color:$dark-30;
    }
  }
}
</style>