<template>
  <div class="reco-block" ref="recommendationBlock">
    <div class="reco-block__title" @click="openMoreInfo">
      <span
          class="reco-block__title-icon"
          :class="recoStore.currentColor"
      >
        <v-icon-svg :name="recoStore.currentTitleIconClass"/>
      </span>
      <span class="reco-block__title-text">{{recoStore.label}}</span>
    </div>
    <div class="reco-block__scale">
      <chart-scale
          :indicators="recoStore.chartScaleIndicators"
          :texts="recoStore.chartScaleTexts"
          :stripes="recoStore.chartScaleStripes"
      />
    </div>
    <v-tooltip
        ref="tooltip"
        :get-linked-block="() => $refs.recommendationBlock"
        :style="{maxWidth:'400px',padding:'20px'}"
    >
      <template v-slot:content>
        <reco-block-more-info
            :title="recoStore.label"
            :reco-metric="recoMetric"
            :project-uniquekey="projectUniquekey"
            :description="recoStore.recommendation"
        />
      </template>
    </v-tooltip>

    <v-modal ref="modalName">
      <reco-block-more-info
          :title="recoStore.label"
          :reco-metric="recoMetric"
          :project-uniquekey="projectUniquekey"
          :description="recoStore.recommendation"
      />
    </v-modal>
  </div>
</template>

<script>
import ChartScale from '@app-vue/components/Blocks/ChartScale.vue'
import RecoBlockMoreInfo from '@app-vue/components/Reco/RecoBlockMoreInfo.vue'
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import VModal from "@app-vue/components/Base/VModal.vue";
import VTooltip from "@app-vue/components/Base/VTooltip.vue";
import {useRecsStore} from "@app-vue/stores/useRecsStore";

export default {
  components: {
    VTooltip,
    VIconSvg,
    ChartScale,
    VModal,
    RecoBlockMoreInfo
  },
  props: {
    recoMetric: {type: String, required:true},
    projectUniquekey: {required: true},
  },
  computed: {
    recoStore: (state)=>{
      return state.recsStore.recoStoreByMetric(state.recoMetric);
    }
  },
  methods:{
    openMoreInfo(){
      if(window.innerWidth > 991){
        this.$refs.tooltip.toggle();
      }else{
        this.$refs.modalName.openModal()
      }
    }
  },
  setup(props){
    return {
      recsStore: useRecsStore(props.projectUniquekey)
    }
  }
}
</script>

<style lang="scss" scoped>
.reco-block {
  display: flex;
  flex-direction: column;
}

.reco-block__how-to-fix-it {
  font-size: 12px;
  height: 18px
}

.reco-block__how-to-fix-it a {
  text-decoration: none
}

.reco-block__title {
  flex-grow: 2;
  font-size: 13px;
  min-height: 34px;
  padding-bottom:8px;
  display:flex;
}
.reco-block__title-icon{
  margin-right: 6px;
  padding-top:2px;
  width:10px;
}
.reco-block__title-icon.color-super-danger svg{fill:$recommendation-super-danger;}
.reco-block__title-icon.color-danger svg{fill:$recommendation-danger;}
.reco-block__title-icon.color-warning svg{fill:$recommendation-warning;}
.reco-block__title-icon.color-success svg{fill:$recommendation-success;}
.reco-block__title-icon.color-default svg{fill:#bbb;}
.reco-block__title-icon.color-error svg{fill:$recommendation-error;}

.reco-block__title-text{
  text-decoration: underline;
  text-decoration-style: dashed;
  text-underline-offset: 3px;
  line-height: 18px;
  text-decoration-color: #898989;
  cursor: pointer;
}
.reco-block__scale{

}
</style>