<template>
  <div class="v-form-input-number-edit vfine" :class="mainClass">

    <div class="vfine__input-wrap">
      <input
          type="text"
          class="vfine__editor"
          ref="editor"
          @focusin="focused = true"
          @focusout="focused = false"
          v-model="inputData"
          @keyup.enter="save"
      >
      <div class="vfine__apply" @click="save" v-show="conteneditable">
        <span v-show="!loading" class="vfine__apply-icon-wrap">
          <v-icon-svg name="apply" class="vfine__apply-icon"/>
        </span>
        <v-loader-ship v-if="loading" class="vfine__loading"/>
      </div>
      <div class="vfine__clicker" @click="setContentEditable()"></div>
    </div>

    <div class="vfine__error" v-if="isError">
      {{errorMessage}}
    </div>

  </div>

</template>

<script setup>

import {computed, onUnmounted, ref, watch} from "vue";
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import VLoaderShip from "@app-vue/components/Base/VLoaderShip.vue";

const conteneditable = ref(false);
const editor = ref();
const loading = ref(false);
const disabled = ref(false);
const isError = ref(false);
const errorMessage = ref();

const props = defineProps({
  filterData: {},
  type: {default: 'model'}, // model or events
  value: {} // for type=events
})
const model = defineModel();
const inputData = ref(model.value);

if(props.type === 'events'){
  watch(() => props.value, () => {
    model.value = props.value;
    inputData.value = props.value;
  },{immediate:true});
}

const emit = defineEmits(['save']);

const focused = ref(false);

const mainClass = computed(() => {
  let classes = [];
  if(conteneditable.value) classes.push('vfine--contenteditable');
  if(loading.value) classes.push('vfine--loading');
  if(disabled.value) classes.push('vfine--disabled');
  if(isError.value) classes.push('vfine--error');
  if(focused.value) classes.push('vfine--focused');
  return classes;
});

function isNumber(str) {
  return /^[-+]?\d+$/.test(str);
}

watch(() => inputData.value, (value, oldValue) => {
  if(
      isNaN(parseInt(inputData.value))
      || !isNumber(inputData.value)
  ){
    disabled.value = true;
  }else if(props.filterData && !props.filterData(parseInt(inputData.value))){
    disabled.value = true;
  }else{
    disabled.value = false;
  }
});

const setContentEditable = (value = null) => {
  if(value !== null){
    inputData.value = value;
  }
  conteneditable.value = true;
  window.addEventListener('keydown', handleEscKey);
  setTimeout(() => {
    editor.value.focus();
  },0);
}

const save = () => {
  if(disabled.value){return;}
  let value = parseInt(inputData.value);

  if(props.type === 'events'){

    loading.value = true;
    emit('save', value);
  }else if(props.type === 'model'){
    model.value = value;
    saveWithSuccess();
    window.addEventListener('keydown', handleEscKey);
  }
}

const saveWithSuccess = () => {
  window.removeEventListener('keydown', handleEscKey);
  conteneditable.value = false;
  loading.value = false;
  isError.value = false;
  if(document.activeElement === editor.value){
    editor.value.blur();
  }
}

const saveWithError = (message) => {
  isError.value = true;
  loading.value = false;
  errorMessage.value = message;
}

defineExpose({setContentEditable,saveWithSuccess,saveWithError});

const handleEscKey = (event) => {
  if (event.key === 'Escape' || event.key === 'Esc') {
    inputData.value = model.value;
    conteneditable.value = false;
    loading.value = false;
    isError.value = false;
    if(document.activeElement === editor.value){
      editor.value.blur();
    }
  }
};

onUnmounted(() => {
  window.removeEventListener('keydown', handleEscKey);
});

</script>

<style scoped>
.v-form-input-number-edit.vfine{
  width:100px;
  background-color: #fff;

  .vfine__input-wrap{
    display: flex;
    border:1px solid #D6E3EF;
    border-radius: 4px;
    justify-content: space-between;
    padding:4px 4px 4px 10px;
    max-width: 100%;
    gap:4px;
    position: relative;
    flex-grow: 1;

    .vfine__apply{
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      font-weight: 500;
      padding: 0px 0px;
      background-color: #219E26;
      color: #fff;
      border-radius: 3px;
      opacity: 0;
      cursor: pointer;
      width: 24px;
      flex-shrink: 0;
      text-align: center;
      justify-content: center;

      .vfine__apply-icon-wrap{
        .vfine__apply-icon{
          display: block;
        }
      }
    }
    .vfine__editor{
      font-size: 14px;
      line-height:23px;
      font-weight: 600;
      display: flex;
      align-items: center;
      height:23px;
      border:none;
      max-width: 100%;
      flex-grow: 1;
    }
    .vfine__editor:focus{
      outline:0px;
      cursor: pointer;
    }

    .vfine__clicker{
      position: absolute;
      width:100%;
      height:100%;
      top:0;
      left:0;
      cursor: pointer;
    }
    .vfine__loading{
      width: 15px;
      height: 15px;
    }

  }

  .vfine__error{
    margin-top:4px;
    font-size:12px;
    line-height: 15px;
    color:#F2001D;
  }

  &.vfine--contenteditable{

    .vfine__apply{
      opacity: 1;
    }
    .vfine__editor{
      max-width: calc(100% - 24px - 8px);
    }

    .vfine__clicker{
      display: none;
    }

    &.vfine--focused{
      .vfine__input-wrap{
        border: 1px solid #1D94FF;
      }
    }

    &.vfine--error{
      .vfine__input-wrap{
        border-color:#F2001D;
      }
    }

    &.vfine--disabled{
      .vfine__apply{
        opacity:0.5;
      }
    }

  }

}
</style>