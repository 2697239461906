<template>
  <div class="lb-order-review-v2-ctx-menu">
    <div class="item" @click="(e) => isMobile || !e.target.closest('.item-stars') ? emit('rateArticle') : null">
      <span>
        Rate article
      </span>
      <v-review-stars :value="currentRating"
                      color="bordered-gray-or-blue"
                      class="item-stars"
                      :no-select="isMobile"
                      @choose-rating="(rating) => emit('rateArticle', rating)"/>
    </div>
    <div v-if="!orderState.hasReviewComment" class="item" @click="emit('addComment')">
      <span>
        Add a comment
      </span>
    </div>
    <div v-if="orderState.hasReviewComment" class="item" @click="emit('changeComment')">
      <span>
        Change a comment
      </span>
    </div>
    <div v-if="!orderState.has_report_problem" class="item" @click="emit('reportProblem')">
      <span>
        Report a problem
      </span>
    </div>
    <div class="item item--disabled" v-if="orderState.has_report_problem">
      <span>
        Report reported
      </span>
    </div>
  </div>
</template>

<script setup>

import {useLinkBuildOrder} from "@app-vue/stores/useLinkBuilding";
import VReviewStars from "@app-vue/components/Base/VReviewStars.vue";
import {computed, getCurrentInstance, ref, watch} from "vue";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const props = defineProps({
  projectUniquekey:{},
  orderId:{},
});


const emit = defineEmits(['rateArticle','addComment','reportProblem', 'changeComment']);

const orderState = useLinkBuildOrder(props.projectUniquekey, props.orderId);

const currentRating = ref(orderState.reviewScore);

watch(() => orderState.reviewScore, (reviewScore) => currentRating.value = reviewScore);

</script>

<style lang="scss" scoped>
.lb-order-review-v2-ctx-menu{
  min-width:200px;
  border-radius: 4px;
  padding:6px 0px;
  background:#fff;
  box-shadow: $box-shadow-tooltip;

  .item{
    @include flex(row,between,center,10px);
    height:40px;
    padding:0px 10px;
    cursor:pointer;
    color:$dark-50;
    background-color:#fff;
    transition-duration: 0.2s;
    &:hover{
      background-color:$secondary-blue-5;
    }
    &.item--disabled{
      color:$dark-30;
      cursor:initial;
      background:#fff;
    }
  }
}
</style>