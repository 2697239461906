<template>
  <div class="bank-transfer-payment btp">

    <div class="btp__payment">
      <div class="btp__row btp__row--head">
        You are purchasing:
        <ul class="btp__purchases">
          <li v-for="item in usePayBank.orders" v-html="usePayBank.getPurchaseNameOrder(item,currentCurrency)"/>
        </ul>
      </div>

      <div class="btp__row btp__row--info">
        <div class="btp__row-label">
          Currency
        </div>
        <div class="btp__row-info">
          <v-form-select-custom
              :options="currencyOptions"
              v-model="currentCurrency"
              :strict-value="false"
              :searchable="true"
              class="btp__currency-select"
          />
        </div>
      </div>

      <div class="btp__row btp__row--info">
        <div class="btp__row-label">
          Total amount
        </div>
        <div class="btp__row-info btp__row-info--bold">
            <span class="amount">{{totalAmount}} {{currentCurrency}}</span>
        </div>
      </div>

      <div class="btp__row btp__row--info">
        <div class="btp__row-label">
          Reference
        </div>
        <div class="btp__row-info btp__row-info--bold">
          SPARKTRAFFIC/{{ usePayBank.orderId }}
        </div>
      </div>

      <div class="btp__row btp__row--info">
        <div class="btp__row-label">
          Bank Details
        </div>
        <div class="btp__row-info">
          <template v-for="item in usePayBank.bankDetails">
            <div class="btp__bank-details" v-if="item.currency === currentCurrency">

              <div class="btp__bank-details-group">
                <div class="btp__bank-details-title">
                  For Local transfers in {{ item.currency }}:
                </div>
                <div class="btp__bank-details-info" v-html="item.local_transfer"/>
              </div>

              <div class="btp__bank-details-group" v-if="item.international_transfer">
                <div class="btp__bank-details-title">
                  For International transfers in {{ item.currency }}:
                </div>
                <div class="btp__bank-details-info" v-html="item.international_transfer"/>
              </div>

            </div>
          </template>
        </div>
      </div>

      <div class="btp__row btp__row--info">
        <div class="btp__row-info btp__row-info--small">
          Please send us a confirmation of the transfer to <v-link href="mailto:support@sparktraffic.com">support@sparktraffic.com</v-link> so that we can add the credits faster.
        </div>
      </div>
    </div>

    <div class="row justify-content-end">
      <div class="col-auto">
        <v-button color="red" @click="cancelPayment">Cancel payment</v-button>
      </div>
    </div>

  </div>
</template>

<script setup>

import {usePayBankTransfer} from "@app-vue/stores/usePayBankTransfer";
import {computed, ref} from "vue";
import VFormSelectCustom from "@app-vue/components/VForm/VFormSelectCustom.vue";
import VLink from "@app-vue/components/Base/VLink.vue";
import VButton from "@app-vue/components/Base/VButton.vue";

const props = defineProps({
  cartType: {},
  projectUniquekey: {default:null,},
});

const usePayBank = usePayBankTransfer(props.projectUniquekey);

const currentCurrency = ref('EUR');

const cancelPayment = () => {
  usePayBank.closeWindow();
  usePayBank.clearOrder();
}

const currencyOptions = computed(() => {
  let response = [];
  Object.keys(usePayBank.totalAmount).forEach((currency) => response.push({value:currency, name:currency}));
  return response;
});

const totalAmount = computed(() => {
  return usePayBank.totalAmount[currentCurrency.value];
});

</script>

<style lang="scss" scoped>
.bank-transfer-payment.btp{
  .btp__payment{
    width: 100%;
    margin-bottom: 24px;
    border: 1px solid rgba(91, 97, 110, 0.2);
    border-radius: 4px;

    .btp__row{

      padding: 24px;
      border-bottom: 1px solid rgba(91, 97, 110, 0.2);

      &.btp__row--head{
        font-size: 18px;
        font-weight: 600;
        color: #1F293B;
      }

      &.btp__row--info{

      }

      &.btp__row--flex-row{
        display:flex;
        align-items:center;
        gap:20px;
        .btp__row-label{
          margin-bottom:0px;
        }
      }

      .btp__row-label{
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #5b616e;
        text-align: left;
      }

      .btp__row-info{
        font-size: 16px;
        color: #1F293B;
        text-align: left;
        overflow-wrap: break-word;
        &.btp__row-info--small{
          font-size: 14px;
        }
        &.btp__row-info--bold{
          font-weight:600;
        }
      }

    }
  }

  .btp__bank-details{
    display:flex;
    flex-direction: column;
    width:100%;
    gap:20px;

    .btp__bank-details-group{
      display:flex;
      flex-direction: column;
      width:100%;
      gap:10px;
    }
    .btp__bank-details-title{
      font-weight:600;
    }
    .btp__bank-details-info{
      font-size:14px;
      line-height:19px;
    }
  }

  .btp__currency-select{
    max-width:200px;
  }

  .btp__purchases{
    font-size:14px;
    margin-bottom:0px;
    margin-top:10px;
  }

}
</style>