<template>
  <div class="v-breadcrumbs" v-if="showItems.length > 0" :class="{'no-padding':props.noPadding}">
    <template v-for="item in showItems">
      <v-link v-if="item.type === 'link'"
              class="item link"
              :href="item.url"
              v-text="item.title"/>
      <v-icon-svg v-else-if="item.type === 'arrow'"
                  class="item icon-arrow"
                  as-block
                  as-img
                  name="arrow-right-2"/>
      <span v-else-if="item.type === 'text'"
            class="item text"
            v-text="item.title"/>
    </template>
  </div>
</template>

<script setup>
import {computed, defineProps} from 'vue';
import VIconSvg from "../Base/VIconSvg.vue";
import VLink from "../Base/VLink.vue";

const getProjectId = () => {
  return window.location.search.replace('?uid=', '');
}

const props = defineProps({
  items: {
    type: Array,
    default: [
      {url: '/', title: 'Website Traffic'},
      {url: '/website-traffic/projects', title: 'My Projects'},
      {title: 'Project'},
    ],
  },
  noPadding: {type: Boolean, default: false},
});

const showItems = computed(() => {
  let response = [];
  props.items.forEach((item,index) => {
    if(index < props.items.length - 1){
      response.push(Object.assign(item,{type:'link'}));
      response.push({type:'arrow'});
    }else{
      let itm = item;
      itm.title = 'Project ' + getProjectId();
      response.push(Object.assign(itm,{type:'text'}));
    }
  });
  return response;
});

</script>

<style lang="scss" scoped>
.v-breadcrumbs{
  @include flex(row,start,center,6px);
  padding-top:20px;
  padding-bottom:20px;

  &.no-padding{
    padding-top:0px;
    padding-bottom:0px;
    @media(max-width:991px){
      padding-top:16px;
    }
  }

  .item{
    &.link{
      white-space: nowrap;
    }
    &.icon-arrow{
      width:16px;
      height:16px;
    }
    &.text{
      @include ellipsis();
      color:$dark-80;
    }
  }

}
</style>