<template>
  <div class="lb-step-cart-build">
    <div class="items">
      <lb-step-cart-build-item v-for="(item, key) in useCart.items"
                               :key="item.itemLocalKey"
                               :project-uniquekey="projectUniquekey"
                               :order-number="key+1"
                               :item-local-key="item.itemLocalKey"/>
    </div>
    <div class="button-add-wrap">
      <v-button-dotted icon="blue-80-plus-rounded" @click="useCart.addItemWithDefault()"/>
    </div>
  </div>
</template>

<script setup>

import VButtonDotted from "@app-vue/components/Base/VButtonDotted.vue";
import {useLinkBuildCart, useLinkBuildProject} from "@app-vue/stores/useLinkBuilding";
import LbStepCartBuildItem from "@app-vue/components/LinkBuilding/LbStepCartBuildItem.vue";

const props = defineProps({
  projectUniquekey:{},
});

const useCart = useLinkBuildCart(props.projectUniquekey);
const useProject = useLinkBuildProject(props.projectUniquekey);

</script>

<style lang="scss" scoped>
.lb-step-cart-build{
  @include flex(column,start,stretch,16px);
  .items{
    @include flex(column,start,stretch,16px);
    @media(max-width: 767px){
      gap:12px;
    }
  }
  .button-add-wrap{
    padding-left:27px;
    padding-right:40px;
    @media(max-width: 767px){
      padding:0px;
    }
  }
}
</style>