<template>
  <div class="bc-payer-bank-transfer bcpbt" :class="mainClass">

    <transition name="fade" mode="out-in">
      <div v-if="!usePayBank.windowActive" class="bcpbt__inner">
        <div class="bcpbt__group" v-for="item in bankTransferItems">
          <div class="bcpbt__icons" v-if="item.icons.length > 0">
            <img v-for="icon in item.icons" :src="icon.src" :style="icon.style">
          </div>
          <div class="bcpbt__button-wrap">
            <v-button
                color="green"
                class="bcpbt__button"
                @click="openPayWindow"
                :loading="payButtonLoading"
                :disabled="isDisabledButton"
            >{{item.buttonTitle}}</v-button>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade" mode="out-in">
      <div v-if="usePayBank.windowActive">
        <bc-payer-bank-transfer-payment :cart-type="cartType" :project-uniquekey="projectUniquekey"/>
      </div>
    </transition>

  </div>
</template>

<script setup>
import VButton from "@app-vue/components/Base/VButton.vue";
import {computed, reactive, ref, watch} from "vue";
import {usePayBankTransfer} from "@app-vue/stores/usePayBankTransfer.js";
import BcPayerBankTransferPayment from "@app-vue/components/BuyCredits/BcPayer/BcPayerBankTransferPayment.vue";
import {useBillingCart} from "@app-vue/stores/useBilling";
import {useLinkBuildCart} from "@app-vue/stores/useLinkBuilding";

const bankTransferItems = reactive([
  {
    icons: [
      {
        src:'/img/icons/bank.svg',
        style: {
          width:'48px',
          height:'48px'
        }
      }
    ],
    buttonTitle: 'Pay by Bank Transfer',
  }
]);

const props = defineProps({
  items: {type:Array,default:[]},
  type: {},
  cartType: {},
  projectUniquekey: {default:null,},
});

const usePayBank = usePayBankTransfer(props.projectUniquekey);

const mainClass = computed(() => {
  return 'bcpbt--bank';
});

const payButtonLoading = ref(false)

const openPayWindow = () => {
  payButtonLoading.value = true;
  usePayBank.pay().then((response) => {
    payButtonLoading.value = false;
    let [status, message] = response;
    if(status){
      usePayBank.openWindow();
    }else{
      console.log('error', message);
    }
  });
}

const cancelPayment = () => {
  usePayBank.closeWindow();
  usePayBank.clearOrder();
}

watch(() => {
  switch(props.cartType){
    case 'main' : return useBillingCart().totalPrice;
    //case 'link-building' : return useLinkBuildCart(props.projectUniquekey).finalAmount;
  }
}, () => {
  cancelPayment();
});

const isDisabledButton = computed(() => {
  switch(props.cartType){
    case 'main' : return useBillingCart().totalPrice === 0;
    case 'link-building' : return useLinkBuildCart(props.projectUniquekey).finalAmount <= 0;
  }
});

</script>

<style lang="scss" scoped>

.bc-payer-bank-transfer.bcpbt{


  .bcpbt__inner:first-child{
    display: flex;
    flex-direction: column;
    gap:20px;
  }

  .bcpbt__group{
    display:flex;
    flex-direction:column;
    gap:20px;

    @media(max-width: 767px){
      gap:16px;
    }

    .bcpbt__icons{
      display:flex;
      justify-content:center;
      align-items:center;
      gap:16px;
      flex-wrap:wrap;
      padding:6px;

      @media(max-width:767px){
        padding:0px;
        gap:6px;
      }

      img {
        width:48px;
        height:48px;
      }


    }

    .bcpbt__button-wrap{
      .bcpbt__button{
        width:100%;
        font-weight:600;
      }
    }

  }

}

.fade-enter-from{
  opacity:0;
}
.fade-enter-active{
  transition: opacity 0.5s;
}
.fade-enter-to{
  opacity:1;
}
.fade-leave-from{
  display:none;
}
.fade-leave-active{
  display:none;
}
.fade-leave-to{
  display:none;
}

</style>