<template>
  <div class="lb-step-cart-order-settings">

    <lb-step-cart-packages :project-uniquekey="projectUniquekey"/>

    <div class="coupon-wrap">
      <lb-step-cart-coupon :discount-price="useCart.discountPrice"/>
    </div>

    <div class="vat-wrap">
      <div class="lb-step-cart-vat">
        <div v-if="useCart.vatRate > 0" class="info">
          VAT {{useCart.vatRate}}%
        </div>

      </div>
    </div>

    <div class="order-settings__total">
      <span>Pay now: ${{priceFormatted(useCart.finalAmount)}}</span>
    </div>

    <input type="hidden" name="package_quantity" :value="useCart.packageQuantity">
    <input type="hidden" name="backlink_project_id" :value="linkBuildProjectStore.id">

  </div>
</template>

<script setup>

import LbStepCartPackages from "@app-vue/components/LinkBuilding/LbStepCartPackages.vue";
import {priceFormatted} from "@app-vue/utils/utils";
import {useLinkBuildCart, useLinkBuildProject} from '@app-vue/stores/useLinkBuilding';
import LbStepCartCoupon from "@app-vue/components/LinkBuilding/LbStepCartCoupon.vue";

const props = defineProps({
  projectUniquekey: {},
});

const useCart = useLinkBuildCart(props.projectUniquekey);
const linkBuildProjectStore = useLinkBuildProject(props.projectUniquekey);

</script>

<style lang="scss" scoped>
.lb-step-cart-order-settings{
  @include flex(column,start,stretch,3px);
  .order-settings__total{
    @include flex(row,end,center);
    font:$font-14-regu;
    border-top:1px solid $dark-10;
    padding:14px 0px;
  }
  .coupon-wrap{
    @include flex(row,end,center);
    min-height:40px;
    padding:8px 0px;
  }
  .vat-wrap{
    @include flex(row,end,center);
    min-height:40px;
    padding:8px 0px;
  }
}
</style>