import {createStore} from "vuex";
import {popperInstancesModule} from "@app-vue/store/popperInstancesModule";
import {resizeObserverModule} from "@app-vue/store/resizeObserverModule";
import {userNotificationsModule} from "@app-vue/store/userNotificationsModule";
import {checklistsWidgetModule} from "@app-vue/store/checklistsWidgetModule";

export default createStore({
    modules: {
        popperInstances: popperInstancesModule,
        gResizeObserver: resizeObserverModule,
        userNotifications: userNotificationsModule,
        checklistsWidget: checklistsWidgetModule
    }
})