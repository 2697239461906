<template>
  <div class="bc-payer-base bcps" :class="mainClass">

    <div class="bcps__group" v-for="item in items">
      <div class="bcps__icons" v-if="item.icons.length > 0">
        <img v-for="icon in item.icons" :src="icon.src" :style="icon.style" alt="">
      </div>
      <div class="bcps__button-wrap">
        <v-button
            color="green"
            @click.prevent="redirectToPay(item.payType)"
            class="bcps__button"
            :loading="btnLoading"
            :disabled="isDisabledButton"
        >{{item.buttonTitle}}</v-button>
      </div>
      <v-form-message-error :message-error="errorMessage" :no-margin/>
    </div>

  </div>
</template>

<script setup>

import VButton from "@app-vue/components/Base/VButton.vue";
import {computed, ref} from "vue";
import {useBillingCart} from "@app-vue/stores/useBilling";
import VFormMessageError from "@app-vue/components/VForm/VFormMessageError.vue";
import {useLinkBuildCart, useLinkBuildProject} from "@app-vue/stores/useLinkBuilding";

const props = defineProps({
  items: {type:Array,default:[]},
  type: {},
  cartType: {},
  projectUniquekey: {},
});

const mainClass = computed(() => {
  return props.type ? 'bcps--'+props.type : '';
});

const btnLoading = ref(false);

const errorMessage = ref(null);

const redirectToPay = (payType) => {
  btnLoading.value = true;
  let options = {};
  if(props.cartType === 'link-building'){
    options.plan_id = useLinkBuildCart(props.projectUniquekey).planOption.id;
    options.quantity = useLinkBuildCart(props.projectUniquekey).packageQuantity;
    options.backlink_project_id = useLinkBuildProject(props.projectUniquekey).id;
  }
  useBillingCart().getPayData(payType, options).then((response) => {
    let [status, data] = response;
    if(status){
      errorMessage.value = null;
      window.location.href = data.url;
    }else{
      errorMessage.value = data;
      btnLoading.value = false;
    }
  })
}

const isDisabledButton = computed(() => {
  switch(props.cartType){
    case 'main' : return useBillingCart().totalPrice === 0;
    case 'link-building' : return useLinkBuildCart(props.projectUniquekey).finalAmount <= 0;
  }
});

</script>

<style scoped lang="scss">

.bc-payer-base.bcps{
  display: flex;
  flex-direction: column;
  gap:20px;

  .bcps__group{
    display:flex;
    flex-direction:column;
    gap:20px;

    @media(max-width: 767px){
      gap:16px;
    }

    .bcps__icons{
      display:flex;
      justify-content:center;
      align-items:center;
      gap:16px;
      flex-wrap:wrap;
      padding:6px;

      @media(max-width:767px){
        padding:0px;
        gap:6px;
      }

      img {
        width:48px;
        height:48px;
      }


    }

    .bcps__button-wrap{
      .bcps__button{
        width:100%;
        font-weight:600;
      }
    }

  }

  &.bcps--cards{
    @media(max-width:767px){
      .bcps__icons{
        max-width:250px;
        margin-left:auto;
        margin-right:auto;
      }
    }
  }

  &.bcps--paypal{
    .bcps__icons{
      width:100%;
      height:48px;
      margin:auto;
    }
  }

  &.bcps--crypto{
    .bcps__icons{

      margin-left:auto;
      margin-right:auto;
      margin-bottom:-6px;
      padding:3px;
      @media(max-width:767px){
        max-width:240px;
      }
      img{
        width:38px;
        height:38px;
        margin-bottom:6px;
      }

    }
  }

}

</style>