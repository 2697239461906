<template>
  <div class="lb-order-review-v2">
    <div class="review-summary" ref="refReviewSummary" @click="refTooltipCtxMenu.toggle()">
      <div class="item item--star">
        <span>{{orderState.reviewScore}}</span>
        <v-icon-svg name="star-bordered" as-img style="margin-top:-1px"/>
      </div>
      <div class="item item--chat">
        <span>{{orderState.reviewCountComments}}</span>
        <v-icon-svg name="chat-bordered" as-img/>
      </div>
      <div class="item" v-if="orderState.has_report_problem">
        <v-icon-svg name="excl-mark-circle-bordered" as-img/>
      </div>
    </div>
    <v-tooltip ref="refTooltipCtxMenu"
               :get-linked-block="() => refReviewSummary"
               placement="bottom"
               btn-close-off
               reset-tooltip>
      <template #content>
        <lb-order-review-v2-ctx-menu :project-uniquekey="projectUniquekey"
                                     :order-id="orderId"
                                     @rate-article="rateArticle"
                                     @report-problem="reportProblem"
                                     @add-comment="addComment"
                                     @change-comment="addComment"/>
      </template>
    </v-tooltip>

    <v-tooltip placement="bottom"
               ref="refTooltipReview"
               :style="{width:'100%', maxWidth:'400px', borderRadius:'8px',padding:'24px'}"
               :btn-close-on="true"
               :get-linked-block="() => refReviewSummary">
      <template v-slot:content>
        <lb-order-review-leave
            ref="refOrderReviewLeave"
            :project-uniquekey="projectUniquekey"
            :order-id="orderState.id"
            @review-leave-success="refTooltipReview.hide()"
        />
      </template>
    </v-tooltip>

    <v-tooltip placement="bottom"
               ref="refTooltipReport"
               reset-tooltip
               follow-linked-block
               :get-linked-block="() => refReviewSummary">
      <template v-slot:content>
        <lb-order-report-problem :project-uniquekey="projectUniquekey" :order-id="orderId"/>
      </template>
    </v-tooltip>

  </div>
</template>

<script setup>

import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import VTooltip from "@app-vue/components/Base/VTooltip.vue";
import {ref} from "vue";
import LbOrderReviewV2CtxMenu from "@app-vue/components/LinkBuilding/LbOrderReviewV2CtxMenu.vue";
import {useLinkBuildOrder} from "@app-vue/stores/useLinkBuilding";
import LbOrderReviewLeave from "@app-vue/components/LinkBuilding/LbOrderReviewLeave.vue";
import LbOrderReportProblem from "@app-vue/components/LinkBuilding/LbOrderReportProblem.vue";

const props = defineProps({
  projectUniquekey: {},
  orderId: {},
  canLeaveReportProblem: {type:Boolean,default:false,}
});

const refReviewSummary = ref();
const refTooltipCtxMenu = ref();
const refTooltipReview = ref();
const refOrderReviewLeave = ref();
const refTooltipReport = ref();

const orderState = useLinkBuildOrder(props.projectUniquekey, props.orderId);

function rateArticle(rating){
  if(rating){
    refOrderReviewLeave.value.preSetRating(rating);
  }
  refTooltipCtxMenu.value.hide();
  refTooltipReview.value.show();
}

function addComment(){
  refTooltipCtxMenu.value.hide();
  refTooltipReview.value.show();
}

function reportProblem(){
  refTooltipCtxMenu.value.hide();
  refTooltipReport.value.show();
}

</script>

<style lang="scss" scoped>
.lb-order-review-v2{

  .review-summary{
    cursor: pointer;
    @include flex(row,start,center,7px);
    font:$font-mini-12-regu;
    color:$dark-30;
    .item{
      @include flex(row,start,center);
      &.item--chat{
        gap:3px;
      }
      &.item--star{
        gap:1px;
      }
      >span{
        @include flex(row,start,center);
        height:15px;
      }
    }
  }

}
</style>