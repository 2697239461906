import {defineStore} from "pinia";
import {isArray} from "lodash";

export const useExperiments = defineStore('experiments', {
    state: () => {
        return {
            list: [],
        }
    },
    getters: {
        isLinkBuildingOneCredit(state){
            return true;
            return state.list.includes('link-building-one-credit');
        }
    },
    actions: {
        init(){
            if(isArray(window.listExperiments) && window.listExperiments.length > 0 &&  this.list.length === 0){
                this.$patch({list: window.listExperiments});
            }
        }
    }

});