<template>
  <div class="wt-project-preview-realtime">

    <div class="title" v-if="!isMobile">
      <v-icon-realtime-pulse class="title-pulse" :is-active="useProject.isActiveRealtime"/>
      Realtime {{amountShow <= 10000 ? abbrNumberFormat(amountShow, 1) : abbrNumberFormat(amountShow, 0, true)}}
      <v-tag-active v-if="useProject.isActiveRealtime"/>
    </div>

    <div class="title-in-one-row" v-if="isMobile">
      <v-icon-realtime-pulse :is-active="useProject.isActiveRealtime"/>
      <div class="title-in-one-row__text-wrap">
        <span class="title-in-one-row__title">Realtime</span>
        <span class="title-in-one-row__value">{{amountShow <= 10000 ? abbrNumberFormat(amountShow, 1) : abbrNumberFormat(amountShow, 0, true)}}</span>
      </div>
      <div class="title-in-one-row__tag-wrap">
        <v-tag-active size="mini" v-if="useProject.isActiveRealtime"/>
      </div>
    </div>

    <div class="chart-wrap">
      <div class="chart-title"></div>
      <chart-realtime-v2 :values="useProjectRealtime.values.slice(-countBars)"
                         class="chart"
                         :animate-last-bar="useProject.isActiveRealtime"
                         :show-labels="isMobile ? 'at-the-ends' : 'at-the-ends-absolute'"/>
    </div>
  </div>
</template>

<script setup>

import ChartRealtimeV2 from "../Chart/ChartRealtimeV2.vue";
import VIconRealtimePulse from "../Base/VIconRealtimePulse.vue";
import VTagActive from "../Base/VTagActive.vue";
import {useRealtimeWebsiteTrafficProject, useWebsiteTrafficProject} from "../../stores/useWebsiteTraffic";
import {computed, getCurrentInstance} from "vue";
import {abbrNumberFormat} from '@app-vue/utils/utils.js';

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const props = defineProps({
  projectId: {},
  countBars: {type:Number,},
});

const useProjectRealtime = useRealtimeWebsiteTrafficProject(props.projectId);
const useProject = useWebsiteTrafficProject(props.projectId);

const amountShow = computed(() => {
  return useProjectRealtime.values.slice(-props.countBars).reduce((s,i) => s+i, 0);
})

</script>

<style lang="scss" scoped>
.wt-project-preview-realtime {
  @include flex(column, start, stretch, 4px);

  .title {
    @include flex(row, start, center, 5px);
    height:18px;
    color: $dark-80;
    font: $font-14-semi;
    .title-pulse{
      margin-right:5px;
    }
  }

  .chart-wrap {
    @include flex(column,start,stretch,8px);
  }

  .chart-title {
    @include flex(row,end,center,7px);
    font: $font-16-semi;
    color: $dark-80;
    height:19.5px;
  }

  .chart {
    width:100%;
    height:90px;
    @media(max-width: 767px){
      height: 80px;
    }
  }

  .title-in-one-row{
    @include flex(row,start,center,8px);
    padding-left:6px;
    height:18px;
    .title-in-one-row__text-wrap{
      @include flex(row,start,center,5px);
    }
    .title-in-one-row__title{
      font:$font-mini-12-regu;
      color:$dark-40;
    }
    .title-in-one-row__value{
      font:$font-mini-12-semi;
      color:$dark-80;
    }
    .title-in-one-row__tag-wrap{
      @include flex(row,start,center);
    }
  }

}
</style>