<template>
  <v-project-preview class="wt-project-preview"
                     type="website-traffic"
                     :is-demo="useProject.is_demo"
                     :is-expired="useProject.is_expired && !useProject.is_unlim"
                     v-intersect="{callback:updateIntersect, options: {rootMargin: '1000px'}}">

    <template #default>

      <div class="content-wrap">

        <v-project-bar-danger v-if="useProject.showBarDangerExpired"
                              text="Project Expired"
                              size="for-preview"
                              class="content-wrap__project-bar"
                              :button-props="{
                                color:'green',
                                tag:'a',
                                text:'Renew Now',
                                href:useRoutes().url('website-traffic.renew', {id: useProject.id}),
                              }"/>

        <v-project-bar-danger v-if="useProject.showBarDangerPaused"
                              text="Project paused"
                              size="for-preview"
                              class="content-wrap__project-bar"
                              :button-props="{
                              clickAction:[useProject.saveIsActive,true],
                              color:'green',
                              icon:'icon-play-white-v2',
                              text:'Resume',
                              style:{
                                paddingLeft:'8px',
                                paddingRight:'8px',
                              }
                            }"/>

        <v-project-bar-danger v-else-if="useProject.showBarDangerCredits"
                              text="No credits"
                              size="for-preview"
                              class="content-wrap__project-bar"
                              :button-props="{
                                color:'green',
                                tag:'a',
                                text:'Buy Now',
                                href:useRoutes().url('website-traffic-pay.get'),
                              }"/>

        <div class="content">
          <div class="info content__info" :class="{'content__info--full-width':useProject.is_deleted}">

            <div class="info__top">

              <div class="info__id">
                {{useProject.id}}
                <span v-if="useProject.createdDate">Created {{formatDateDDMMYYYY(useProject.createdDate)}}</span>
                <div class="info__tag-deleted" v-if="useProject.is_deleted" v-text="'DELETED'"/>
              </div>

              <v-link class="info__title"
                      :class="useProject.is_deleted ? 'opacity-50' : ''"
                      :href="useProject.linkProject"
                      :text="useProject.name"/>

              <span class="info__domain"
                    :class="useProject.is_deleted ? 'opacity-50' : ''"
                    v-text="useProject.shortUrl"/>

              <span class="info__search_engine" v-if="useProject.searchEngine">
              <v-icon-svg-wrapped :name="useProject.searchEngine?.icon" :outer-size="20" :inner-size="[15,null]" as-block as-img/>
              {{useProject.searchEngine?.name}}
            </span>

            </div>

            <v-button class="button-settings info__button"
                      v-if="!isMobile"
                      tag="a"
                      :href="useProject.linkProject"
                      color="blue2-stroke"
                      text="Settings"/>

          </div>

          <div class="charts content__charts"
               v-if="!useProject.is_deleted"
               :class="useProject.is_demo ? 'charts--demo' : ''">

            <template v-if="!isMobile && isIntersect">
              <wt-project-preview-realtime class="charts__realtime"
                                           :project-id="projectId"
                                           :count-bars="15"/>

              <wt-project-preview-chart-clicks class="charts__clicks"
                                               :project-id="projectId"
                                               v-if="!useProject.is_demo"/>

              <wt-project-preview-countries class="charts__countries"
                                            v-if="!useProject.is_demo"
                                            :project-id="projectId"/>

              <div v-if="useProject.is_demo" class="charts__separator"/>

              <wt-project-demo-bar v-if="useProject.is_demo"
                                   class="charts__demo-info"
                                   :project-uniquekey="useProject.id"
                                   is-compact/>
            </template>

            <template v-else-if="isMobile && !useProject.is_demo && isIntersect">

              <Flicking ref="refChartsSliderFlicking"
                        class="charts-slider"
                        :plugins="[new Pagination({type: 'bullet' })]">

                <div class="charts-slider__item" :key="0">
                  <div class="charts-slider__charts">
                    <wt-project-preview-realtime class="charts-slider__realtime"
                                                 :project-id="projectId"
                                                 :count-bars="15"/>
                    <wt-project-preview-chart-clicks class="charts-slider__chart-clicks"
                                                     :project-id="projectId"/>
                  </div>
                </div>

                <div class="charts-slider__item"
                     v-if="Object.keys(useProject.clicks_by_country).length > 0"
                     :key="1">
                  <wt-project-preview-countries class="charts-slider__countries"
                                                :project-id="projectId"/>
                </div>

                <template #viewport>
                  <div class="flicking-pagination charts-slider__pagination"
                       :class="{hide: Object.keys(useProject.clicks_by_country).length === 0}"/>
                </template>

              </Flicking>

            </template>

            <template v-else-if="isMobile && useProject.is_demo && isIntersect">
              <wt-project-preview-realtime class="charts-slider__realtime"
                                           :project-id="projectId"
                                           :count-bars="30"/>
              <wt-project-demo-bar class="charts__demo-info"
                                   :project-uniquekey="useProject.id"
                                   is-compact/>
            </template>

          </div>

          <v-button class="button-settings content__button"
                    v-if="isMobile"
                    tag="a"
                    :href="useProject.linkProject"
                    color="blue2-stroke"
                    :class="{'content__button--left': useProject.is_deleted}"
                    text="Settings"/>
        </div>
      </div>
    </template>

  </v-project-preview>
</template>

<script setup>

import VLink from "../Base/VLink.vue";
import VButton from "../Base/VButton.vue";
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import VIconSvg from "../Base/VIconSvg.vue";
import WtProjectDemoBar from "./WtProjectDemoBar.vue";
import {useWebsiteTrafficProject} from "../../stores/useWebsiteTraffic";
import VProjectPreview from "../Base/VProjectPreview.vue";
import {useRoutes} from "../../stores/useRoutes";
import WtProjectPreviewRealtime from "./WtProjectPreviewRealtime.vue";
import WtProjectPreviewChartClicks from "./WtProjectPreviewChartClicks.vue";
import WtProjectPreviewCountries from "./WtProjectPreviewCountries.vue";
import Flicking from "@egjs/vue3-flicking";
import { Pagination } from "@egjs/flicking-plugins";
import {formatDateDDMMYYYY} from "../../utils/utils";
import "@egjs/vue3-flicking/dist/flicking.css";
import {useMounted} from "../../utils/useMounted";
import VIconSvgWrapped from '../Base/VIconSvgWrapped.vue';
import VProjectBarDanger from '../Base/VProjectBarDanger.vue';

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const {isMounted} = useMounted();

const props = defineProps({
  projectId: {},
});

const refChartsSliderFlicking = ref();

const useProject = useWebsiteTrafficProject(props.projectId);

function updateInfoProject(){
  if(useProject.is_demo && !useProject.demoCompleted){
    setTimeout(() => {
      if(!isMounted.value) return;
      useProject.download().then(() => updateInfoProject());
    },5000);
  }
}

onMounted(() => updateInfoProject());

const isIntersect = ref(false);
function updateIntersect(value){isIntersect.value = value}

</script>

<style lang="scss" scoped>
.wt-project-preview{

  .content-wrap{
    .content-wrap__project-bar{
      border-radius:0px 4px 0px 0px;
    }
  }

  .content{
    @include flex(row,between,stretch,24px);
    width:100%;
    padding:24px 24px 24px 20px;
    @media(max-width: 767px) {
      @include flex(column,start,stretch,16px);
      padding:16px;
    }
    &.content--modal{
      height:100%;
      @media(max-width:767px){
        @include flex(column,between,stretch);
      }
    }
    .content__info{
      width:calc(100% - 600px - 24px);
      @media(max-width: 767px){
        width:100%;
      }
      &.content__info--full-width{
        width:100%;
      }
    }
    .content__charts{
      flex-shrink:0;
      width:600px;
      min-height:140px;
      @media(max-width: 767px){
        width:100%;
        min-height:102px;
        &.charts--demo{
          min-height:158px;
        }
      }
    }
    .content__button{
      align-self:flex-end;
      &.content__button--left{
        align-self:flex-start;
      }
    }
  }

  .info{
    @include flex(column,between,start, 28px);

    .info__top{
      @include flex(column,start,start,6px);
      max-width:100%;
    }

    .info__id{
      @include flex(row,start,center, 6px);
      font:$font-mini-12-regu;
      color:$dark-40;
      &.info__id--white{
        color:#fff;
      }
      @media (max-width: 767px) {
        font:$font-mini-10-regu;
      }
    }

    .info__title{
      @include ellipsis();
      font:$font-16-semi;
      @media (max-width: 767px) {
        font:$font-14-semi;
      }
    }

    .info__domain{
      @include ellipsis();
      color:$dark-50;
    }

    .info__search_engine{
      color:$dark-50;
      @include flex(row,start,center,4px);
    }

    .info__tag-demo{
      font:$font-mini-12-semi;
      color:$dark-40;
    }

    .info__tag-deleted{
      font:$font-mini-12-semi;
      color:$optional-red_120;
    }

    .info__tag-expired{
      font:$font-mini-12-semi;
      color:$optional-red_120;
    }

  }

  .charts{
    @include flex(row,between,stretch,36px);

    &.charts--demo{
      gap:24px;
      @media(max-width: 767px){
        @include flex(column,start,stretch,16px);
      }
    }

    .charts__realtime{
      width: 170px;
      flex-shrink: 0;
    }
    .charts__clicks{
      flex-grow:2;
    }
    .charts__separator{
      width:1px;
      background:$dark-10;
      flex-shrink:0;
    }
    .charts__demo-info{
      flex-grow:2;
      align-self:flex-start;
    }
    .charts__countries{
      width:128px;
      flex-shrink: 0;
    }
  }

  .modal-info{
    @include flex(column,center,center, 12px);
    position:absolute;
    z-index:10;
    top: 50%;
    left: 50%;
    width:100%;
    transform: translate(-50%, -50%);

    &.modal-info--has-subtitle{
      gap:8px;
    }

    .modal-info__title{
      font:$font-h2-20-semi;
      color:$white-100;
      @media(max-width:767px){
        font:$font-16-semi;
      }
    }
    .modal-info__sub_title{
      @include flex(row,center,center,8px);
      color: $white-100;
    }
    .modal-info__button{
      @include flex(row,center,center,8px);
      padding-top:0;
      padding-bottom:0;
      height:40px;
    }
  }

  .button-settings{
    padding:0px 14px;
    height:32px;
  }

  .charts-slider{
    @include flex(column,start,stretch,16px);
    .charts-slider__item{
      width:100%;
    }
    .charts-slider__charts{
      @include flex(row,between,stretch,12px);
    }
    .charts-slider__realtime{
      width:50%;
    }
    .charts-slider__chart-clicks{
      width:50%;
    }
    .charts-slider__countries{

    }
    .charts-slider__pagination{
      @include flex(row,center,center,6px);
      position:initial;
      &.hide{
        display:none;
      }
      &:deep(.flicking-pagination-bullet){
        width:6px;
        height:6px;
        background: $secondary-blue-10;
        border-radius: 50%;
        transition-duration: 0.2s;
        &.flicking-pagination-bullet-active{
          background:$secondary-blue-100;
        }
      }
    }

  }

}
</style>