import {defineStore} from "pinia";
import {ApiResource} from "@app-vue/plugins/ApiExtends";
import {useLinkBuildCart, useLinkBuildProject, useLinkBuildProjectOrders} from "@app-vue/stores/useLinkBuilding";
import {useUserBalance} from "@app-vue/stores/useStaticData";

export const usePayByCrypto = (projectUniquekey = null) => defineStore(`pay-by-crypto-${projectUniquekey}`, {

    state: () => {
        return {
            projectUniquekey: projectUniquekey,
            asset_id:'BTC',
            blockchain:'BTC',
            windowActive: false,

            plan_id:null,
            order_id: null,
            crypto_address:null,
            total_crypto_amount: null,
            start_time: null,
            status: null,
        }
    },

    getters: {

        totalCryptoAmountFormatted: (state) => {
            if(state.asset_id?.toLowerCase() === 'usdt' || state.asset_id?.toLowerCase() === 'usdc'){
                return parseFloat(state.total_crypto_amount).toFixed(2);
            }else{
                return state.total_crypto_amount;
            }
        },

        /**
         * Есть ли в текущем state order ?
         */
        hasOrder: (state) => {
            return state.order_id !== null;
        },

        /**
         * Текущий ордер оплачен ?
         */
        orderIsPaid: (state) => {
            return state.status === 'paid';
        },

        /**
         * Текущий ордер в ожидании ?
         */
        orderIsPending: (state) => {
            return state.status === 'pending';
        },

        /**
         * Текущий ордер в отменен ?
         */
        orderIsCanceled: (state) => {
            return state.status === 'canceled';
        },

        /**
         * Текущий ордер в отменен ?
         */
        orderIsTimeIsOver: (state) => {
            return state.status === 'time_is_over';
        },

    },

    actions: {

        /**
         * передаем plan_id, добавляем новый ордер, сохраняем его данные в state
         */
        async pay(){

            this.clearOrder();

            let options = {
                asset_id: this.asset_id,
                blockchain: this.blockchain,
            }

            if(this.projectUniquekey){
                options.plan_id = useLinkBuildCart(this.projectUniquekey).planOption.id;
                options.quantity = useLinkBuildCart(this.projectUniquekey).packageQuantity;
                options.backlink_project_id = useLinkBuildProject(this.projectUniquekey).id;
            }

            let resource = new ApiResource({
                url: '/api/v1/cart/pay/crypto',
                method:'GET',
                params: options
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){

                let data = {
                    order_id: resource.data.order_id,
                    crypto_address: resource.data.crypto_address,
                    asset_id: resource.data.asset_id,
                    blockchain: resource.data.blockchain,
                    total_crypto_amount: resource.data.total_crypto_amount,
                    start_time: Date.now(),
                    status: 'pending',
                }

                this.$patch(data);

                return [true];
            }else{
                return [false, resource.errorMessage]
            }

        },

        /**
         * Открыть окно оплаты
         */
        openWindow(){
            this.$patch({windowActive: true});
        },

        /**
         * Закрыть окно оплаты
         */
        closeWindow(){
            this.$patch({windowActive: false});
        },

        /**
         * Отмена заказа, меняем статус
         */
        async cancel(){

            let order_id = this.order_id;

            let resource = new ApiResource({
                url: '/api/v1/payment-orders/'+order_id+'/cancel',
                method:'DELETE',
                params: {}
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch({status:'canceled'});
                return [true];
            }else{
                return [false, resource.errorMessage];
            }

        },

        async timeIsOver(){
          this.$patch({status:'time_is_over'});
        },

        /**
         * Проверяем, существует ли заказ, если он есть, то обновить данные ордера
         */
        async check(){

            let resource = new ApiResource({
                url: '/api/v1/check-crypto-order',
                method:'GET',
                params: {}
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){

                this.$patch({
                    order_id: resource.data.order_id,
                    start_time: new Date(resource.data.created_at),
                    status: resource.data.status,
                    asset_id: resource.data.currency,
                    total_crypto_amount:resource.data.amount,
                    plan_id: resource.data.plan_id,
                    blockchain: resource.data.blockchain,
                    crypto_address: resource.data.crypto_address,
                })

                if(this.projectUniquekey){
                    if(resource.data.status === 'paid'){
                        Promise.all([
                            useUserBalance().download(),
                            useLinkBuildProjectOrders(this.projectUniquekey).download(),
                            useLinkBuildCart(this.projectUniquekey).update()
                        ]).then(() => {
                            this.clearOrder();
                            this.closeWindow();
                            useLinkBuildProject(this.projectUniquekey).orderCreationStatusSetStart();
                        });
                    }
                }

                return [true];
            }else{
                return [false, resource.errorMessage]
            }

        },

        /**
         * Очищаем state ордера
         */
        clearOrder(exceptions = []){

            let resetData = {
                'plan_id': null,
                'order_id': null,
                'crypto_address': null,
                'total_crypto_amount': null,
                'start_time':null,
                'status':null,
            };
            let cols = Object.keys(resetData);

            let colsDiff = cols.filter(item => !exceptions.includes(item));

            this.$patch((state) => {
                colsDiff.forEach(colName => {
                    state[colName] = resetData[colName];
                })
            });

            //this.deleteLocalStorage();
        },


    }

})();