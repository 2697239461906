<template>
  <v-container class="bh-page-main">

    <v-breadcrumbs :items="breadcrumbs" v-if="isMobile"/>

    <v-white-container class="inner-wrap">

      <v-head-title class="title">
        Billing History
      </v-head-title>

      <v-search-bar-v3 placeholder="Search by ID or date" v-model="filterSearchString"/>

      <div class="controls">
        <v-form-checkbox-slider v-model="filterOnlyPayment"/>
        <span>Show only payments</span>
      </div>

      <bh-table v-if="!isMobile"/>
      <bh-mobile-list :filter-only-payment="filterOnlyPayment" :filter-search-string="filterSearchString" v-if="isMobile" class="mobile-list"/>

    </v-white-container>
  </v-container>
</template>

<script setup>

import VContainer from "@app-vue/components/Base/VContainer.vue";
import VHeadTitle from "@app-vue/components/Base/VHeadTitle.vue";
import VWhiteContainer from "@app-vue/components/Base/VWhiteContainer.vue";
import VSearchBarV3 from "@app-vue/components/Base/VSearchBarV3.vue";
import VFormCheckboxSlider from "@app-vue/components/VForm/VFormCheckboxSlider.vue";
import BhTable from "@app-vue/components/BillingHistory/BhTable.vue";
import {computed, getCurrentInstance, ref, watch} from "vue";
import BhMobileList from "@app-vue/components/BillingHistory/BhMobileList.vue";
import VBreadcrumbs from "@app-vue/components/Base/VBreadcrumbs.vue";
import {useBillingHistory} from "@app-vue/stores/useBillingHistory";
import {useCreditTypes} from "@app-vue/stores/useBilling";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const breadcrumbs = ref([
  {url: '#', title: 'Subscriptions & Bonuses',},
  {title: 'FTV8K0O4O0OW699Q',}
]);

const filterOnlyPayment = ref(false);
const filterSearchString = ref('');

watch(() => filterOnlyPayment.value, (filterOnlyPayment) => {
  useBillingHistory().$patch((state) => state.filters.onlyPayment = filterOnlyPayment);
});
watch(() => filterSearchString.value, (filterSearchString) => {
  useBillingHistory().$patch((state) => state.filters.searchString = filterSearchString);
});

useBillingHistory().download();
useCreditTypes().download();

</script>

<style lang="scss" scoped>
.bh-page-main{
  margin-bottom: 100px;
  .inner-wrap{
    @include flex(column,start,stretch,30px);
    @media (max-width: 767px) {
      gap:14px;
    }
  }

  .controls{
    @include flex(row,start,center,10px);
    color:$dark-80;
  }

  .mobile-list{
    margin-top:5px;
  }

}
</style>