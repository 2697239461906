<template>
  <div class="bc-balance bcb" :class="mainClass">
    <div class="bcb__left">
      <div class="bcb__title">
        Balance
      </div>
    </div>
    <div class="bcb__right">
      <bc-balance-item
        v-for="balanceItem in list"
        :key="balanceItem.typeConstant"
        :type-constant="balanceItem.typeConstant"
      />
    </div>
  </div>
</template>

<script setup>

import BcBalanceItem from "@app-vue/components/BuyCredits/BcBalanceItem.vue";
import {useUserBalance} from "@app-vue/stores/useStaticData";
import {computed} from "vue";
import {CREDIT_TYPE_BACKLINK, CREDIT_TYPE_BACKLINK_V2, CREDIT_TYPE_SEO, CREDIT_TYPE_WEBSITE} from "@app-vue/stores/useBilling";

const props = defineProps({
  creditType: {default:null},
});

const useBalance = useUserBalance();
useBalance.download();

const list = computed(() => {
  if(props.creditType === CREDIT_TYPE_SEO){
    return useBalance.seoCreditBalances;
  }else if(props.creditType === CREDIT_TYPE_BACKLINK){
    return useBalance.backlinkBalances;
  }else if(props.creditType === CREDIT_TYPE_BACKLINK_V2){
    return useBalance.backlinkV2Balances;
  }else if(props.creditType === CREDIT_TYPE_WEBSITE){
    return useBalance.websiteCreditBalances;
  }else{
    return useBalance.items;
  }
});

const mainClass = computed(() => {
  if(props.creditType === CREDIT_TYPE_SEO){
    return 'bcb--credit-type-seo';
  }else if(props.creditType === CREDIT_TYPE_BACKLINK || props.creditType === CREDIT_TYPE_BACKLINK_V2){
    return 'bcb--credit-type-backlink';
  }else if(props.creditType === CREDIT_TYPE_WEBSITE){
    return 'bcb--credit-type-website';
  }else{
    return 'bcb--all';
  }
});

</script>

<style lang="scss" scoped>
.bc-balance.bcb{
  @include flex(row,start,center,10px);
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  background: #FFFFFF80;

  @media(max-width:767px){
    &:not(.bcb--credit-type-seo){
      @include flex(column,start,start,10px);
    }
  }

  .bcb__left{
    flex-shrink:0;
  }
  .bcb__right{
    @include flex(row,start,center,10px);
    flex-wrap:wrap;
  }

  .bcb__title{
    font-size: 14px;
    font-weight: 600;
    color:#596C8F;
  }

}
</style>