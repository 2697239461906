<template>
  <div class="v-form-textarea-inline vfti" @click="setFocus" :class="mainClass" ref="refWrap">
    <div class="vfti__placeholder-wrap">
      <div class="vfti__placeholder">
        {{placeholder}} <span class="vfti__placeholder-required" v-if="required">*</span>
      </div>
    </div>
    <div class="vfti__textarea">
      <textarea type="text" v-model="model" ref="refInput" rows="5"/>
    </div>

  </div>
</template>

<script setup>

import {computed, onMounted, ref} from 'vue';

const props = defineProps({
  placeholder: {},
  required: {type:Boolean, default:false},
  isError: {type: Boolean,default:false}
});

onMounted(() => {
  /**
   * Костыль
   * вводим в textarea значение
   * переходим на другую страницу
   * делаем возврат назад на страницу с textarea
   * в поле находятся данные, которые были введены в прошлый раз, несмотря на значение в model.value
   */
  setTimeout(() => {
    refInput.value.value = model.value;
  },10);
})

const refWrap = ref();
const refInput = ref();

const model = defineModel();

const mainClass = computed(() => {
  let classes = [];
  if(isOpen.value){
    classes.push('vfti--is-open')
  }
  if(props.isError){
    classes.push('vfti--is-error')
  }
  return classes;
});

const isFocus = ref(false);
const isOpen = computed(() => isFocus.value || model.value?.length > 0);

onMounted(() => {
  refInput.value.addEventListener('focus', () => {isFocus.value = true;});
  refInput.value.addEventListener('blur', () => {setTimeout(() => {isFocus.value = false;},60)});
});

const setFocus = () => {
  if(!isFocus.value){
    refInput.value.focus();
  }
}

defineExpose({isFocus,isOpen});

</script>

<style scoped lang="scss">
.v-form-textarea-inline.vfti{

  width:100%;
  border: 1px solid #D6E3EF;
  border-radius:4px;
  display:flex;
  flex-direction: column;
  padding:6px 10px;
  cursor: text;

  .vfti__placeholder-wrap{
    position:relative;
    height:18px;
    z-index:2;
    .vfti__placeholder{
      padding-top:8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #AEB7C7;
      transition-duration: 0.2s;
      transform-origin: left center;
      display:flex;
      gap:2px;
      .vfti__placeholder-required{
        color: #F2001D;
      }
    }
  }

  .vfti__textarea{
    position: relative;
    z-index:1;
    textarea {
      border:none;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #384967;
      padding:0px;
      width:100%;
      min-height:94px;
      &:focus{
        outline:0px;
      }
    }
  }

  &.vfti--is-open{
    .vfti__placeholder{
      transform:scale(0.85);
      padding-top:0px;
    }
  }

  &.vfti--is-error {
    border-color:#F2001D;
  }

}
</style>