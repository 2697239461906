import {ref} from 'vue';

export default {
    install(app) {

        const hash = ref(window.location.hash.substring(1));

        const updateHash = () => {
            hash.value = window.location.hash.substring(1);
        };

        window.addEventListener('hashchange', updateHash);

        app.config.globalProperties.$locationHash = hash;
    }
};