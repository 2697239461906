<template>
  <textarea class="v-form-textarea" :class="{'is-none-resize': noneResize}" v-model="model"/>
</template>

<script setup>

const props = defineProps({
  noneResize:{type:Boolean,default:false,}
});

const model = defineModel();

</script>

<style lang="scss" scoped>
.v-form-textarea{
  border-color:#D6E3EF;
  padding:12px;
  border-radius: 4px;
  &:focus{
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
</style>