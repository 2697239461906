<template>
  <div class="lb-order-review">

    <div class="review-stars">
      <v-review-stars
          @choose-rating="chooseRating"
          :value="currentRating"
          ref="refReviewStars"
          :no-select="isMobile"
          @click="isMobile ? refTooltipReview.show() : ''"
          :color="colorStars"
      />
      <v-link v-if="orderState.hasReviewComment && hasEdit" class="review-stars__edit" @click.prevent="refTooltipReview.show()">
        <v-icon-svg name="chat"/>
        <span class="review-stars__edit-text">Edit</span>
      </v-link>
    </div>

    <div v-if="canLeaveReportProblem"
         ref="refReport"
         class="review-report"
         :class="{'is-reported':orderState.has_report_problem,}">
      <v-link v-if="!orderState.has_report_problem" text="Report problem" @click="refTooltipReport.show()"/>
      <span v-else v-text="'Problem reported'" @click="refTooltipReport.show()"/>
    </div>

    <v-tooltip placement="bottom"
               ref="refTooltipReview"
               :style="{width:'100%', maxWidth:'400px', borderRadius:'8px',padding:'24px'}"
               :btn-close-on="true"
               :get-linked-block="() => refReviewStars.$el">
      <template v-slot:content>
        <lb-order-review-leave
            ref="refOrderReviewLeave"
            :project-uniquekey="projectUniquekey"
            :order-id="orderState.id"
            @review-leave-success="refTooltipReview.hide()"
        />
      </template>
    </v-tooltip>

    <v-tooltip placement="bottom-end"
               v-if="canLeaveReportProblem"
               ref="refTooltipReport"
               reset-tooltip
               follow-linked-block
               :get-linked-block="() => refReport">
      <template v-slot:content>
        <lb-order-report-problem :project-uniquekey="projectUniquekey" :order-id="orderId"/>
      </template>
    </v-tooltip>

  </div>
</template>

<script setup>

import VReviewStars from "@app-vue/components/Base/VReviewStars.vue";
import {useLinkBuildOrder} from "@app-vue/stores/useLinkBuilding";
import {computed, getCurrentInstance, ref, watch} from "vue";
import VTooltip from "@app-vue/components/Base/VTooltip.vue";
import LbOrderReviewLeave from "@app-vue/components/LinkBuilding/LbOrderReviewLeave.vue";
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import VLink from "@app-vue/components/Base/VLink.vue";
import LbOrderReportProblem from "@app-vue/components/LinkBuilding/LbOrderReportProblem.vue";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const props = defineProps({
  projectUniquekey: {},
  orderId: {},
  colorStars: {default: 'default',},
  hasEdit: {default: true},
  canLeaveReportProblem: {type:Boolean,default:false,}
});

const refReviewStars = ref();
const refTooltipReview = ref();
const refTooltipReport = ref();
const refOrderReviewLeave = ref();
const refReport = ref();

const orderState = useLinkBuildOrder(props.projectUniquekey, props.orderId);

/**
 * Рейтинг, который уже изменили, но еще не сохранили
 */
const currentRating = ref(orderState.reviewScore);

/**
 * После изменение рейтинга в store, сохранение в локальные изменения
 */
watch(() => orderState.reviewScore, (score) => {
  currentRating.value = score;
});

/**
 * Пользователь выбрал рейтинг
 */
const chooseRating = (rating) => {
  if(isMobile.value){
    return;
  }

  refOrderReviewLeave.value.preSetRating(rating);
  refTooltipReview.value.show();

}

</script>

<style lang="scss" scoped>
.lb-order-review{
  @include flex(row,start,center,8px);
  .review-stars{
    @include flex(row,start,center,12px);
    height:18px;
  }
  .review-stars__edit{
    @include flex(row,start,center,6px);
  }
  .review-stars__edit-text{
    @include flex(row,start,center);
    height:15px;
    font-size: 12px;
    line-height:14.52px;
  }
  .review-report{
    font:$font-mini-12-regu;
    color:$dark-40;
    span{
      cursor:pointer;
    }
    &.is-reported{
      @include flex(column,center,start,1px);
      span{
        height:14px;
      }
      &:after{
        content:'';
        display:block;
        border-bottom:1px dashed $dark-40;
        width:100%;
      }
    }
  }
}
</style>