<template>
  <div class="sc-project-keywords">

    <table v-if="!isMobile"
           v-show="useKeywords.isDownloaded && useKeywords.countShowedIds > 0"
           class="table-keywords"
           :class="{'is-checked': useKeywords._checkboxTriState>0}">
      <thead class="table-header">
        <tr>
          <th class="table-header__checkbox-col">
            <v-form-checkbox-tri-state :value="useKeywords._checkboxTriState" @change-request="useKeywords.tristateUpdate"/>
          </th>
          <template v-if="useKeywords._checkboxTriState===0">
            <th>Keyword</th>
            <th>Recommendations</th>
            <th></th>
            <th>
              Daily limit
              <div v-if="useProject.is_demo">(50 for a Demo)</div>
            </th>
            <th></th>
          </template>
          <template v-else>
            <th colspan="5">

              <div class="table-filter">
                <div class="table-filter__selected">
                  <v-form-checkbox-tri-state :value="useKeywords._checkboxTriState" @change-request="useKeywords.tristateUpdate" class="table-filter__selected-checkbox"/>
                  Selected: <span>{{(useKeywords.countChecked === useKeywords.countShowedIds) ? 'All' : useKeywords.countChecked}}</span>
                </div>
                <div class="table-filter__form">
                  <v-link @click.prevent="pauseAll">Pause</v-link>
                  <v-link @click.prevent="playAll">Resume</v-link>
                  <v-link @click.prevent="deleteModalConfirm.confirm()">Delete</v-link>
                  <div class="table-filter__change-daily-limit">
                    <span class="desktop-label">Change daily limit</span>
                    <v-form-input-number-edit
                        :value="eachDailyLimit"
                        type="events"
                        :filter-data="(val) => {return val >= 0;}"
                        ref="inputDailyLimit"
                        @save="changeDailyLimit"
                    />
                  </div>
                </div>
              </div>

            </th>
          </template>
        </tr>
      </thead>
      <colgroup class="table-colgroup">
        <col v-for="i in [1,2,3,4,5]" :key="i">
      </colgroup>
      <tbody class="table-keywords__body">
        <template v-for="group in useKeywordsGroups.listFiltered" :key="group.ids.sort().join('-')">
          <sc-project-keyword-group-desktop :project-id="projectId"
                                            v-show="group.is_show"
                                            :keywords-ids="group.ids"/>
        </template>
      </tbody>
    </table>

    <div v-if="isMobile"
         v-show="useKeywords.isDownloaded && useKeywords.countShowedIds > 0"
         class="list-keywords" >
      <template v-for="group in useKeywordsGroups.listFiltered" :key="group.ids.sort().join('-')">
        <sc-project-keyword-group-mobile
            class="list-keywords__item"
            v-show="group.is_show"
            :keywords-ids="group.ids"
            :project-id="projectId"
        />
      </template>
    </div>

    <div class="sc-loading-section" v-if="!useKeywords.isDownloaded">
      <div class="sc-loading-section__inner">
        <div class="sc-loading-section__icon">
          <v-loader-ship color-line-one="#1C7EE080" color-line-two="#1C7EE0"/>
        </div>
        <div class="sc-loading-section__text" v-text="'Keywords loading ....'"></div>
      </div>
    </div>

    <div class="section-not-found"
         v-if="useKeywords.isDownloaded && useKeywords.countShowedIds === 0"
         v-text="'Nothing find on your request'"/>

    <v-modal-confirm ref="deleteModalConfirm" @confirm="deleteAll">
      <template v-slot:title>
        Delete keywords
      </template>
      <template v-slot:description>
        Do you really want to delete delete the selected keywords ? <br>The selected keywords will be lost
      </template>
    </v-modal-confirm>
  </div>

</template>

<script setup>
import {computed, getCurrentInstance, ref, watch} from "vue";
import VFormCheckboxTriState from "@app-vue/components/VForm/VFormCheckboxTriState.vue";
import VLink from "@app-vue/components/Base/VLink.vue";
import VFormInputNumberEdit from "@app-vue/components/VForm/VFormInputNumberEdit.vue";
import {useSeoClicksKeywords, useSeoClicksKeywordsGroups, useSeoClicksProject} from "@app-vue/stores/useSeoClicks.js";
import VLoaderShip from "@app-vue/components/Base/VLoaderShip.vue";
import VModalConfirm from "@app-vue/components/Base/VModalConfirm.vue";
import ScProjectKeywordGroupDesktop from "@app-vue/components/SeoClicks/ScProjectKeywordGroupDesktop.vue";
import ScProjectKeywordGroupMobile from "@app-vue/components/SeoClicks/ScProjectKeywordGroupMobile.vue";

const props = defineProps({
  projectId:{},
});

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const useProject = useSeoClicksProject(props.projectId);
const useKeywords = useSeoClicksKeywords(props.projectId);
const useKeywordsGroups = useSeoClicksKeywordsGroups(props.projectId);

watch(() => useKeywords._listChecked, () => {
  useKeywords.updateTriStateCheckbox();
},{deep:true});

const updateKeywords = () => {
  useKeywords.download();
}

defineExpose({updateKeywords});

const pauseAllLoading = ref(false);
const pauseAll = async () => {
  if(pauseAllLoading.value){return;}
  pauseAllLoading.value = true;
  await useKeywords.updateIsActive(useKeywords.getSelectedIds(), 0);
  pauseAllLoading.value = false;
}

const playAllLoading = ref(false)
const playAll = async () => {
  if(playAllLoading.value){return;}
  playAllLoading.value = true;
  await useKeywords.updateIsActive(useKeywords.getSelectedIds(), 1);
  playAllLoading.value = false;
}

const deleteModalConfirm = ref();
const deleteAll = async () => {
  let [status,message] = await useKeywords.updateIsDeleted(useKeywords.getSelectedIds(),true);
  if(status){
    deleteModalConfirm.value.closeWithSuccess();
  }else{
    deleteModalConfirm.value.closeWithError(message);
  }
}

const inputDailyLimit = ref();
const eachDailyLimit = ref(1);
const changeDailyLimit = async (value) => {
  let [status,message] = await useKeywords.updateDailyLimit(useKeywords.getSelectedIds(), value);
  if(status){
    inputDailyLimit.value.saveWithSuccess();
  }else{
    inputDailyLimit.value.saveWithError();
  }
}

</script>

<style scoped lang="scss">
.table-keywords{
  width:100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0 2px;

  .table-header{
    tr{
      > th{
        padding-top:16px;
        padding-bottom:16px;
        border-top:1px solid #D6E3EF80;
        border-bottom:1px solid #D6E3EF80;
        color:#0000004D;
        align-content: center;
        vertical-align: middle;
        font-weight: 400;
      }

      .table-header__checkbox-col{
        padding-left:30px;
      }
    }
    
  }

  .table-colgroup{
    col:nth-child(1){width:71px;}
    col:nth-child(2){width:289px;}
    col:nth-child(3){width:210px;}
    col:nth-child(4){width:136px;}
    col:nth-child(5){width:132px;}
    col:nth-child(6){width:82px;}
  }
  .table-body{
    width:100%;
  }

  &.is-checked{
      .table-header{
        tr {
          > th {
            padding-top:8px;
            padding-bottom:8px;
            background: #F4F9FD;
            border-top-color: transparent;
            border-bottom: 1px solid #D6E3EF80
          }
        }
      }
  }

  .table-filter{
    display: flex;
    align-items: center;
    gap:33px;

    .table-filter__selected{
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      color:#0B1424;

      span{
        display: inline-block;
        min-width: 19px;
      }

      .table-filter__selected-checkbox{
        display: none;
      }
    }
    .table-filter__form{
      display: flex;
      align-items: center;
      gap:26px;

      .table-filter__change-daily-limit{
        display: flex;
        align-items: center;
        gap:10px;

        > span{
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          color:#0B1424;
        }
      }
    }
  }

}
.list-keywords{
  .list-keywords__item{
    margin-bottom:16px;
    &:last-child{
      margin-bottom:0px;
    }
  }
}
.sc-loading-section{
  background-color: $secondary-blue-5;
  padding:117px 0px;
  margin-left:30px;
  margin-right:30px;
  @media(max-width:767px){
    margin-left:0px;
    margin-right:0px;
  }

  .sc-loading-section__inner{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .sc-loading-section__icon{
      margin-bottom:10px;
    }
    .sc-loading-section__text{
      color:#596C8F;
      text-align: center;
    }
  }
}
.section-not-found{
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  display:flex;
  justify-content: center;
  align-items:center;
  height:100px;
  border-top: 1px solid #D6E3EF80;
  color: #00000080;
}
</style>