<template>
  <div class="reco-block-table">
    <div class="reco-block-table__row">
      <div class="reco-block-table__left">
        Minimum
      </div>
      <div class="reco-block-table__right" v-if="!recoStore.is_error">
        {{recoStore.min_value}} {{recoStore.value_name}}
      </div>
      <div class="reco-block-table__right" v-else>
        N/A
      </div>
    </div>
    <div class="reco-block-table__row">
      <div class="reco-block-table__left">
        Median
      </div>
      <div class="reco-block-table__right" v-if="!recoStore.is_error">
        {{recoStore.median_value}} {{recoStore.value_name}}
      </div>
      <div class="reco-block-table__right" v-else>
        N/A
      </div>
    </div>
    <div class="reco-block-table__row">
      <div class="reco-block-table__left">
        Avarage
      </div>
      <div class="reco-block-table__right" v-if="!recoStore.is_error">
        {{recoStore.avg_value}} {{recoStore.value_name}}
      </div>
      <div class="reco-block-table__right" v-else>
        N/A
      </div>
    </div>
    <div class="reco-block-table__row">
      <div class="reco-block-table__left">
        Maximum
      </div>
      <div class="reco-block-table__right" v-if="!recoStore.is_error">
        {{recoStore.max_value}} {{recoStore.value_name}}
      </div>
      <div class="reco-block-table__right" v-else>
        N/A
      </div>
    </div>
    <div class="reco-block-table__row">
      <div class="reco-block-table__left">
        You have
      </div>
      <div class="reco-block-table__right">
        <span
            v-if="recoStore.not_applicable === 0 && !recoStore.is_error"
            class="reco-block-table__result"
            :class="recoStore.currentColor"
        >
          {{recoStore.value}} {{recoStore.value_name}}
        </span>
        <span
            v-if="recoStore.not_applicable === 1"
            class="reco-block-table__result"
            :class="recoStore.currentColor"
        >
          N/A
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {useRecsStore} from "@app-vue/stores/useRecsStore";

export default {
  props: {
    recoMetric: {type:String, required:true},
    projectUniquekey: {}
  },
  computed: {
    recoStore: (state)=>{
      return state.recsStore.recoStoreByMetric(state.recoMetric);
    }
  },
  setup(props){
    return {recsStore: useRecsStore(props.projectUniquekey)};
  }
}
</script>

<style lang="scss" scoped>
.reco-block-table{
  display:block;
}
.reco-block-table__row{
  display:flex;
  justify-content:space-between;
  align-items:center;
  line-height:18px;
}
.reco-block-table__left{
  text-align:left;
  color:#00000080;
  font-size:11px;
}
.reco-block-table__right{
  text-align:right;
  font-size:12px;
}
.reco-block-table__result{
  font-weight:600;
}
.reco-block-table__result.color-super-danger{
  color:$recommendation-super-danger;
}
.reco-block-table__result.color-danger{
  color:$recommendation-danger;
}
.reco-block-table__result.color-warning{
  color:$recommendation-warning;
}
.reco-block-table__result.color-success{
  color:$recommendation-success;
}
</style>