<template>
  <div class="crypto-payment">

    <transition name="fade" mode="out-in">
      <div v-if="useCrypto.orderIsPending">

        <div class="crypto-payment__header">
          <div class="row align-items-center">
            <div class="col-auto">
              <img class="sp-logo" :src="'/img/sparktraffic-logo-square.svg'" alt="">
            </div>
            <div class="col-auto">
              <div class="crypto-payment__header-title">SparkTraffic.com</div>
              <div class="crypto-payment__header-order">Order ID: {{useCrypto.order_id}}</div>
            </div>
          </div>
        </div >

        <div class="crypto-payment__content">

          <div class="crypto-payment__info">

            <div class="row align-items-center">
              <div class="col">
                <div class="crypto-payment__info-title">Send {{useCrypto.asset_id}} over the {{useCrypto.blockchain}} network</div>
                <div class="crypto-payment__info-desc">To make a payment, open your crypto wallet and scan the QR code or copy the {{useCrypto.asset_id}} address below.</div>
              </div>
              <div class="col-auto">
                <v-skills-graph-circle :start-time="useCrypto.start_time" :timer-seconds="1800" ref="refTimer" @timer-is-over="timeIsOver"/>
              </div>
            </div>

          </div>

          <div class="crypto-payment__qr">
            <div class="generated-qr-code" id="generated_qr_code" title="TS27tCYJgxhDd2ipjfHYPFU7BmWFaPHV4W">
              <canvas width="220" height="220" ref="refCanvasQrCode"></canvas>
            </div>
            <div class="crypto-payment__qr-info">
              <div class="row g-3 align-items-center">
                <div class="col-auto">
                  <img class="icon-info" :src="'/img/icons/icon_notification-warning.svg'" alt="">
                </div>
                <div class="col">
                  <span>Only {{useCrypto.asset_id}} from the {{useCrypto.blockchain}} network can be sent to this address. Do not send {{useCrypto.asset_id}} from other networks as this will result in loss of funds.</span>
                </div>
              </div>
            </div>
          </div>

          <div class="crypto-payment__address">
            <div class="row g-3 align-items-center">
              <div class="col">
                <div class="crypto-payment__address-title">Address {{useCrypto.asset_id}} ({{useCrypto.blockchain}})</div>
                <span class="crypto-payment__address-wallet" id="sp_address_wallet">{{useCrypto.crypto_address}}</span>
              </div>
              <div class="col-auto">
                <v-button-copy :content="useCrypto.crypto_address">Copy</v-button-copy>
              </div>
            </div>
          </div>

          <div class="crypto-payment__amount">
            <div class="row g-3 align-items-center">
              <div class="col">
                <div class="crypto-payment__amount-title">Total amount</div>
                <span class="crypto-payment__amount-price" id="amount_price">{{useCrypto.totalCryptoAmountFormatted}}</span><span>&nbsp;{{useCrypto.asset_id}}</span>
              </div>
              <div class="col-auto">
                <v-button-copy :content="useCrypto.totalCryptoAmountFormatted">Copy</v-button-copy>
              </div>
            </div>
          </div>

        </div>

        <div class="row justify-content-end">
          <div class="col-auto">
            <v-button color="red" @click="cancelPayment" :loading="cancelPaymentLoading">Cancel payment</v-button>
          </div>
        </div>

      </div>
    </transition>

    <transition name="fade" mode="out-in">
      <div v-if="useCrypto.orderIsTimeIsOver">

        <div class="crypto-payment__error">
          <div class="row justify-content-center align-items-center">
            <div class="col-12 text-center">
              <div class="crypto-payment__error-icon">
                <img :src="'/img/icons/icon_notification-error.svg'" alt="">
              </div>
            </div>
            <div class="col-12 text-center">
              <div class="crypto-payment__error-title">
                No payment
              </div>
            </div>
            <div class="col-12 text-center">
              <div class="crypto-payment__error-info">
                We are constantly monitoring the network, but have not detected the payment. If you have already submitted payment, please contact us at support@sparktraffic.com.
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-end">
          <div class="col-auto">
            <v-button @click="repeatPayment">Repeat payment</v-button>
          </div>
        </div>

      </div>
    </transition>

    <transition name="fade" mode="out-in">

      <div v-if="useCrypto.orderIsPaid">
        <div class="crypto-payment__success" style="">
          <div class="row justify-content-center align-items-center">
            <div class="col-12 text-center">
              <div class="crypto-payment__success-icon">
                <img :src="'/img/icons/icon_notification-success.svg'" alt="">
              </div>
            </div>
            <div class="col-12 text-center">
              <div class="crypto-payment__success-title">
                Payment has been successful
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-auto">
            <v-button @click="repeatPayment">Repeat payment</v-button>
          </div>
        </div>
      </div>

    </transition>

  </div>
</template>

<script setup>

import QRCode from "qrcode";
import {onMounted, onUnmounted, ref, watch} from "vue";
import VSkillsGraphCircle from "@app-vue/components/Base/VSkillsGraphCircle.vue";
import {usePayByCrypto} from "@app-vue/stores/usePayByCrypto";
import VButton from "@app-vue/components/Base/VButton.vue";
import VButtonCopy from "@app-vue/components/Base/VButtonCopy.vue";
import {useBillingCart} from "@app-vue/stores/useBilling";

const props = defineProps({
  cartType: {},
  projectUniquekey: {default:null,},
});

const useCrypto = usePayByCrypto(props.projectUniquekey);

const refCanvasQrCode = ref();
const refTimer = ref();

const drawQr = (cryptoAddress) => {
  if(refCanvasQrCode.value && cryptoAddress){
    QRCode.toCanvas(
        refCanvasQrCode.value,
        cryptoAddress,
        {
          width:220,
          errorCorrectionLevel:'H',
          margin:0,
        }
    );
  }
}

onMounted(() => {drawQr(useCrypto.crypto_address);});
watch(() => useCrypto.crypto_address,(cryptoAddress) => {drawQr(cryptoAddress);});

const changePackCancelPayment = () => {
  useCrypto.closeWindow();
  useCrypto.cancel().then(() => {
    useCrypto.clearOrder();
  });
}

watch(() => {
  switch(props.cartType){
    case 'main' : return useBillingCart().totalPrice;
    //case 'link-building' : return useLinkBuildCart(props.projectUniquekey).finalAmount;
  }
}, () => {
  changePackCancelPayment();
});


const checkTimer = setInterval(() => {
  if(useCrypto.orderIsPending){
    useCrypto.check();
  }
},2000);

onUnmounted(() => {
  clearInterval(checkTimer);
})

const cancelPaymentLoading = ref(false);
const cancelPayment = () => {
  cancelPaymentLoading.value = true;
  useCrypto.cancel().then(([status,data]) => {
    cancelPaymentLoading.value = false;
    if(status){
      useCrypto.closeWindow();
      useCrypto.clearOrder();
    }
  });
}

const repeatPayment = () => {
  useCrypto.clearOrder();
  useCrypto.closeWindow();
}

const timeIsOver = () => {
  useCrypto.timeIsOver();
}



</script>

<style scoped lang="scss">
.crypto-payment {
  .crypto-payment__header {
    @include flex(row,start,center,0px);
    width: 100%;
    min-height: 80px;
    margin-bottom: 16px;
    padding: 24px;
    border: 1px solid #5b616e33;
    border-radius: 4px;

    .sp-logo {
      width: 80px;
      height: 80px;
      @media(max-width:767px){
        margin-bottom:8px;
      }
    }

    .crypto-payment__header-title {
      font-size: 16px;
      font-weight: 600;
      color: $black-1;
      margin-bottom: 8px;
    }

    .crypto-payment__header-order {
      font-size: 16px;
      font-weight: 400;
      color: #5b616e;
    }
  }

  .crypto-payment__content {
    width: 100%;
    margin-bottom: 24px;
    border: 1px solid #5b616e33;
    border-radius: 4px;
  }

  .crypto-payment__info {
    padding: 24px;
    border-bottom: 1px solid #5b616e33;

    .crypto-payment__info-title {
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: 600;
      color: $black-1;
    }

    .crypto-payment__info-desc {
      font-size: 14px;
      font-weight: 400;
      color: #5b616e;
    }
  }

  .crypto-payment__qr {
    padding: 24px;
    text-align: center;
    border-bottom: 1px solid #5b616e33;

    .generated-qr-code {
      width: 220px;
      height: 220px;
      margin: 0 auto 16px auto;
      text-align: center;
    }

    .crypto-payment__qr-info {
      padding: 24px;
      font-size: 14px;
      font-weight: 400;
      color: #5b616e;
      text-align: left;
      background-color: #f5f8ff;

      .icon-info {
        width: 22px;
        height: 22px;
      }
    }
  }

  .crypto-payment__address {
    padding: 24px;
    border-bottom: 1px solid #5b616e33;

    .crypto-payment__address-title {
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 400;
      color: #5b616e;
      text-align: left;
    }

    .crypto-payment__address-wallet {
      font-size: 16px;
      font-weight: 600;
      color: $black-1;
      text-align: left;
      overflow-wrap:break-word;
    }
  }

  .crypto-payment__amount {
    padding: 24px;

    .crypto-payment__amount-title {
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 400;
      color: #5b616e;
      text-align: left;
    }

    .crypto-payment__amount-price {
      font-size: 16px;
      font-weight: 600;
      color: $black-1;
      text-align: left;
    }
  }

  .crypto-payment__error {
    width: 100%;
    margin-bottom: 24px;
    padding: 24px;
    border: 1px solid #5b616e33;
    border-radius: 4px;

    .crypto-payment__error-icon {
      width: 96px;
      height: 96px;
      margin: 0 auto 24px auto;

      img {
        width: 96px;
        height: 96px;
      }
    }

    .crypto-payment__error-title {
      margin-bottom: 24px;
      font-size: 22px;
      font-weight: 600;
      color: $black-1;
    }

    .crypto-payment__error-info {
      font-size: 16px;
      font-weight: 400;
      color: $black-1;
    }
  }

  .crypto-payment__success {
    width: 100%;
    margin-bottom: 24px;
    padding: 24px;
    border: 1px solid #5b616e33;
    border-radius: 4px;

    .crypto-payment__success-icon {
      width: 96px;
      height: 96px;
      margin: 0 auto 24px auto;

      img {
        width: 96px;
        height: 96px;
      }
    }

    .crypto-payment__success-title {
      margin-bottom: 24px;
      font-size: 22px;
      font-weight: 600;
      color: $black-1;
    }

    .crypto-payment__success-info {
      font-size: 16px;
      font-weight: 400;
      color: $black-1;
    }
  }
}
.fade-enter-from{
  opacity:0;
}
.fade-enter-active{
  transition: opacity 0.5s;
}
.fade-enter-to{
  opacity:1;
}
.fade-leave-from{
  display:none;
}
.fade-leave-active{
  display:none;
}
.fade-leave-to{
  display:none;
}

</style>