<template>

  <div class="row g-0">
      <div class="checkout-block col-12" style="display: block;">
        <div class="checkout-block-payment" data-payment-method="cg">
          <div class="commerce-gate-checkout top-form" data-amount-limit="5000">
            <div class="form-payment-card-wrap">
              <div class="form-payment-card">
                <form data-form-id="form-payment-card">
                  <div class="form-payment-card__amount">
                    Pay by card: <span data-js-amount>${{priceFormatted(useCart.finalAmount)}}</span>&nbsp;<span data-js-currency>USD</span>, <span data-js-vat>{{useBillingCart().vatRate}}%</span> VAT
                  </div>

                  <div class="user-saved-cards" :style="!useBillingCart().hasCards ? 'display: none;' : ''">
                    <div class="form-group">
                      <label class="form-label" for="card_for_payment">Choose a saved card for the payment</label>
                      <select class="form-control custom-select" id="card_for_payment" name="card_for_payment">
                        <option value="0" :selected="!useBillingCart().lastUsedCard">Pay with a new card</option>
                        <option v-for="saveCard in useBillingCart().saveCards" name="card_for_payment"
                                :data-content="'<img src=\'/img/payment-badges/' + saveCard.cardName + '.svg\' style=\'width: auto;height: 22px;margin: 0;\' alt=\'\'><span style=\'display: inline-block;line-height: 22px;\'>&nbsp;&nbsp;****&nbsp;****&nbsp;****&nbsp;' + saveCard.cardLastFourDigits + '</span>'"
                                :value="saveCard.cardId" :selected="saveCard && useBillingCart().lastUsedCard.cardId === saveCard.cardId">{{ '**** **** **** ' + saveCard.cardLastFourDigits }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-payment-card__form" :style="useBillingCart().hasCards ? 'display: none;' : ''">
                    <div class="row g-16 h-100">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="form__number form__detail">
                            <div class="icon">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><rect x="48" y="96" width="416" height="320" rx="56" ry="56" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" stroke="currentColor" fill="none"></rect><path stroke-linejoin="round" stroke-width="60" d="M48 192h416M128 300h48v20h-48z" fill="none"></path></svg>
                            </div>
                            <input type="text" class="form-control" id="card_number" name="card_number" onkeypress="return !(event.keyCode < 48 || event.keyCode > 57) && (event.keyCode > 31)" value="" autocomplete="cc-number">
                            <div class="alert-block" id="alert-2">
                              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3163 9.00362C11.8593 10.0175 11.1335 11.25 9.99343 11.25H2.00657C0.866539 11.25 0.140732 10.0175 0.683711 9.00362L4.67714 1.54691C5.24618 0.484362 6.75381 0.484362 7.32286 1.54691L11.3163 9.00362ZM5.06238 4.49805C5.02858 3.95721 5.4581 3.5 6 3.5C6.5419 3.5 6.97142 3.95721 6.93762 4.49805L6.79678 6.75146C6.77049 7.17221 6.42157 7.5 6 7.5C5.57843 7.5 5.22951 7.17221 5.20322 6.75146L5.06238 4.49805ZM6 8C5.44772 8 5 8.44772 5 9C5 9.55229 5.44772 10 6 10C6.55228 10 7 9.55229 7 9C7 8.44772 6.55228 8 6 8Z" fill="#FF6A96"/>
                              </svg>
                              Invalid card number</div>
                            <label class="form-label" for="card_number">Card Number</label>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <div class="form__expiry form__detail">
                                <div class="icon">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><rect x="48" y="80" width="416" height="384" rx="48" ry="48" stroke-linejoin="round" stroke-width="32" stroke="currentColor" fill="none"></rect><path d="M397.82 80H114.18C77.69 80 48 110.15 48 147.2V208h16c0-16 16-32 32-32h320c16 0 32 16 32 32h16v-60.8c0-37.05-29.69-67.2-66.18-67.2z" stroke="currentColor" fill="currentColor"></path><circle cx="296" cy="232" r="24" fill="currentColor"></circle><circle cx="376" cy="232" r="24" fill="currentColor"></circle><circle cx="296" cy="312" r="24" fill="currentColor"></circle><circle cx="376" cy="312" r="24" fill="currentColor"></circle><circle cx="136" cy="312" r="24" fill="currentColor"></circle><circle cx="216" cy="312" r="24" fill="currentColor"></circle><circle cx="136" cy="392" r="24" fill="currentColor"></circle><circle cx="216" cy="392" r="24" fill="currentColor"></circle><circle cx="296" cy="392" r="24" fill="currentColor"></circle><path stroke-linecap="round" stroke-linejoin="round" d="M128 48v32M384 48v32" stroke-width="32" stroke="currentColor" fill="none"></path></svg>
                                </div>
                                <input type="text" class="form-control" id="card_expiration" name="card_expiration" placeholder="MM/YY" onkeypress="return !(event.keyCode < 48 || event.keyCode > 57) && (event.keyCode > 31)" value="" autocomplete="cc-exp">
                                <div class="alert-block" id="alert-3">
                                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3163 9.00362C11.8593 10.0175 11.1335 11.25 9.99343 11.25H2.00657C0.866539 11.25 0.140732 10.0175 0.683711 9.00362L4.67714 1.54691C5.24618 0.484362 6.75381 0.484362 7.32286 1.54691L11.3163 9.00362ZM5.06238 4.49805C5.02858 3.95721 5.4581 3.5 6 3.5C6.5419 3.5 6.97142 3.95721 6.93762 4.49805L6.79678 6.75146C6.77049 7.17221 6.42157 7.5 6 7.5C5.57843 7.5 5.22951 7.17221 5.20322 6.75146L5.06238 4.49805ZM6 8C5.44772 8 5 8.44772 5 9C5 9.55229 5.44772 10 6 10C6.55228 10 7 9.55229 7 9C7 8.44772 6.55228 8 6 8Z" fill="#FF6A96"/>
                                  </svg>
                                  Invalid date</div>
                                <label class="form-label" for="card_expiration">Experation Date</label>
                              </div>
                              <input type="hidden" id="card_expiration_m" name="card_expiration_m" value="">
                              <input type="hidden" id="card_expiration_y" name="card_expiration_y" value="">
                            </div>
                          </div>

                          <div class="col-4">
                            <div class="form-group">
                              <div class="form__cvv form__detail">
                                <div class="icon">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M336 208v-95a80 80 0 00-160 0v95" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" stroke="currentColor" fill="none"></path><rect x="96" y="208" width="320" height="272" rx="48" ry="48" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" stroke="currentColor" fill="none"></rect></svg>
                                </div>
                                <input type="password" class="form-control" name="card_cvv" id="card_cvv" placeholder="000" maxlength="4" onkeypress="return !(event.keyCode < 48 || event.keyCode > 57) && (event.keyCode > 31)" value="" autocomplete="cc-csc">
                                <div class="alert-block" id="alert-4">
                                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3163 9.00362C11.8593 10.0175 11.1335 11.25 9.99343 11.25H2.00657C0.866539 11.25 0.140732 10.0175 0.683711 9.00362L4.67714 1.54691C5.24618 0.484362 6.75381 0.484362 7.32286 1.54691L11.3163 9.00362ZM5.06238 4.49805C5.02858 3.95721 5.4581 3.5 6 3.5C6.5419 3.5 6.97142 3.95721 6.93762 4.49805L6.79678 6.75146C6.77049 7.17221 6.42157 7.5 6 7.5C5.57843 7.5 5.22951 7.17221 5.20322 6.75146L5.06238 4.49805ZM6 8C5.44772 8 5 8.44772 5 9C5 9.55229 5.44772 10 6 10C6.55228 10 7 9.55229 7 9C7 8.44772 6.55228 8 6 8Z" fill="#FF6A96"/>
                                  </svg>
                                  Invalid CVV</div>
                                <label class="form-label" for="card_cvv">CVV</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="form-group">
                          <div class="form__name form__detail">
                            <div class="icon">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" stroke="currentColor" fill="none"></path><path d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z" stroke-miterlimit="10" stroke-width="32" stroke="currentColor" fill="none"></path></svg>
                            </div>
                            <input type="text" class="form-control" id="card_name" name="card_name" placeholder="John Doe" maxlength="24" value="" autocomplete="cc-name">
                            <div class="alert-block" id="alert-1">
                              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3163 9.00362C11.8593 10.0175 11.1335 11.25 9.99343 11.25H2.00657C0.866539 11.25 0.140732 10.0175 0.683711 9.00362L4.67714 1.54691C5.24618 0.484362 6.75381 0.484362 7.32286 1.54691L11.3163 9.00362ZM5.06238 4.49805C5.02858 3.95721 5.4581 3.5 6 3.5C6.5419 3.5 6.97142 3.95721 6.93762 4.49805L6.79678 6.75146C6.77049 7.17221 6.42157 7.5 6 7.5C5.57843 7.5 5.22951 7.17221 5.20322 6.75146L5.06238 4.49805ZM6 8C5.44772 8 5 8.44772 5 9C5 9.55229 5.44772 10 6 10C6.55228 10 7 9.55229 7 9C7 8.44772 6.55228 8 6 8Z" fill="#FF6A96"/>
                              </svg>
                              Invalid Name (eg: John Doe)</div>
                            <label class="form-label" for="card_name">Name on Card</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-payment-card__button">
                    <div class="form-payment-card__error"></div>
                    <button class="button button-main button_bg_green button_size_middle w-100" type="submit" data-ajax-action="pay-now">Pay Now</button>
                  </div>

                  <input type="hidden" name="card_id" :value="useBillingCart().lastUsedCard ? useBillingCart().lastUsedCard.cardId : ''">
                </form>
              </div>

              <div class="loading-block">
                <div class="lottie-animation"></div>
              </div>
            </div>

            <iframe class="iframe-3d-verification" frameborder="0" scrolling="no" sandbox="allow-forms allow-same-origin allow-pointer-lock allow-scripts" allow="payment *; publickey-credentials-get *"></iframe>
          </div>

          <div class="checkout-block-info" data-payment-method="cg">
            <div class="checkout-block__error">
              <div class="row justify-content-center align-items-center">
                <div class="col-12 text-center">
                  <div class="checkout-block__error-icon">
                    <v-icon-svg class="icon" name="icon_notification-error" as-img/>
                  </div>
                </div>
                <div class="col-12 text-center">
                  <div class="checkout-block__error-title">
                    Payment has been declined
                  </div>
                </div>
              </div>

              <button class="button button-main"
                      type="button"
                      data-button-action="repeat"
              >Repeat payment</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  <transition name="fade" mode="out-in">
    <div v-if="useCard.orderIsPaid">
      <div class="form-payment__success" style="">
        <div class="row justify-content-center align-items-center">
          <div class="col-12 text-center">
            <div class="form-payment__success-icon">
              <img :src="'/img/icons/icon_notification-success.svg'" alt="">
            </div>
          </div>
          <div class="col-12 text-center">
            <div class="form-payment__success-title">
              Payment has been successful
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>

</template>

<script setup>
import {onMounted, onUnmounted, ref, watch} from 'vue';
import {useUserInfo} from '@app-vue/stores/useStaticData.js';
import {useBillingCart} from "@app-vue/stores/useBilling";
import {priceFormatted} from '../../../utils/utils.js';
import {useLinkBuildCart} from '@app-vue/stores/useLinkBuilding.js';
import VIconSvg from '@app-vue/components/Base/VIconSvg.vue';
import {usePayByCard} from '@app-vue/stores/usePayByCard.js';
import {initCartScripts} from "@app-frontend/panel/cart/index.js";
import {initCartPaymentMethodCommerceGatePaymentsAndUnlimitScripts} from '@app-frontend/panel/cart/payment_methods/commerce-gate-payments-and-unlimit.js';
import {initBootstrapSelect} from '@app-frontend/panel/common.js';

const props = defineProps({
  projectUniquekey: {},
});

const userInfo = useUserInfo();
const useCart = useLinkBuildCart(props.projectUniquekey);
const useCard = usePayByCard(props.projectUniquekey);

watch(() => useBillingCart().totalPrice, () => {
  const form = document.getElementById('payment-form');
  form.hidden = true;
});

userInfo.download();

const isOnMounted = ref(false);
onMounted(() => {
  initBootstrapSelect();
  initCartScripts();
  initCartPaymentMethodCommerceGatePaymentsAndUnlimitScripts();

  isOnMounted.value = true;
});

const checkTimer = setInterval(() => {
  if(useCard.orderIsPending){
    useCard.check();
  }
},2000);

onUnmounted(() => {
  clearInterval(checkTimer);
})
</script>

<style scoped lang="scss">
.form-payment__error {
  width: 100%;
  margin-bottom: 24px;
  padding: 24px;
  border: 1px solid #5b616e33;
  border-radius: 4px;

  .form-payment__error-icon {
    width: 96px;
    height: 96px;
    margin: 0 auto 24px auto;

    img {
      width: 96px;
      height: 96px;
    }
  }

  .form-payment__error-title {
    margin-bottom: 24px;
    font-size: 22px;
    font-weight: 600;
    color: $black-1;
  }

  .form-payment__error-info {
    font-size: 16px;
    font-weight: 400;
    color: $black-1;
  }
}

.form-payment__success {
  width: 100%;
  margin-bottom: 24px;
  padding: 24px;
  border: 1px solid #5b616e33;
  border-radius: 4px;

  .form-payment__success-icon {
    width: 96px;
    height: 96px;
    margin: 0 auto 24px auto;

    img {
      width: 96px;
      height: 96px;
    }
  }

  .form-payment__success-title {
    margin-bottom: 24px;
    font-size: 22px;
    font-weight: 600;
    color: $black-1;
  }

  .form-payment__success-info {
    font-size: 16px;
    font-weight: 400;
    color: $black-1;
  }
}
</style>