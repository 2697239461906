import {defineStore} from "pinia";
import {ApiResource} from "@app-vue/plugins/ApiExtends";
import {useLinkBuildCart, useLinkBuildProject, useLinkBuildProjectOrders} from "@app-vue/stores/useLinkBuilding";
import {useUserBalance} from "@app-vue/stores/useStaticData";

export const usePayByCard = (projectUniquekey = null) => defineStore(`pay-by-card-${projectUniquekey}`, {

    state: () => {
        return {
            projectUniquekey: projectUniquekey,
            windowActive: false,
            currency: 'USD',
            order_id: null,
            amount: null,
            start_time: null,
            status: null,
        }
    },

    getters: {

        totalAmountFormatted: (state) => {
            return parseFloat(state.amount).toFixed(2);
        },

        hasOrder: (state) => {
            return state.order_id !== null;
        },

        orderIsPaid: (state) => {
            return state.status === 'paid';
        },

        orderIsPending: (state) => {
            return state.status === 'pending' || state.status === null;
        },

        orderIsCanceled: (state) => {
            return state.status === 'canceled';
        },

        orderIsTimeIsOver: (state) => {
            return state.status === 'time_is_over';
        },

    },

    actions: {

        /**
         * Открыть окно оплаты
         */
        openWindow(){
            this.$patch({windowActive: true});
        },

        /**
         * Закрыть окно оплаты
         */
        closeWindow(){
            this.$patch({windowActive: false});
        },

        async timeIsOver(){
          this.$patch({status:'time_is_over'});
        },

        /**
         * Проверяем, существует ли заказ, если он есть, то обновить данные ордера
         */
        async check() {
            let resource = new ApiResource({
                url: '/api/v1/orders/check-status-backlinks-order',
                method: 'GET',
                params: {
                    'backlinkProjectId': useLinkBuildProject(this.projectUniquekey).id,
                },
            });

            await resource.downloadAsync();
            if (resource.isSuccess()) {
                this.$patch({
                    order_id: resource.data.order_id,
                    start_time: new Date(resource.data.created_at),
                    status: resource.data.status,
                    amount: resource.data.amount,
                });

                if (this.projectUniquekey) {
                    if (resource.data.status === 'paid') {
                        Promise.all([
                            useUserBalance().download(),
                            useLinkBuildProjectOrders(this.projectUniquekey).download(),
                            useLinkBuildCart(this.projectUniquekey).update(),
                        ]).then(() => {
                            this.clearOrder();
                            this.closeWindow();
                            useLinkBuildProject(this.projectUniquekey).orderCreationStatusSetStart();
                        });
                    }
                }

                return [true];
            } else {
                return [false, resource.errorMessage];
            }
        },

        /**
         * Очищаем state ордера
         */
        clearOrder(exceptions = []){

            let resetData = {
                'order_id': null,
                'amount': null,
                'start_time':null,
                'status':null,
            };
            let cols = Object.keys(resetData);

            let colsDiff = cols.filter(item => !exceptions.includes(item));

            this.$patch((state) => {
                colsDiff.forEach(colName => {
                    state[colName] = resetData[colName];
                })
            });
        },

    }

})();