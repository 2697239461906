<template>
  <v-project-preview class="lb-project-preview"
                     type="link-building"
                     :is-demo="useProject.is_demo"
                     :modal-blackout="modalBlackout">

    <template #default>
      <div class="content">

        <div class="info content__info" :class="{'info--short-gap':useProject.is_deleted, 'content__info--full-width':useProject.is_deleted}">

          <div class="info__top">

            <div class="info__id" :class="modalBlackout ? 'info__id--hide' : ''">
              {{useProject.uniquekey}}
              <span v-if="useProject.createdAtDate">Created {{formatDateDDMMYYYY(useProject.createdAtDate)}}</span>
              <div class="info__tag-deleted" v-if="useProject.is_deleted" v-text="'DELETED'"/>
            </div>

            <v-link :href="useProject.link"
                    class="info__title"
                    :class="useProject.is_deleted ? 'opacity-50' : ''"
                    :text="useProject.name"/>

            <div class="info__category"
                 v-if="useProject.category?.name"
                 :class="useProject.is_deleted ? 'opacity-50' : ''"
                 v-text="useProject.category?.name"/>

            <span class="info__domain"
                    :class="useProject.is_deleted ? 'opacity-50' : ''"
                    v-text="useProject.shortUrl"/>

          </div>

          <div class="content__footer" v-if="!isMobile">
            <v-button class="content__footer-button-settings"
                      tag="a"
                      :href="useProject.link"
                      color="blue2-stroke"
                      :class="modalBlackout ? 'opacity-0' : ''"
                      text="Settings"/>
            <v-button class="content__footer-button-credits"
                      v-if="hasContentFooterButtonCredits"
                      tag="a"
                      :href="useRoutes().url('backlinks-pay.get')"
                      color="green"
                      :class="{'opacity-0':modalBlackout}"
                      text="Buy credits to start"/>
          </div>

        </div>

        <div class="charts content__charts"
             v-if="!useProject.is_deleted"
             :class="useProject.is_demo ? 'charts--demo' : ''">

          <div class="optimization-info chart__rating-optimization" :class="{'w-100': isMobile && useProject.is_demo}">
            <div class="optimization-info__title">
              {{!isMobile ? 'Project audit' : 'Audit'}}
            </div>
            <v-lb-optimization-short-list :project-uniquekey="projectUniquekey" :as-row="isMobile && useProject.is_demo"/>
          </div>

          <div class="chart-activity chart__chart-activity" v-if="!useProject.is_demo">
            <div class="chart-activity__title">Backlink activity (last year)</div>
            <chart-backlinks2 class="chart-activity__chart"
                              :values="values"
                              :labels="labels"
                              :show-labels="!isMobile ? '3-items' : null"
                              type="Actions"/>
          </div>

          <lb-project-demo-bar v-if="useProject.is_demo"
                               class="charts__demo-info"
                               :project-uniquekey="projectUniquekey"
                               is-compact/>

        </div>

        <div class="content__footer"
             :class="{'content__footer--left': useProject.is_deleted, 'content__footer--between': isMobile && hasContentFooterButtonCredits}"
             v-if="isMobile">
          <v-button class="content__footer-button-settings"
                    tag="a"
                    :href="useProject.link"
                    color="blue2-stroke"
                    :class="{'button-settings--hide':modalBlackout}"
                    text="Settings"/>
          <v-button class="content__footer-button-credits"
                    v-if="hasContentFooterButtonCredits"
                    tag="a"
                    :href="useRoutes().url('backlinks-pay.get')"
                    color="green"
                    :class="{'opacity-0':modalBlackout}"
                    text="Buy credits to start"/>
        </div>


      </div>
    </template>

    <template #blackout>
      <div class="content content--modal">
        <div class="info">
          <div class="info__id info__id--white">
            {{useProject.uniquekey}}
            <span v-if="useProject.createdAtDate">Created {{formatDateDDMMYYYY(useProject.createdAtDate)}}</span>
          </div>
          <div class="content__footer" v-if="!isMobile">
            <v-button class="content__footer-button-settings"
                      color="white-stroke"
                      :href="useProject.link"
                      tag="a"
                      text="Settings"/>
          </div>
        </div>
        <div class="content__footer" v-if="isMobile">
          <v-button class="content__footer-button-settings"
                    color="white-stroke"
                    :href="useProject.link"
                    tag="a"
                    text="Settings"/>
        </div>
      </div>
    </template>

    <template #content-after v-if="!useProject.is_deleted">

      <div class="modal-info" v-if="!useProject.is_active">
        <div class="modal-info__title">Paused</div>
        <v-button class="modal-info__button" color="green"
                  :click-action="[useProject.saveIsActive,true]"
                  icon="icon-play-white"
                  text="Resume"/>
      </div>

      <div class="modal-info" v-else-if="useProject.insufficientBalance && !useProject.is_demo">
        <div class="modal-info__title">Not enough credits</div>
        <v-button class="modal-info__button"
                  color="green"
                  text="Buy credits"
                  icon="shopping-cart-white"
                  tag="a"
                  :href="useRoutes().url('buy-credits')"/>
      </div>

    </template>

  </v-project-preview>
</template>

<script setup>

import {useRoutes} from "@app-vue/stores/useRoutes";
import VButton from "@app-vue/components/Base/VButton.vue";
import VProjectPreview from "@app-vue/components/Base/VProjectPreview.vue";
import VLink from "@app-vue/components/Base/VLink.vue";
import {computed, getCurrentInstance} from "vue";
import ChartBacklinks2 from "@app-vue/components/Chart/ChartBacklinks2.vue";
import LbProjectDemoBar from "@app-vue/components/LinkBuilding/LbProjectDemoBar.vue";
import {useLinkBuildProject, useLinkBuildProjectOrders} from "@app-vue/stores/useLinkBuilding";
import VLbOptimizationShortList from "@app-vue/components/Base/LinkBuilding/VLbOptimizationShortList.vue";
import {formatDateDDMMYYYY} from "@app-vue/utils/utils";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const props = defineProps({
  /**
   * @type {'start'|'mini'|'normal'}
   */
  fullness: {type:String,default:'normal',},
  projectUniquekey: {},
});

const useProject = useLinkBuildProject(props.projectUniquekey);
const useProjectOrders = useLinkBuildProjectOrders(props.projectUniquekey);

const modalBlackout = computed(() => {
  if(useProject.is_deleted) return null;
  if(!useProject.is_active) return 'black';
  if(useProject.insufficientBalance && !useProject.is_demo) return 'red';
  return null;
});

const labels = computed(() => {
  const months = [];
  let dateTo = new Date();
  let dateFrom = new Date(dateTo.getFullYear(), dateTo.getMonth() - 11, 1);
  let currentDate = new Date(dateFrom.getFullYear(), dateFrom.getMonth(), 1);

  while (currentDate <= dateTo) {
    const month = currentDate.toLocaleString('en-US', { month: 'short' });
    const year = currentDate.getFullYear().toString().slice(-2);
    const label = `${month.toLowerCase()} '${year}`;
    months.push(label);

    // Увеличиваем месяц
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return months;
});

const values = computed(() => {
  let dateTo = new Date();
  let dateFrom = new Date(dateTo.getFullYear(), dateTo.getMonth() - 11, 1);
  let currentDate = new Date(dateFrom.getFullYear(), dateFrom.getMonth(), 1);

  let response = [];

  while (currentDate <= dateTo) {

    let key = `${currentDate.getFullYear()}-${currentDate.getMonth()}`;

    if(useProjectOrders.countOrdersByMonth.hasOwnProperty(key)){
      response.push(useProjectOrders.countOrdersByMonth[key].count);
    }else{
      response.push(0);
    }

    currentDate.setMonth(currentDate.getMonth() + 1);
  }
  return response;
});

const hasContentFooterButtonCredits = computed(() => {
  return values.value.reduce((s,i) => s+i) === 0
      && !useProject.is_deleted
      && !useProject.is_demo
      && useProject.is_active
});

const downloadProjectAndOrders = async () => {
  if(
      (
          useProject.is_demo
          && useProject.demo_status!== 'expired'
          && useProject.demo_status!== 'delivered'
      )
      || (
          useProject.is_demo
          && (useProject.demo_status=== 'expired' || useProject.demo_status=== 'delivered')
          && !useProject.demoOrderHasLink
      )
  ){
    await Promise.all([
      useProject.downloadProject(),
      useProjectOrders.download(),
    ]);
    setTimeout(() => downloadProjectAndOrders(),5000);
  }
}

//onMounted(() => setTimeout(() => downloadProjectAndOrders(), 5000));

async function convertDemoToReal(){
  let [status] = await useProject.convertDemoToReal();
  if(status){
    await Promise.all([
      useProject.downloadProject(),
      useProjectOrders.download(),
    ]);
  }
}

</script>

<style lang="scss" scoped>
.lb-project-preview{

  .content{
    @include flex(row,between,stretch,24px);
    width:100%;
    padding:18px 24px 18px 20px;
    @media (max-width:767px){
      @include flex(column,start,stretch,16px);
      padding:16px 16px 16px 12px;
    }

    &.content--modal{
      height:100%;
      @media(max-width: 767px){
        @include flex(column,between,stretch,0);
      }
    }
    .content__info{
      width:calc(100% - 600px - 24px);
      @media(max-width:767px){
        width:100%;
      }
      &.content__info--full-width{
        width:100%;
      }
    }
    .content__charts{
      flex-shrink:0;
      width:600px;
      @media (max-width: 767px){
        width:100%;
      }
    }
    .content__footer{
      @include flex(row,end,center,8px);
      &.content__footer--left{
        @include flex(row,start,center,8px);
      }
      &.content__footer--between{
        @include flex(row,between,center,8px);
      }
      .content__footer-button-settings{
        padding:0px 14px;
        height:32px;
        @media(max-width: 767px){
          order:2;
        }
      }
      .content__footer-button-credits{
        padding:0px 11px;
        height:32px;
        @media(max-width: 767px){
          order:1;
        }
      }
    }
  }

  .info {
    @include flex(column, between, start, 44px);
    &.info--short-gap{
      gap:19px;
    }

    .info__top{
      @include flex(column,start,start,6px);
      max-width:100%;
      @media (max-width: 767px) {
        width:100%;
      }
    }

    .info__id{
      @include flex(row,start,center, 6px);
      font:$font-mini-12-regu;
      color:$dark-40;
      &.info__id--white{
        color:#fff;
      }
      &.info__id--hide{
        opacity:0;
      }
    }

    .info__title{
      @include ellipsis();
      font:$font-16-semi;
      @media(max-width:767px){
        font:$font-14-semi;
      }
    }

    .info__domain{
      @include ellipsis();
      color:$dark-50;
    }
    .info__category{
      @include ellipsis();
      color:$dark-50;
    }

    .info__tag-demo{
      font:$font-mini-12-semi;
      color:$dark-40;
    }

    .info__tag-deleted{
      font:$font-mini-12-semi;
      color:$optional-red_120;
    }

  }

  .charts {
    @include flex(row, between, stretch, 24px);

    &.charts--demo{
      @media(max-width:767px){
        @include flex(column, start, stretch, 16px);
      }
    }

    .charts__demo-info{
      flex-grow:2;
      align-self:flex-start;
      margin-top:27px;
      @media(max-width: 767px){
        margin-top:0px;
        order:3;
      }
    }
    .chart__chart-activity{
      flex-grow:2;
      @media(max-width: 767px){
        order:1;
        width:50%;
      }
    }

    .chart__rating-optimization{
      width:170px;
      flex-shrink:0;
      @media(max-width: 767px){
        order:2;
        width:50%;
      }
    }

  }

  .optimization-info{
    @include flex(column,start,start,12px);
    @media(max-width: 767px){
      gap:8px;
    }

    .optimization-info__title{
      color:$dark-40;
      font: $font-mini-12-regu;
    }

  }

  .chart-activity{
    @include flex(column,start,start,12px);
    @media(max-width: 767px){
      gap:8px;
    }
    .chart-activity__title{
      color:$dark-40;
      font:$font-mini-12-regu;
    }
    .chart-activity__chart{
      height:142px;
      @media(max-width:767px) {
        flex-grow:2;
        height:initial;
        min-height:80px;
      }
    }
  }

  .modal-info{
    @include flex(column,center,center, 12px);
    position:absolute;
    z-index:10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    &.modal-info--has-subtitle{
      gap:8px;
    }

    .modal-info__title{
      font:$font-h2-20-semi;
      color:$white-100;
      @media (max-width: 767px) {
        font:$font-16-semi;
      }
    }
    .modal-info__sub_title{
      @include flex(row,center,center,8px);
      color: $white-100;
    }
    .modal-info__button{
      @include flex(row,center,center,8px);
    }
  }

}
</style>