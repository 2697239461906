<template>
  <div class="lb-project-demo-blackout" :class="`is-${useProject.demo_status}`">
    <template v-if="useProject.demo_status === 'delivered'">
      <v-button v-if="false" color="green" class="button" tag="a" :href="useRoutes().url('backlinks-pay.get')" target="_blank">Buy credits</v-button>
      <v-button color="green" class="button" @click.prevent="convertToReal" :loading="loadingConvert">Start Project</v-button>
    </template>
    <template v-else-if="useProject.demo_status === 'expired'">
      <v-button color="green" class="button" @click.prevent="retryDemo" :loading="retryLoading">Retry demo</v-button>
    </template>
  </div>
</template>

<script setup>

import {useRoutes} from "@app-vue/stores/useRoutes";
import VButton from "@app-vue/components/Base/VButton.vue";
import {useLinkBuildProject} from "@app-vue/stores/useLinkBuilding";
import {computed, getCurrentInstance, ref} from "vue";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const props = defineProps({
  projectUniquekey: {},
});

const useProject = useLinkBuildProject(props.projectUniquekey);
const retryLoading = ref(false);
const loadingConvert = ref(false);

function retryDemo(){
  retryLoading.value = true;
  useProject.retryDemo().then(() => {
    window.location.reload();
  })
}

async function convertToReal(){
  if(loadingConvert.value) return;
  loadingConvert.value = true;
  await useProject.convertDemoToReal();
  window.location.reload();
}

</script>

<style lang="scss" scoped>
.lb-project-demo-blackout{
  position:absolute;
  @include flex(column,center,center,18px);
  &.is-expired{
    padding-top:33px;
    @include flex(column,start,center,18px);
  }
  width:100%;
  height:100%;
  top:0px;
  left:0px;
  background:#25587DCC;
  z-index:9999;

  @media(max-width:767px){
    @include flex(column,start,center,18px);
    padding-top:25px;
  }

  .button{
    font-size: 21px;
    font-weight: 700;
    line-height: 18px;
    height:64px;
    padding-left:20px;
    padding-right:20px;
    text-transform:uppercase;
    @media(max-width:767px){
      width:initial;
      padding:18px 20px;
      font-size:18px;
      height:54px;
    }
  }
}
</style>