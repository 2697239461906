<template>
  <div class="bc-payer bcp">
    <bc-payer-tabs class="bcp__tabs" v-model="currentTab"/>
    <div class="bcp__body">

      <transition name="fade" mode="out-in">
        <div class="bcp__item" v-show="currentTab === '#tab_cards'">
          <bc-payer-card-form :items="cardsItems" type="cards" :cart-type="cartType" :project-uniquekey="projectUniquekey"/>
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div class="bcp__item" v-show="currentTab === '#tab_alternative'">
          <bc-payer-base :items="alternativeItems" type="pay-by-link" :cart-type="cartType" :project-uniquekey="projectUniquekey"/>
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div class="bcp__item" v-show="currentTab === '#tab_paypal'">
          <bc-payer-fastspring :items="paypalItems" type="paypal" :cart-type="cartType" :project-uniquekey="projectUniquekey"/>
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div class="bcp__item" v-show="currentTab === '#tab_crypto'">
          <bc-payer-crypto :cart-type="cartType" :project-uniquekey="projectUniquekey"/>
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div class="bcp__item" v-show="currentTab === '#tab_bank_transfer'">
          <bc-payer-bank-transfer :cart-type="cartType" :project-uniquekey="projectUniquekey"/>
        </div>
      </transition>

    </div>
  </div>
</template>

<script setup>
import BcPayerTabs from "@app-vue/components/BuyCredits/BcPayer/BcPayerTabs.vue";
import {reactive, ref} from "vue";
import BcPayerBase from "@app-vue/components/BuyCredits/BcPayer/BcPayerBase.vue";
import BcPayerCrypto from "@app-vue/components/BuyCredits/BcPayer/BcPayerCrypto.vue";
import BcPayerBankTransfer from "@app-vue/components/BuyCredits/BcPayer/BcPayerBankTransfer.vue";
import BcPayerCardForm from '@app-vue/components/BuyCredits/BcPayer/BcPayerCardForm.vue';
import BcPayerFastspring from '@app-vue/components/BuyCredits/BcPayer/BcPayerFastspring.vue';

const props = defineProps({
  /** @cartType {'main'|'link-building'}*/
  cartType: {default: 'main'},
  projectUniquekey: {},
});

const currentTab = ref('#tab_cards');

const cardsItems = reactive([
  {
    icons: [
      {src: '/img/payments/payment-visa.svg',},
      {src:'/img/payments/payment-mastercard.svg',},
      {src:'/img/payments/payment-diners.svg',},
      {src:'/img/payments/payment-discover.svg',},
      {src:'/img/payments/payment-jcb.svg',},
      {src:'/img/payments/payment-american-express.svg',},
      {src:'/img/payments/payment-union-pay.svg',},
    ],
    buttonTitle: 'Pay with Card',
    payType: 'stripe',
  },
]);

const paypalItems = reactive([
  {
    icons: [
      {
        src: '/img/payments/payment-paypal.svg',
        style: {
          width:'auto',
          height: '28px'
        }
      },
      {
        src: '/img/payments/payment-visa.svg',
        style: {
          width:'60px',
          height: '60px'
        }
      },
      {
        src: '/img/payments/payment-mastercard.svg',
        style: {
          width:'60px',
          height: '60px'
        }
      },
      {
        src: '/img/payments/payment-american-express.svg',
        style: {
          width:'60px',
          height: '60px'
        }
      },
    ],
    buttonTitle: 'Pay Now',
    payType: 'paypal',
  }
]);

const alternativeItems = reactive([
  {
    icons: [
      {src: '/img/payments/payment-visa.svg',},
      {src:'/img/payments/payment-mastercard.svg',},
      {src:'/img/payments/payment-diners.svg',},
      {src:'/img/payments/payment-discover.svg',},
      {src:'/img/payments/payment-jcb.svg',},
      {src:'/img/payments/payment-american-express.svg',},
      {src:'/img/payments/payment-union-pay.svg',},
    ],
    buttonTitle: 'Debit or Credit Card, CommerceGate',
    payType: 'cg-by-link',
  },
  {
    icons: [
      {src: '/img/payments/payment-visa.svg',},
      {src:'/img/payments/payment-mastercard.svg',},
      {src:'/img/payments/payment-google-pay.svg',},
      {src:'/img/payments/payment-ali-pay.svg',},
      {src:'/img/payments/payment-sofort.svg',},
      {src:'/img/payments/payment-neosurf.svg',},
      {src:'/img/payments/payment-wechat-pay.svg',},
    ],
    buttonTitle: 'Debit or Credit Card, PaymentWall',
    payType: 'paymentwall',
  },
]);

defineExpose({currentTab});

</script>

<style scoped lang="scss">

.bc-payer.bcp{
  .bcp__item{
    padding-top:20px;
  }
}

.fade-enter-from{
  opacity:0;
}
.fade-enter-active{
  transition: opacity 0.5s;
}
.fade-enter-to{
  opacity:1;
}
.fade-leave-from{
  display:none;
}
.fade-leave-active{
  display:none;
}
.fade-leave-to{
  display:none;
}

</style>