<template>
  <div class="lb-project-orders-toggle"
       :class="!triggerShowOrders ? 'is-show-button' : 'is-hide-button'">
    <v-icon-svg-wrapped :outer-size="18"
                        :inner-size="[6,10]"
                        name="arrow-dark-40"
                        class="arrow"
                        @click="triggerShowOrders = !triggerShowOrders"
                        as-img/>
    <v-link only-style
            class="link"
            :text="!triggerShowOrders ? 'Show my older backlinks' : 'Hide my older backlinks'"
            @click="triggerShowOrders = !triggerShowOrders"/>

    <span v-if="!triggerShowOrders && !isMobile"
          v-text="'(will open from the bottom)'"
          class="caption"/>
  </div>
</template>

<script setup>

import VIconSvgWrapped from "@app-vue/components/Base/VIconSvgWrapped.vue";
import VLink from "@app-vue/components/Base/VLink.vue";
import {computed, getCurrentInstance} from "vue";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const triggerShowOrders = defineModel();

</script>

<style lang="scss" scoped>
.lb-project-orders-toggle{
  @include flex(row,start,center,8px);
  .arrow{
    transform:rotate(90deg);
    transition-duration: 0.2s;
  }
  .link{
    cursor:pointer;
  }
  .caption{
    font:$font-mini-12-regu;
    color:$dark-40;
  }
  &.is-hide-button{
    .arrow{
      transform:rotate(-90deg);
    }
  }
}
</style>