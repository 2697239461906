<template>
  <div class="sc-project-recommendations" v-if="useProject.count_recommendations > 0">
    <v-icon-svg name="exclamation-mark-circle"
                class="recommendation-icon"
                as-block
                :class="`is-${useProject.max_degree_recommendations}`"/>
    <v-link class="recommendation-title"
            @click.prevent="emit('recommendation-group-click')"
            :text="useProject.count_recommendations"/>
  </div>
</template>

<script setup>

import {useSeoClicksProject} from "@app-vue/stores/useSeoClicks";
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import VLink from "@app-vue/components/Base/VLink.vue";

const props = defineProps({
  projectId:{},
});

const useProject = useSeoClicksProject(props.projectId);

const emit = defineEmits(['recommendation-group-click']);

</script>

<style lang="scss" scoped>
.sc-project-recommendations{
  @include flex(row,start,center,6px);
  .recommendation-icon{
    width: 20px;
    height: 20px;
    fill:#EF8E34;
    &.is-warning{fill:#EF8E34;}
    &.is-danger{fill:#F2001D;}
  }
  .recommendation-title{
    font:$font-16-semi;
    font-weight: 600;
    font-size:16px;
    line-height:20px;
    @media(max-width: $mobile-width){
      font:$font-14-semi;
    }
  }
}
</style>