<template>
  <div class="lb-add-project lbap">
    <v-container>

      <v-breadcrumbs :items="breadcrumbs" class="lbap__bradcrumbs"/>

      <v-head-title class="lbap__title">
        Create New Backlinks Project
      </v-head-title>

      <div class="lbap__wrap">

        <div class="lbap__form">
          <lb-add-project-form/>
        </div>

        <div class="lbap__info">
          <lb-add-project-info/>
        </div>

      </div>

      <div class="lbap__faq">
        <section-faq type="blogger-outreach"/>
      </div>

    </v-container>
  </div>
</template>

<script setup>

import VBreadcrumbs from "@app-vue/components/Base/VBreadcrumbs.vue";
import VContainer from "@app-vue/components/Base/VContainer.vue";
import VHeadTitle from "@app-vue/components/Base/VHeadTitle.vue";
import {useRoutes} from "@app-vue/stores/useRoutes.js";
import LbAddProjectForm from "@app-vue/components/LinkBuilding/LbAddProjectForm.vue";
import LbAddProjectInfo from "@app-vue/components/LinkBuilding/LbAddProjectInfo.vue";
import SectionFaq from "@app-vue/components/Blocks/SectionFaq.vue";

const breadcrumbs = [
  {url: useRoutes().url('backlinks-project.list'), title: 'Backlinks'},
  {url: useRoutes().url('backlinks-project.list'), title: 'My Projects'},
  {title: 'Create New',},
];

</script>

<style scoped lang="scss">
.lb-add-project.lbap{
  .lbap__bradcrumbs{
    margin-bottom:10px;
    @media(max-width:767px){
      margin-bottom: 0px;
    }
  }
  .lbap__balance{
    margin-bottom:30px;
    @media (max-width:767px) {
        margin-bottom:20px;
    }
  }
  .lbap__title{
    margin-bottom:30px;
    @media (max-width:767px) {
        margin-bottom:20px;
    }
  }
  .lbap__wrap{
    padding: 30px;
    gap: 30px;
    border-radius: 8px;
    background:#fff;
    box-shadow: $box-shadow-default;
    margin-bottom:30px;
    display:flex;
    gap:30px;
    margin-bottom:30px;

    .lbap__form{
      width:50%;
    }

    .lbap__info{
      width:50%;
    }

    @media(max-width:767px) {
      padding: 16px;
      flex-wrap:wrap;
      margin-bottom:20px;
      .lbap__form{
        width:100%;
      }

      .lbap__info{
        width:100%;
      }
    }

  }


  .lbap__faq{
    margin-bottom:40px;
  }

}
</style>