<template>
  <table class="bh-table">

    <thead>
    <tr>
      <th class="th-type">
          <span class="th-sortable"
                :class="'is-'+useBillingHistory().filters.dateSort"
                @click="useBillingHistory().changeSort()">
            Type <v-icon-svg class="th-sortable__icon" name="arrow-sort" as-img as-block/>
          </span>
      </th>
      <th class="th-date">Date</th>
      <th class="th-project-credits">
        <div>
          <span>Project</span>
          <span>Credits</span>
        </div>
      </th>
      <th class="th-subscription"></th>
    </tr>
    </thead>

    <tbody>
    <v-bh-table-space :space="10"/>

    <!-- LIST ITEMS -->
    <template v-for="(group, index) in useBillingHistory().sortedGroups" :key="group.uniqueKey">

      <v-bh-table-space v-if="index > 0" v-show="group.isShow" :space="6"/>
      <tr v-show="group.isShow" class="tr-info" :class="getTrClasses(group)">

        <td class="td-type">
          <div class="info-type">
            <v-bh-payment-type :type="group.type" class="info-type__type"/>
            {{group.payment?.id}}
          </div>
          <v-bh-overlay-declined v-if="group.payment?.isDeclined" :payment-id="group.paymentId" class="overlay-declined"/>
          <v-bh-overlay-pending v-if="group.payment?.isPending" class="overlay-pending"/>
        </td>

        <td class="td-date">
          <div class="info-date">{{group.dateFormatted}}</div>
        </td>

        <td class="td-project" v-if="group.isPayment">
          <div class="payment-info">
            <div>
              <v-bh-info-payment :group-uniquekey="group.uniqueKey"/>
            </div>
            <div>
              <v-bh-info-credits :credits="group.listCredits"/>
            </div>
          </div>
        </td>

        <td class="td-project" v-if="group.isSpending">
          <div class="projects-and-credits">
            <div v-for="item in group.spendingsProjectsAndCredits.slice(0, group.projectAndCreditsExpanded ? group.spendingsProjectsAndCredits.length : 5)">
              <div>
                <v-link :href="item.project.link" target="_blank">{{item.project.title}}</v-link>
              </div>
              <div>
                <v-bh-info-credit :credit="item.credit"/>
              </div>
            </div>
            <div>
              <div></div>
              <div>
                <v-link v-if="group.spendingsProjectsAndCredits.length > 5"
                        class="projects-and-credits__link-expanded"
                        @click="group.$patch({projectAndCreditsExpanded: !group.projectAndCreditsExpanded})"
                        :class="{'is-expanded':group.projectAndCreditsExpanded}">
                  <v-icon-svg-wrapped name="arrow-down-blue-100"
                                      class="projects-and-credits__link-expanded-icon"
                                      :outer-size="18"
                                      :inner-size="[11,7]"
                                      as-img as-block/>
                  {{group.projectAndCreditsExpanded ? 'hide' : 'Show more '+(group.spendingsProjectsAndCredits.length - 5)}}
                </v-link>
              </div>
            </div>
          </div>
        </td>

        <td class="td-project" v-if="group.isTrial">
          <v-link text="Free Trial" only-style/>
        </td>

        <!-- INFO SUBSCRIPTION -->

        <td v-if="group.isPayment">

          <div v-if="group.payment.subscriptionStatusHaveNo" class="subscription is-red is-have-no">

            <div class="subscription__left is-centered">
              <div class="subscription__title is-red">
                <v-icon-svg name="icon-cancel" class="subscription__title-icon"/> No subscription
              </div>
            </div>

            <div class="subscription__right">
              <v-button v-if="group.payment.recoverUrl"
                        class="subscription__recover"
                        tag="a"
                        :href="group.payment.recoverUrl"
                        text="Recover Subcription"
                        color="green"/>
            </div>

          </div>

          <div v-if="group.payment.subscriptionStatusCancelled" class="subscription is-red is-cancelled">

            <div class="subscription__left" :class="{'is-centered': !group.payment.subscription?.external_id}">
              <div class="subscription__title is-red">
                <v-icon-svg name="icon-cancel" class="subscription__title-icon"/> Subscription cancelled
              </div>
              <v-link class="subscription__id"
                      :href="group.payment.subscriptionLink"
                      is-ends-ellipsis
                      :text="group.payment.subscription.external_id"/>
            </div>

            <div class="subscription__right">
              <v-button v-if="group.payment.recoverUrl"
                        class="subscription__recover"
                        tag="a"
                        :href="group.payment.recoverUrl"
                        text="Recover"
                        color="green"/>
            </div>

          </div>

          <div v-if="group.payment.subscriptionStatusOk" class="subscription is-status-ok">
            <div class="subscription__left">
              <div class="subscription__title">
                <v-icon-svg name="icon-ok" class="subscription__title-icon"/> Subscription
              </div>
              <v-link class="subscription__id"
                      :href="group.payment.subscriptionLink"
                      is-ends-ellipsis
                      :text="group.payment.subscription.external_id"/>
            </div>

            <div class="subscription__right">
              <div class="subscription__bonus" v-if="group.payment.subscription.bonuses.length > 0">
                <div class="subscription__bonus-title">Bonus:</div>
                <div class="subscription__bonus-item" v-for="bonus in group.payment.subscription.bonuses">
                  <span>+{{shortNumber(bonus.amount,3)}}</span>
                  <span>{{bonus.type}}</span>
                </div>
              </div>
            </div>

          </div>

        </td>

        <td v-if="group.isSpending || group.isTrial"></td>

      </tr>

    </template>

    </tbody>

  </table>

</template>

<script setup>

import VBhPaymentType from "@app-vue/components/Base/BillingHistory/VBhPaymentType.vue";
import {onMounted, ref, watch} from "vue";
import VLink from "@app-vue/components/Base/VLink.vue";
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import VBhInfoCredits from "@app-vue/components/Base/BillingHistory/VBhInfoCredits.vue";
import VBhInfoPayment from "@app-vue/components/Base/BillingHistory/VBhInfoPayment.vue";
import VBhOverlayPending from "@app-vue/components/Base/BillingHistory/VBhOverlayPending.vue";
import VBhTableSpace from "@app-vue/components/Base/BillingHistory/VBhTableSpace.vue";
import {useBillingHistory,} from "@app-vue/stores/useBillingHistory";
import {shortNumber} from "@app-vue/utils/utils";
import {useRefsManager} from "@app-vue/utils/useRefsManager";
import VBhInfoCredit from "@app-vue/components/Base/BillingHistory/VBhInfoCredit.vue";
import VIconSvgWrapped from "@app-vue/components/Base/VIconSvgWrapped.vue";
import VButton from "@app-vue/components/Base/VButton.vue";
import VBhOverlayDeclined from '@app-vue/components/Base/BillingHistory/VBhOverlayDeclined.vue';

const props = defineProps({});

const refsStatusMessage = useRefsManager()

const refsTooltipRenewBonus = useRefsManager();

const tooltipMouseStatus = ref({});
function tooltipMouseEnter(id){
  tooltipMouseStatus.value[id] = true;
  refsTooltipRenewBonus.get(id).show();
}

function tooltipMouseLeave(id){
  if(id !== firstTooltipId.value){
    tooltipMouseStatus.value[id] = false;
    setTimeout(() => {
      if(!tooltipMouseStatus.value[id]){
        refsTooltipRenewBonus.get(id).hide();
      }
    },200);
  }
}

const firstTooltipId = ref(null);

function openFirstTooltip(){
  let hasOpened = false;
  useBillingHistory().sortedGroups.forEach((group) => {
    if(group.type === 'payment'){
      if(group.payment.subscription.status !== 'ok' && !hasOpened){
        hasOpened = true;
        firstTooltipId.value = group.uniqueKey;
        refsTooltipRenewBonus.get(group.uniqueKey).show();
      }else if(group.payment.subscription.status !== 'ok'){
        refsTooltipRenewBonus.get(group.uniqueKey).hide();
      }
    }
  })
}

function handlerTooltipClose(id){
  if(id === firstTooltipId.value){
    firstTooltipId.value = null;
  }
}

onMounted(() => {
  //setTimeout(() => {
  //openFirstTooltip();
  //},500);
});

watch(() => [useBillingHistory().filters], () => {
  refsTooltipRenewBonus.getKeys().forEach((key) => {
    refsTooltipRenewBonus.get(key)?.hide();
  });
},{deep:true,once:true});

function getTrClasses(group){
  return [
    'is-'+group.type,
    group.isPayment ? 'is-payment-status-'+group.payment.paymentStatus : '',
  ]
}

</script>

<style lang="scss" scoped>

$bh-table-project-width: 120px;
$bh-table-project-padding-right: 10px;
$bh-table-subscription-width:342px;

.bh-table{
  table-layout: fixed;

  .th-type{width:84px;}
  .th-date{width:84px;}
  .th-project{width:170px;}
  .th-credits{width:200px;}
  .th-project-credits{
    width:350px;
    div{
      @include flex(row,start,center);
      span{
        &:first-child{
          width:calc($bh-table-project-width + $bh-table-project-padding-right);
        }
      }
    }
  }
  .th-subscription{
    width:$bh-table-subscription-width;
  }

  .th-sortable{
    @include flex(row,start,center,3px);
    color:$dark-80;
    cursor:pointer;
    width:min-content;
    &.is-desc{
      .th-sortable__icon{
        transform:rotateZ(180deg);
      }
    }
  }

  thead{
    th{
      border-bottom:1px solid $dark-10;
      height:40px;
      font:$font-mini-12-regu;
      color:$dark-30;
    }
  }

  tbody{

    .tr-info{
      td{
        vertical-align: top;
        &.td-project, &.td-credits{
          padding-top:4.5px;
        }
      }
      &.is-payment{
        td{}
      }
      &.is-payment-status-declined, &.is-payment-status-pending{
        position:relative;
        .info-type{
          min-height:88px;
        }
      }
      &.is-payment-status-pending > td :not(.overlay-pending){
        opacity:0.5;
      }
    }

  }

  .info-type{
    @include flex(column,start,start,2px);
    width:73px;
    .info-type__type{
      width:73px;
    }
  }
  .info-date{
    @include flex(row,start,center);
    height:23px;
    color:$dark-40;
    font:$font-mini-11-regu;
  }

  .subscription{
    @include flex(row,between,start,10px);
    padding:12px 12px;
    border-radius:4px;
    max-width:$bh-table-subscription-width;
    background:$primary-green-5;
    font-size:13px;

    &.is-red{
      background: #C70B0B0D;
    }

    .subscription__left{
      @include flex(column,start,start,3px);
      &.is-centered{
        align-self: center;
      }
    }
    .subscription__right{
      flex-grow:2;
    }

    &.is-have-no{
      .subscription__left{
        width:152px;
      }
    }

    &.is-cancelled{
      .subscription__left{
        width:220px;
      }
    }
    &.is-status-ok{
      .subscription__left{
        width:152px;
      }

    }



    .subscription__title{
      @include flex(row,start,center,4px);
      color:$dark-50;
      white-space: nowrap;

      &.is-red{
        color:$optional-red_120;
      }
      .subscription__title-icon{
        flex-shrink: 0;
      }
    }

    .subscription__recover{
      padding:8px 12px;
      font-size:13px;
    }

    .subscription__bonus{
      @include flex(column,start,start,4px);
      color:$primary-green-120;
      .subscription__bonus-title{
        font:$font-mini-12-semi;
        margin-bottom:2px;
      }
      .subscription__bonus-item{
        @include flex(row,start,center,6px);
        span{
          &:first-child{
            font:$font-mini-12-semi;
          }
          &:last-child{
            font:$font-mini-12-regu;
          }
        }
      }
    }

  }

  .projects-and-credits{
    @include flex(column,start,start,4px);
    > div{
      @include flex(row,start,start,$bh-table-project-padding-right);
      padding-right: 15px;
      > div{
        &:first-child{
          flex-shrink:0;
          width:$bh-table-project-width;
          @include ellipsis;
        }
      }
    }

    .projects-and-credits__link-expanded{
      @include flex(row,start,center,5px);
      .projects-and-credits__link-expanded-icon{
        transition-duration: 0.2s;
      }
      &.is-expanded{
        .projects-and-credits__link-expanded-icon{
          transform:rotate(180deg);
        }
      }
    }
  }

  .payment-info{
    @include flex(row,start,start,$bh-table-project-padding-right);
    padding-right: 15px;
    > div{
      &:first-child{
        flex-shrink:0;
        width:$bh-table-project-width;
        @include ellipsis;
      }
    }
  }

}
</style>