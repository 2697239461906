<template>
  <a :href="link" class="bc-balance-item bcbi" :class="mainClass" ref="refLinkEl" @mouseenter="userBalanceItem.creditType === CREDIT_TYPE_WEBSITE ? refTooltip.show() : ''" @mouseleave="userBalanceItem.creditType === CREDIT_TYPE_WEBSITE ? refTooltip.hide() : ''">
    <div class="bcbi__top">

      <div class="bcbi__indicator"></div>
      <v-product :type-constant="userBalanceItem.typeConstant"
                 class="bcbi__product"
                 :is-short="userBalanceItem.creditType === CREDIT_TYPE_SEO || userBalanceItem.creditType === CREDIT_TYPE_BACKLINK_V2"/>

    </div>
    <div class="bcbi__bottom">

      <div class="bcbi__count-credits">
        Credits: {{ userBalanceItem.creditType === CREDIT_TYPE_WEBSITE ? shortNumber(userBalanceItem.balance) : userBalanceItem.balance }}
      </div>

    </div>
  </a>

  <v-tooltip v-if="userBalanceItem.creditType === CREDIT_TYPE_WEBSITE"
             ref="refTooltip"
             reset-tooltip
             :get-linked-block="() => refLinkEl"
             :placement="'top'"
             :btnCloseOn="false"
             :hasArrow="true"
             :offset="[0, -6]"
  >
    <template #content>
      <div class="bc-balance-item-tooltip">{{ userBalanceItem.balance }}</div>
    </template>
  </v-tooltip>
</template>

<script setup>

import VProduct from "@app-vue/components/Base/VProduct.vue";
import {useUserBalanceItem} from "@app-vue/stores/useStaticData";
import {computed, ref} from "vue";
import {CREDIT_TYPE_BACKLINK, CREDIT_TYPE_BACKLINK_V2, CREDIT_TYPE_SEO, CREDIT_TYPE_WEBSITE} from "@app-vue/stores/useBilling";
import {useRoutes} from "@app-vue/stores/useRoutes";
import {shortNumber} from '../../utils/utils.js';
import VTooltip from '../Base/VTooltip.vue';

const props = defineProps({
  typeConstant: {},
});

const userBalanceItem = useUserBalanceItem(props.typeConstant);

const mainClass = computed(() => {
  return 'bcbi--status-'+userBalanceItem.status;
});

const link = computed(() => {
  switch(userBalanceItem.creditType){
    case CREDIT_TYPE_SEO: return useRoutes().url('purchase-clicks.get', {});
    case CREDIT_TYPE_BACKLINK: return useRoutes().url('backlinks-pay.get', {});
    case CREDIT_TYPE_BACKLINK_V2: return useRoutes().url('backlinks-pay.get', {});
    case CREDIT_TYPE_WEBSITE: return useRoutes().url('website-traffic-pay.get', {},{});
  }
});

const refLinkEl = ref();
const refTooltip = ref();

</script>

<style lang="scss" scoped>
.bc-balance-item.bcbi{
  @include flex(column,start,start,2px);
  padding: 10px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #D6E3EF;
  text-decoration: none;

  .bcbi__top{
    @include flex(row,start,center,8px);
  }

  .bcbi__indicator{
    width:7px;
    height:7px;
    flex-shrink:0;
    border-radius:50%;
  }

  .bcbi__product{
    font-size:14px;
  }

  .bcbi__count-credits{
    font-size: 12px;
    font-weight: 400;
    color:#798CAE;
  }

  &.bcbi--status-red{
    border-color:#F2001D;
    .bcbi__indicator{
      background-color:#C70B0B;
    }
  }
  &.bcbi--status-yellow{
    .bcbi__indicator{
      background-color:#EF8E34;
    }
  }
  &.bcbi--status-green{
    .bcbi__indicator{
      background-color:#219E26;
    }
  }
}

.bc-balance-item-tooltip {
  padding: 10px 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  color: #5e718d;
  text-align: left;
  background: #fff;
  border-radius: 6px;
}
</style>