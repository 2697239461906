<template>
  <v-container class="sc-project-add">

    <v-breadcrumbs :items="breadcrumbs" no-padding/>

    <bc-balance :credit-type="CREDIT_TYPE_SEO"/>

    <v-head-title>Create New SEO Traffic Project</v-head-title>

    <v-white-container>
      <sc-project-add-form/>
    </v-white-container>

    <section-faq type="clicks"/>

  </v-container>
</template>

<script setup>
import VBreadcrumbs from "@app-vue/components/Base/VBreadcrumbs.vue";
import VContainer from "@app-vue/components/Base/VContainer.vue";
import VHeadTitle from "@app-vue/components/Base/VHeadTitle.vue";
import {ref} from "vue";
import {useRoutes} from '@app-vue/stores/useRoutes.js'
import ScProjectAddForm from "@app-vue/components/SeoClicks/ScProjectAddForm.vue";
import SectionFaq from "@app-vue/components/Blocks/SectionFaq.vue";
import BcBalance from "@app-vue/components/BuyCredits/BcBalance.vue";
import {CREDIT_TYPE_SEO} from "@app-vue/stores/useBilling";
import VWhiteContainer from "@app-vue/components/Base/VWhiteContainer.vue";

const breadcrumbs = ref([
  {url: useRoutes().url('seo-clicks.list'), title: 'SEO Traffic',},
  {url: useRoutes().url('seo-clicks.list'), title: 'My Projects',},
  {title: 'Create New',}
]);

</script>

<style lang="scss" scoped>
.sc-project-add{
  @include flex(column,start,stretch,24px);
  @media(max-width:767px){
    gap:20px;
  }
}
</style>