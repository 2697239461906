<template>
  <div class="chart-realtime-v2" ref="refWrapCanvas">
    <div class="canvas-wrap" :class="{'canvas-wrap--has-labels': !!showLabels, 'canvas-wrap--labels-is-absolute': showLabels === 'at-the-ends-absolute'}">
      <canvas ref="refCanvas"></canvas>
    </div>
    <div class="labels" v-if="!!showLabels" :class="'labels--'+showLabels">
      <div class="label">{{values.length + 1}} min</div>
      <div class="label">1 min</div>
    </div>
  </div>
</template>

<script setup>

import {computed, onMounted, onUnmounted, ref, watch} from "vue";
import {getScssVar} from "@app-vue/utils/utils";
import {ChartConfigBase, ChartControllerBase} from "@app-vue/utils/chartClasses";
import {calculateScaleYMaxForBar, jumpingBarPlugin} from "@app-vue/utils/chartUtils";

const props = defineProps({
  /**
   * @type {Number[]}
   */
  values: {type: Array, default: [],},
  /**
   * @type {'at-the-ends-absolute'|'at-the-ends'|null}
   */
  showLabels: {type:String, default:null},
  showScaleY: {type:Boolean,default:false,},
  animateLastBar: {type:Boolean,default:true,}
});

const refWrapCanvas = ref();
const refCanvas = ref();

class ChartConfig extends ChartConfigBase{

  colors = ref({
    border: getScssVar('--seo-traffic-color'),
    background: getScssVar('--seo-traffic-color-dark'),
  });

  scaleYMax = computed(() => {
    return calculateScaleYMaxForBar(Math.max(...props.values));
  });

  result = computed(() => {
    if(!this.ready()){return null;}
    return {
      type: 'bar',
      data: {
        labels: new Array(props.values.length).fill(''),
        datasets: [{
          data: props.values,
          backgroundColor: this.colors.value.background,
          borderColor: this.colors.value.border,
          borderWidth: {
            top: 1,
            left: 0,
            right: 0,
            bottom: 0,
          },
          barPercentage:1.1,
          minBarLength: 1.2,
        }],
      },
      options: {
        animation:{duration:0,},
        responsive: true,
        maintainAspectRatio:false,
        hover: {mode: null},
        plugins: {
          tooltip:{enabled:false,},
          legend: {display: false,},
          jumpingBarPlugin: {active:props.animateLastBar},
        },
        scales: {
          x: {display:false,},
          y: {
            display:props.showScaleY,
            max: this.scaleYMax.value,
            min: 0,
            border:{width: 1, color: '#EEF2FB', display: true,},
            ticks: {
              autoSkip:true,
              color: "rgba(31,41,59, 0.5)",
              maxTicksLimit:6,
              padding:0,
            },
            grid:{color:'transparent',}
          },
        }
      },
      plugins: [jumpingBarPlugin],
    }
  });
}

const chartController = new ChartControllerBase(refWrapCanvas, refCanvas, ChartConfig);

function animateChart() {
  if(chartController.chartJS?.ctx) chartController.chartJS?.render();
  if(props.animateLastBar) requestAnimationFrame(animateChart);
}

onMounted(() => {
  chartController.componentOnMounted();
  if(props.animateLastBar) requestAnimationFrame(animateChart);
});

watch(() => props.animateLastBar, (animateLastBar) => {
  if(animateLastBar) requestAnimationFrame(animateChart);
});

onUnmounted(() => {chartController.componentOnUnMounted();});

</script>

<style lang="scss" scoped>
.chart-realtime-v2{
  width:100%;
  position:relative;
  canvas{
    position:relative;
  }
  .canvas-wrap{
    width:100%;
    position:absolute;
    height:100%;
    &.canvas-wrap--has-labels:not(.canvas-wrap--labels-is-absolute){
      height:calc(100% - 11px - 6px);
    }
  }
  .labels{
    .label{
      font:$font-mini-9-regu;
      color:$dark-30;
    }
    &.labels--at-the-ends-absolute{
      @include flex(row,between,center);
      position:absolute;
      width:100%;
      bottom:-6px;
      transform: translateY(100%);
    }
    &.labels--at-the-ends{
      @include flex(row,between,center);
      position:absolute;
      bottom:0px;
      height:11px;
      width:100%;
    }

  }
}
</style>