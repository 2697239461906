import {computed} from "vue";
import {getTooltip} from "@app-vue/utils/tooltipsData";

export default function  (type, name, selector, hasQuestion, questionDescription){

    const tooltipData = computed(() => {
        return getTooltip(type, name);
    });

    const _hasQuestion = computed(() => {
        return hasQuestion || tooltipData.value;
    });

    const _tooltipData = computed(() => {
        if(!_hasQuestion.value){
            return null;
        }
        return tooltipData.value ?? {description:questionDescription};
    });

    return {_hasQuestion, _tooltipData}

}