<template>
  <div class="lb-step-cart-packages-dropdown">
    <lb-step-cart-packages-item v-if="useCart.packageQuantity !== useCart.credits - useUserBalance().backlinkV2BalancesAmount"
                                type="dropdown"
                                :quantity="(useCart.credits - useUserBalance().backlinkV2BalancesAmount < 1 ? 1 : useCart.credits - useUserBalance().backlinkV2BalancesAmount)"
                                @change-quantity="changeQuantity"/>
    <template v-for="option in options">
      <lb-step-cart-packages-item type="dropdown"
                                  v-if="useCart.packageQuantity !== option.quantity"
                                  v-show="option.quantity > useCart.credits - useUserBalance().backlinkV2BalancesAmount"
                                  :quantity="option.quantity"
                                  @change-quantity="changeQuantity"/>
    </template>
  </div>
</template>

<script setup>

import LbStepCartPackagesItem from "@app-vue/components/LinkBuilding/LbStepCartPackagesItem.vue";
import {computed} from "vue";
import {useCreditTypes} from "@app-vue/stores/useBilling";
import {isArray} from "lodash";
import {useLinkBuildCart} from "@app-vue/stores/useLinkBuilding";
import {useUserBalance} from "@app-vue/stores/useStaticData";

const props = defineProps({
  projectUniquekey:{},
});

const options = computed(() => {
  let packages = useCreditTypes().creditTypesBacklinkV2?.firstPlanOptions.plan_options_packages
  if(!isArray(packages)) return [];
  return packages;
});

const useCart = useLinkBuildCart(props.projectUniquekey);

const emit = defineEmits(['changeQuantity']);

function changeQuantity(quantity){
  emit('changeQuantity',quantity);
}

</script>

<style lang="scss" scoped>
.lb-step-cart-packages-dropdown{
  box-shadow: $box-shadow-tooltip;
  border-radius:4px;
  overflow:hidden;
}
</style>