<template>
  <div class="sc-charts-list">

    <div class="chart-column">

      <div class="column-title">
        <div class="column-title-left">
          <span class="column-title--bold">Clicks Delivered</span>
          <div class="column-title-period-wrap">
            <div class="column-title-period-label">Period:</div>
            <v-period-simple
                :start-date="periodSelectedStart"
                :end-date="periodSelectedEnd"
                ref="inputPeriodSimple"
                @save="savePeriodChart"
            />
          </div>
        </div>
        <div class="column-title-right">
          <span class="column-title--bold">{{useProjectsDailyChart?.chartData?.totalHits}} clicks</span>
        </div>
      </div>

      <div class="chart">
        <chart-seo-clicks :chart="useProjectsDailyChart.chartData" chart-size="normal" class="chart-inner" ref="canvasChart" is-blue/>
      </div>

    </div>

    <div class="realtime-column">

      <div class="column-title">
        <div class="column-title-left column-title-left--short-gap">
          <span class="column-title--bold column-title-realtime">
            <v-icon-realtime-pulse class="column-title-realtime-icon"/>
            Realtime
          </span>
          <div class="column-title--muted">
            Last 30 min
          </div>
        </div>
      </div>

      <div class="realtime">
        <chart-realtime-v2 class="realtime__chart" :values="useRealtimeSeoClicksProjects().values" show-scale-y/>
      </div>

    </div>
  </div>
</template>

<script setup>

import ChartSeoClicks from "@app-vue/components/Chart/ChartSeoClicks.vue";
import VPeriodSimple from "@app-vue/components/Base/VPeriodSimple.vue";
import VIconRealtimePulse from "@app-vue/components/Base/VIconRealtimePulse.vue";
import {useRealtimeSeoClicksProjects, useSeoClicksProjectsDailyChart} from "@app-vue/stores/useSeoClicks";
import {onMounted, ref, watch} from "vue";
import ChartRealtimeV2 from "@app-vue/components/Chart/ChartRealtimeV2.vue";

const useProjectsDailyChart = useSeoClicksProjectsDailyChart();
useProjectsDailyChart.update();

const canvasChart = ref();
const inputPeriodSimple = ref();
watch(() => useProjectsDailyChart.chartData, () => {
  canvasChart?.value?.update(useProjectsDailyChart.chartData);
}, {deep:true});

const periodSelectedStart = ref((() => {
  let startDays = new Date();
  startDays.setDate(startDays.getDate() - 30);
  return startDays.getTime();
})());
const periodSelectedEnd = ref((new Date().getTime()));
const savePeriodChart = async (startDate, endDate) => {

  let [status,message] = await useProjectsDailyChart.updateFromTo(new Date(startDate), new Date(endDate));

  if(status){
    periodSelectedStart.value = startDate;
    periodSelectedEnd.value = endDate;
    inputPeriodSimple.value.saveWithSuccess();
  }else{
    inputPeriodSimple.value.saveWithError(message);
  }

}

function realtimeUpdateStart(){
  useRealtimeSeoClicksProjects().update().then(() => {
    setTimeout(() => realtimeUpdateStart(), 1000*10);
  });
}

onMounted(() => {
  realtimeUpdateStart();
});


</script>

<style lang="scss" scoped>

.sc-charts-list{
  display: flex;
  box-shadow: $box-shadow-default;
  padding: 24px ;
  gap: 30px;
  border-radius: 8px;
  background-color: #fff;
  max-width: 1000px;


  @media(max-width: $mobile-width){
    flex-direction: column;
    padding:16px;
    gap:0px;
  }

  .chart-column{
    width:100%;
  }

  .column-title{
    font-size: 14px;
    line-height:18px;
    margin-bottom:24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media(max-width:$mobile-width){
      font-size:16px;
      line-height: 19px;
      margin-bottom:20px;
    }

    .column-title-realtime{
      display: flex;
      align-items: center;
      gap:12px;
      .column-title-realtime-icon{
      }
      .realtime-icon{
        flex-shrink: 0;
        width:14px;
        height:14px;
        background-color: #1A73E81A;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
      .realtime-icon:before{
        content:'';
        display: block;
        width:6px;
        height:6px;
        background-color: #1A73E8;
        border-radius: 50%;
      }
    }

    .column-title--bold{
      font-weight: 600;
    }
    .column-title--muted{
      font-size:10px;
      line-height:12px;
      color:#00000080;
      text-transform: uppercase;
    }

    .column-title-left{
      display: flex;
      align-items: center;
      gap:10px;
      &.column-title-left--short-gap{
        gap:5px;
      }
      .column-title-period-label{
        display: none;
      }
      @media(max-width: $mobile-width){
        .column-title-period-wrap{
          display: flex;
          justify-content: space-between;
          font-size:14px;
          width:100%;
        }
        .column-title-period-label{
          display:block;
        }
      }

      @media(max-width: $mobile-width){
        flex-direction: column;
        width:100%;
        align-items: flex-start;
      }

    }
    .column-title-right{
      @media(max-width:$mobile-width){
        display: none;
      }
    }

  }

  .realtime-column{
    flex-shrink: 0;
    width:300px;
    @media(min-width:$mobile-width-for-min-width) and (max-width: 1050px){
      width:160px;
    }
    @media(min-width:1050px) and (max-width: 1100px){
      width:200px;
    }
    @media(min-width:1100px) and (max-width: 1150px){
      width:250px;
    }
    @media(min-width:1150px){
      width:300px;
    }

    @media(max-width:$mobile-width){
      width:100%;
      max-width:100%;
      .column-title-left{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap:5px;
      }
    }
  }

  .chart{
    .chart-inner{
      width:100%;
      height:166px;
    }
  }

  .realtime{
    .realtime__chart{
      height:150px;
    }
  }

  @media(max-width: $mobile-width){
    > div{
      margin-bottom:20px;
      padding-bottom:20px;
      border-bottom:1px solid #D6E3EF80;
      &:last-child{
        padding-bottom:0px;
        border-bottom:0px;
        margin-bottom:0px;
      }
    }
  }

}

</style>