<template>
  <div class="recommendation-top-100 rt100">

    <div class="rt100__non-top" v-if="status === 1">
      <recommendation-keyword degree="danger">
        <template v-slot:text>
          <div class="rt100__non-top-message-wrap">
            <div class="rt100__non-top-message">
              Not in TOP 100
            </div>
            <div class="rt100__non-top-recheck" @click="check">
              <div class="rt100__non-top-recheck-icon-wrap">
                <v-icon-svg name="reload-mini" class="rt100__non-top-recheck-icon"/>
              </div>
              <div class="rt100__non-top-recheck-label">Сheck now</div>
            </div>
          </div>
        </template>
      </recommendation-keyword>
    </div>

    <div class="rt100__loading" v-if="status === 2">
      <v-loader-ship color-line-one="#1C7EE080" color-line-two="#1C7EE0" class="rt100__loading-icon"/>
    </div>

    <div class="rt100__support" v-if="status === 3">
      <recommendation-keyword :has-tooltip="true" degree="danger">
        <template v-slot:text>
          <span class="rt100__support-message">
            Verified: Not in TOP 100
          </span>
        </template>
        <template v-slot:tooltip>
          <div class="rt100__support-tooltip">
            <div class="rt100__support-tooltip-message">
              We verified that your site not in Google Top 100. If you have any questions please connect support
            </div>
            <v-button-support class="rt100__support-tooltip-button" @click="chatSupportShowMessage('Hello, my site is not in the top 100. Please assist me.')"/>
          </div>

        </template>
      </recommendation-keyword>
    </div>

  </div>
</template>

<script setup>

import {computed, defineProps, getCurrentInstance, onMounted} from 'vue';
import {useSeoClicksKeyword} from '@app-vue/stores/useSeoClicks.js'
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import VLoaderShip from "@app-vue/components/Base/VLoaderShip.vue";
import RecommendationKeyword from "@app-vue/components/Blocks/RecommendationKeyword.vue";
import VButtonSupport from "@app-vue/components/Base/VButtonSupport.vue";
import {chatSupportShowMessage} from '@app-vue/utils/chatWithSupport.js';
import {isArray} from "lodash";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const props = defineProps({
  /**
   * @type {Array|String|Number}
   */
  keywordId: {},
  projectId: {}
});

const keywordsIds = computed(() => {
  if(isArray(props.keywordId)){
    return props.keywordId;
  }else{
    return [props.keywordId];
  }
});

const status = computed(() => {
  let statuses = [];
  keywordsIds.value.forEach((keywordId) => {
    switch(useSeoClicksKeyword(props.projectId, keywordId).statusRecommendationTop100){
      case 'non-top-100' : statuses.push(1); break;
      case 'loading' : statuses.push(2); break;
      case 'support' : statuses.push(3); break;
    }
  })
  return statuses.length > 0 ? Math.min(...statuses) : 0;
});

/** Запускаю проверку top100 у всех keywordsIds, если таковые не top-100 */
const check = () => {
  keywordsIds.value.forEach((keywordId) => {
    let useKeyword = useSeoClicksKeyword(props.projectId, keywordId);
    if(useKeyword.statusRecommendationTop100 === 'non-top-100'){
      useKeyword.startPositionUpdating().then(([status]) => {
        if(status){
          useKeyword.startIntervalCheckPositionUpdating();
        }
      });
    }
  });
}

onMounted(() => {
  keywordsIds.value.forEach((keywordId) => {
    let useKeyword = useSeoClicksKeyword(props.projectId, keywordId);
    if(useKeyword.statusRecommendationTop100 === 'loading'){
      useKeyword.startIntervalCheckPositionUpdating();
    }
  });
});

</script>

<style scoped lang="scss">
.recommendation-top-100.rt100{
  .rt100__non-top{

  }

  .rt100__loading{
    min-width:230px;
    .rt100__loading-icon{
      width:18px;
      height:18px;
    }
  }

  .rt100__support{
    .rt100__support-message{
      color: #3F4C61;
    }
  }

}

.rt100__non-top-message-wrap{
  display: flex;
  align-items: flex-start;
  gap:4px;
  flex-direction: column;
  font-weight: 400;

  .rt100__non-top-message{
    color: #3F4C61;
    white-space: nowrap;
    font-size:13px;
  }
  .rt100__non-top-recheck{
    display:flex;
    align-items: center;
    gap:6px;
    color: $color-link;
    cursor: pointer;

    .rt100__non-top-recheck-icon-wrap{
      :deep(path){
        stroke: $color-link
      }
    }
    .rt100__non-top-recheck-label{
      white-space: nowrap;
      font-size: 13px;
    }

    &:hover{
      color:$color-link-hover;
      :deep(.rt100__non-top-recheck-icon path){
        stroke:$color-link-hover;
      }
    }

  }

}

.rt100__support-tooltip{
  .rt100__support-tooltip-message{
    margin-bottom:13px;
  }
  .rt100__support-tooltip-button{

  }
}
</style>