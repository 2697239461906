<template>
  <div class="lb-project-demo-bar" :class="mainClass">
    <template v-if="useProject.demo_status !== 'expired'">

      <div class="title">DEMO</div>
      <v-pulsating-bar
          :value="useProject.demoReadinessPercent"
          :type-animation="useProject.demo_status !== 'delivered' ? 'snake' : null"
      >

          <div class="bar-inner" v-if="isCompact && useProject.demo_status !== 'delivered'">
            <div class="left">
              Demo: {{useProject.demoStatusName}} {{leftTimeCurrentStage}}
            </div>
            <div class="right">
              <span class="time">{{leftTime}}</span>
            </div>
          </div>

        <div class="bar-inner" v-if="isCompact && useProject.demo_status === 'delivered'">
          <div class="left">
            <span>
              {{isMobile ? 'Demo' : ''}} {{useProject.demoStatusName}}
              <v-icon-svg name="like2"/>
            </span>
          </div>
        </div>

          <div class="bar-inner" v-if="useProject.demo_status !== 'delivered' && !isCompact">
            <div class="left">
              {{isMobile ? 'Demo' : ''}}
              {{useProject.demoStatusName}}
              <span class="time">{{leftTimeCurrentStage}}</span>
            </div>
            <div class="right">
            <span class="time">
              <v-icon-svg name="time-white" as-img v-if="!isMobile"/>
              {{leftTime}}
            </span>
            </div>
          </div>

          <div class="bar-inner delivered" v-else-if="useProject.demo_status === 'delivered' && !isCompact">
            <div class="left">
              <div class="delivered">
                {{isMobile ? 'Demo' : ''}} {{useProject.demoStatusName}}
                <v-icon-svg name="like2"/>
              </div>
              <v-link
                  v-if="useProject.demoOrderHasLink"
                  class="link"
                  :href="useProject.demoOrderResultUrl"
                  target="_blank"
                  is-outer
              >
                {{useProject.demoOrderResultUrlShort}}
              </v-link>
            </div>
            <div class="right" v-if="useProject.demoOrder?.id">
              <span>Rate the article</span>
              <lb-order-review
                  :project-uniquekey="projectUniquekey"
                  :order-id="useProject.demoOrder?.id"
                  color-stars="white-on-blue"
                  :has-edit="false"
              />
            </div>
          </div>

      </v-pulsating-bar>

    </template>

    <template v-else-if="useProject.demo_status === 'expired'">
      <span class="expired-title">
        Demo expired
      </span>
      <span class="expired-subtitle" v-if="!isCompact">
        If there hasn’t been a purchase within 7 days after the delivery, we delete the free Backlink.
      </span>
    </template>

  </div>
</template>

<script setup>

import {computed, getCurrentInstance} from "vue";
import VPulsatingBar from "@app-vue/components/Base/VPulsatingBar.vue";
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import {useLinkBuildProject} from "@app-vue/stores/useLinkBuilding";
import VLink from "@app-vue/components/Base/VLink.vue";
import LbOrderReview from "@app-vue/components/LinkBuilding/LbOrderReview.vue";
import {convertSecondsToTime, pluralize} from "@app-vue/utils/utils";
import {padStart} from "lodash";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const props = defineProps({
  projectUniquekey: {},
  isCompact:{default:false,type:Boolean},
});

const useProject = useLinkBuildProject(props.projectUniquekey);

const leftTime = computed(() => {
  if(useProject.demoLeftTimeSeconds === null){
    if(isMobile.value){
      return `--hr:--m left`;
    }else{
      return `--hr:--min left`;
    }
  }
  let timeData = convertSecondsToTime(useProject.demoLeftTimeMinutes*60);

  let formattedMinutes;
  if(timeData.hours > 0){
    formattedMinutes = padStart(timeData.minutes, 2,0);
  }else{
    formattedMinutes = timeData.minutes;
  }

  if(isMobile.value){
    if(timeData.hours > 0){
      return `~${timeData.hours}hr:${formattedMinutes}m left`;
    }else{
      return `~${formattedMinutes}min left`;
    }
  }else{
    if(timeData.hours > 0){
      return `~${timeData.hours}hr:${formattedMinutes}min left`;
    }else{
      return `~${formattedMinutes}min left`;
    }
  }
});

const leftTimeCurrentStage = computed(() => {
  if(props.isCompact){
    return `~${useProject.demoLeftTimeCurrentStageMinutes} min`;
  }else{
    return `approximately ${useProject.demoLeftTimeCurrentStageMinutes} ${pluralize('minute', useProject.demoLeftTimeCurrentStageMinutes)}`;
  }
});

const mainClass = computed(() => {
  let response = [];
  response.push('is-'+useProject.demo_status);
  if(props.isCompact){
    response.push('is-compact');
  }
  return response;
});

</script>

<style lang="scss" scoped>
.lb-project-demo-bar{
  @include flex(row,space-between,center,23px);
  width:100%;
  background:#25587D;
  padding:13px 32.5px;
  border-radius:8px;

  @media(max-width: 767px){
    padding:0px;
    border-radius:4px;
  }

  &.is-expired{
    @include flex(column,start,stretch,2px);
    background:#C70B0B;

    @media(max-width:767px){
      padding:13px 12px;
    }

    .expired-title{
      font-size: 18px;
      font-weight: 600;
      line-height: 18px;
      color:#fff;
      text-transform:uppercase;
      @media(max-width:767px){
        font-size: 14px;
        text-transform:initial;
      }
    }
    .expired-subtitle{
      color:#fff;
    }

  }

  .title{
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    color:#FFFFFF;
    flex-shrink:0;
    @media(max-width:767px){
      display:none;
    }
  }

  .bar-inner{
    @include flex(row,between,center,10px);
    width:100%;
    color:#fff;
    font-size:14px;
    font-weight:400;
    height:64px;
    padding:0px 20px;

    @media(max-width: 767px){
      height:50px;
      padding:0px 12px;
    }

    .left{
      @include flex(row,start,center,5px);
      font-size:14px;
      line-height:18px;
      span{
        @include flex(row,start,center,5px);
      }
      @media(max-width:767px){
        @include flex(column,center,start, 0px);
        .time{
          margin-top:-1px;
          line-height:18px;
        }
      }
    }
    .right{
      @include flex(column,start,end,1px);
      span{
        opacity:0.7;
      }
    }
    .time{
      opacity:0.5;
      font-size:12px;
      line-height:18px;
      font-weight:400;
    }
    .delivered{
      @include flex(row,start,start,6px);
      line-height:18px;
      font-weight:700;
      @media(max-width: 767px){
        font-weight:400;
      }
    }
    .link{
      color:#fff;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width:100%;
    }


    &.delivered{
      .left{
        @include flex(column,start,start,2px);
      }
    }

  }

  &.is-delivered{
    .bar-inner{
      @include flex(column,start,stretch,2px);
      height:initial;
      padding-top:13px;
      padding-bottom:13px;
      .right{
        @include flex(row,start,center,5px);
      }
    }
  }

  &.is-compact{
    @include flex(row,start,stretch,0);
    padding:0px;
    border-radius: 4px;
    height:40px;
    .title{
      display:none;
    }
    .expired-title{
      @include flex(row,start,center,0);
      padding:0px 10px;
      text-transform:initial;
    }
    .bar-inner {
      @include flex(row,between,center,10px);
      padding:0px 10px;
      height:40px;
      .right{
        font:$font-mini-12-regu;
        color:$white-50;
      }
      &.executed{
        @include flex(row,between,center,0px);
      }
    }
  }

}
</style>