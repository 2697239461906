<template>

  <div class="row g-0">
    <div class="fastspring-payment">
      <div class="checkout-block-payment" data-payment-method="fastspring">
        <div class="row g-0 justify-content-between align-items-center">
          <div class="col-12">
            <div class="fastspring-iframe-wrap hidden">
              <div id="fsc-embedded-checkout-container" class="position-relative"></div>
            </div>

            <div class="payment-options__button">
              <div class="transaction-save-icons">
                <img :src="'/img/payments/payment-paypal.svg'" style="width: auto; height: 28px;" alt="">
                <img :src="'/img/payments/payment-visa.svg'" alt="">
                <img :src="'/img/payments/payment-mastercard.svg'" alt="">
                <img :src="'/img/payments/payment-american-express.svg'" alt="">
              </div>

              <button class="button button-main button_size_middle button_bg_green w-100"
                      type="button"
                      data-ajax-action="pay-now"
              >Pay Now</button>
            </div>
          </div>

          <div class="loading-block">
            <div class="lottie-animation"></div>
          </div>
        </div>
      </div>

      <div class="checkout-block-info" data-payment-method="fastspring">
        <div class="checkout-block__error">
          <div class="row justify-content-center align-items-center">
            <div class="col-12 text-center">
              <div class="checkout-block__error-icon">
                <img :src="'/img/icons/icon_notification-error.svg'" alt="">
              </div>
            </div>
            <div class="col-12 text-center">
              <div class="checkout-block__error-title">
                Payment has been declined
              </div>
            </div>
          </div>

          <button class="button button-main"
                  type="button"
                  data-button-action="repeat"
          >Repeat payment</button>
        </div>
      </div>
    </div>
  </div>

  <transition name="fade" mode="out-in">
    <div v-if="useCard.orderIsPaid">
      <div class="crypto-payment__success" style="">
        <div class="row justify-content-center align-items-center">
          <div class="col-12 text-center">
            <div class="crypto-payment__success-icon">
              <img :src="'/img/icons/icon_notification-success.svg'" alt="">
            </div>
          </div>
          <div class="col-12 text-center">
            <div class="crypto-payment__success-title">
              Payment has been successful
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>

</template>

<script setup>
import {onMounted, onUnmounted, ref, watch} from 'vue';
import {useUserInfo} from '@app-vue/stores/useStaticData.js';
import {useBillingCart} from "@app-vue/stores/useBilling";
import {usePayByCard} from '@app-vue/stores/usePayByCard.js';
import {initCartPaymentMethodFastspringScripts} from '@app-frontend/panel/cart/payment_methods/fastspring.js';

const props = defineProps({
  projectUniquekey: {},
});

const userInfo = useUserInfo();
const useCard = usePayByCard(props.projectUniquekey);

watch(() => useBillingCart().totalPrice, () => {
  const form = document.getElementById('payment-form');
  form.hidden = true;
});

userInfo.download();

const isOnMounted = ref(false);
onMounted(() => {
  initCartPaymentMethodFastspringScripts();

  isOnMounted.value = true;
});

const checkTimer = setInterval(() => {
  if(useCard.orderIsPending){
    useCard.check();
  }
},2000);

onUnmounted(() => {
  clearInterval(checkTimer);
})
</script>

<style scoped lang="scss">
.form-payment__error {
  width: 100%;
  margin-bottom: 24px;
  padding: 24px;
  border: 1px solid #5b616e33;
  border-radius: 4px;

  .form-payment__error-icon {
    width: 96px;
    height: 96px;
    margin: 0 auto 24px auto;

    img {
      width: 96px;
      height: 96px;
    }
  }

  .form-payment__error-title {
    margin-bottom: 24px;
    font-size: 22px;
    font-weight: 600;
    color: $black-1;
  }

  .form-payment__error-info {
    font-size: 16px;
    font-weight: 400;
    color: $black-1;
  }
}

.form-payment__success {
  width: 100%;
  margin-bottom: 24px;
  padding: 24px;
  border: 1px solid #5b616e33;
  border-radius: 4px;

  .form-payment__success-icon {
    width: 96px;
    height: 96px;
    margin: 0 auto 24px auto;

    img {
      width: 96px;
      height: 96px;
    }
  }

  .form-payment__success-title {
    margin-bottom: 24px;
    font-size: 22px;
    font-weight: 600;
    color: $black-1;
  }

  .form-payment__success-info {
    font-size: 16px;
    font-weight: 400;
    color: $black-1;
  }
}
</style>