<template>
  <div class="sc-form-group-keywords" :class="mainClass" ref="wrap">
    <div ref="formGroupKeywords" class="selected-keywords-wrap">
      <v-form-group class="selected-keywords-form-group">
        <v-form-tagify
            v-model="useSuggestKeywords().selectedKeywords"
            :suggested-tags="useSuggestKeywords().remainingSuggestedKeywords"
            :current-country-code="useSuggestKeywords().selectedLocation?.value"
            :current-state-code="useSuggestKeywords().selectedState?.value"
            class="selected-keywords"
            @add-tag="useSuggestKeywords().addSelectedKeyword"
            @remove-tag="useSuggestKeywords().removeSelectedKeyword"
            :disabled="disabled"
            :is-error="!!errors"
        />
        <v-form-field-list-errors :errors="errors"/>
      </v-form-group>
    </div>
    <div class="suggested-keywords-wrap">

      <div class="suggested-keywords-wrap__top">
        <div v-show="showSuggestedKeywords" class="suggested-keywords-title" v-auto-tooltip data-name="suggested-keywords">Suggested Keywords</div>

        <v-sc-select-geo default-country-no-geo
                         :disabled="disabled"
                         v-model:country="countrySelected"
                         v-model:state="stateSelected"
                         @update-country="updateSuggestedKeywords"/>

        <div v-if="!useSuggestKeywords().selectedLocationIsNoGeo" class="suggested-keywords-price">
          <div class="suggested-keywords-price__icons">
            <v-icon-flag :code="useSuggestKeywords().selectedLocation?.value" :has-tooltip="false" :state-code="useSuggestKeywords()?.selectedState?.value"/>
          </div>
          <span v-if="!useSuggestKeywords().selectedState">(+1 credit per click)</span>
          <span v-else>(+1.5 credit per click)</span>
        </div>

      </div>

      <div class="suggested-keywords-wrap__bottom" v-show="showSuggestedKeywords">

        <list-tags
            v-if="useSuggestKeywords().remainingSuggestedKeywords.length > 0 && !useSuggestKeywords().loading"
            ref="suggestKeywordsList"
            :list-tags="useSuggestKeywords().remainingSuggestedKeywords"
            @click-tag="useSuggestKeywords().addSelectedKeyword"
            :disabled="disabled"
        />

        <div class="loading" v-if="useSuggestKeywords().loading">
          <v-loader-ship class="loading-icon" color-line-one="#1C7EE080" color-line-two="#1C7EE0"/>
          <span class="loading-text">Loading keywords</span>
        </div>

        <div v-if="useSuggestKeywords().keywordsNotFound && !useSuggestKeywords().loading" class="message">
          Keywords not found
        </div>

      </div>

    </div>
  </div>

</template>

<script setup>

import {computed, defineProps, ref, watch} from 'vue';
import VIconFlag from "@app-vue/components/Base/VIconFlag.vue";
import ListTags from "@app-vue/components/Blocks/ListTags.vue";
import VFormTagify from "@app-vue/components/VForm/VFormTagify.vue";
import {useSuggestKeywords} from "@app-vue/stores/useSuggestKeywords.js";
import VLoaderShip from "@app-vue/components/Base/VLoaderShip.vue";
import VFormGroup from "@app-vue/components/VForm/VFormGroup.vue";
import VFormFieldListErrors from "@app-vue/components/VForm/VFormFieldListErrors.vue";
import {useStaticData} from "@app-vue/stores/useStaticData";
import VScSelectGeo from "@app-vue/components/Base/SeoClicks/VScSelectGeo.vue";

const props = defineProps({
  website: {default: null},
  errors: {default:''},
  disabled: {type:Boolean,default:false},
  searchEngineId: {},
  excludedProjectId: {default:null,},
});

const countrySelected = ref(useSuggestKeywords().selectedLocation);
const stateSelected = ref(useSuggestKeywords().selectedState);

watch(() => countrySelected.value, (countrySelected) => {
  useSuggestKeywords().$patch({selectedLocation: countrySelected});
});
watch(() => stateSelected.value, (stateSelected) => {
  useSuggestKeywords().$patch({selectedState: stateSelected});
});

watch(() => useSuggestKeywords().selectedLocation, (selectedLocation) => {
  countrySelected.value = selectedLocation;
});
watch(() => useSuggestKeywords().selectedState, (selectedState) => {
  stateSelected.value = selectedState;
});


watch(
    () => props.excludedProjectId,
    (excludedProjectId) => useSuggestKeywords().updateExcludedProjectId(excludedProjectId),
    {immediate:true},
);

const showSuggestedKeywords = computed(() => {
  let searchEngine = useStaticData().searchEngines.find((searchEngine) => props.searchEngineId == searchEngine.id);
  return searchEngine && searchEngine.showSuggestedKeywords;
});

const mainClass = computed(() => {
  let classes = [];
  if(props.disabled){
    classes.push('scfgk--disabled');
  }
  return classes;
});

const updateSuggestedKeywords = (option = null) => {
  let geoCode = option!== null ? option.value : null;
  if(showSuggestedKeywords.value){
    useSuggestKeywords().updateSuggestedKeywords(props.website, geoCode);
  }
}

watch(() => showSuggestedKeywords.value, (showSuggestedKeywords) => {
  if(showSuggestedKeywords){
    updateSuggestedKeywords();
  }
});

defineExpose({updateSuggestedKeywords});

</script>

<style lang="scss" scoped>
.sc-form-group-keywords{
  display:flex;
  gap:20px;
  align-items: stretch;
  @media(max-width: 767px){
    flex-direction:column;
  }
  .selected-keywords-wrap{
    width:50%;
    min-height:160px;
    display:flex;
    align-items: stretch;
    .selected-keywords-form-group{
      width:100%;
      display:flex;
      flex-direction:column;
      align-items:stretch;
      height:100%;
      min-height:160px;
      margin-bottom:0px;
      .selected-keywords{
        width:100%;
        height:100%;
        min-height:160px;
      }
    }
    @media(max-width: 767px){
      width:100%;
    }
  }
  .suggested-keywords-wrap{
    @include flex(column,start,stretch,20px);
    border-radius:4px;
    width:50%;
    background:$secondary-blue-5;
    padding:20px;
    @media(max-width: 767px){
      width:100%;
      padding:16px;
    }
    .suggested-keywords-wrap__top{
      @include flex(column,start,stretch,6px);
    }
    .suggested-keywords-wrap__bottom{
      @include flex(column,start,stretch,6px);
    }
  }
  .suggested-keywords-title{
    color:#384967;
    padding:3.5px 0px;
    @include flex(row,start,center,2px);
  }

  .suggested-keywords-price{
    @include flex(row,start,center,10px);
    padding-left:11px;
    color:#596C8F;
    font-size:13px;
    .suggested-keywords-price__icons{
      @include flex(row,start,center,4px);
    }
  }

  .loading{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .loading-icon{
      flex-shrink: 0;
      margin-right: 7px;
      width:20px;
      height:20px;
      margin-left:2px;
    }
    .loading-text{
      color: rgba(31, 41, 59, 0.5);
    }
  }

  .message{
    color:#7f7f7f;
  }


}
</style>