<template>
  <v-container class="lb-project" mobile-no-padding>

    <v-breadcrumbs :items="breadcrumbs" no-padding/>

    <bc-balance :credit-type="useExperiments().isLinkBuildingOneCredit ? CREDIT_TYPE_BACKLINK_V2 : CREDIT_TYPE_BACKLINK"/>

    <lb-project-head :project-uniquekey="projectUniquekey"  v-if="!linkBuildProjectStore.is_demo"/>

    <lb-project-head-demo :project-uniquekey="projectUniquekey"  v-if="linkBuildProjectStore.is_demo"/>

    <v-white-container class="project-content"
                       v-if="!linkBuildProjectStore.is_demo"
                       :class="{'is-deleted': linkBuildProjectStore.is_deleted}">

      <lb-step-cart :project-uniquekey="projectUniquekey"/>

      <lb-project-orders-toggle v-if="isDesktop" v-show="computedShowOrdersToggle" v-model="triggerShowOrders"/>

      <lb-orders v-if="(isDesktop || linkBuildProjectStore.orderCreationStatusIsStart)" v-show="computedShowOrders" :project-uniquekey="projectUniquekey"/>

    </v-white-container>

    <v-white-container v-if="isMobile" v-show="computedShowOrdersToggle" only-padding style="padding-top:0;padding-bottom:0">
      <lb-project-orders-toggle v-model="triggerShowOrders"/>
    </v-white-container>

    <v-white-container v-if="isMobile && !linkBuildProjectStore.orderCreationStatusIsStart" v-show="computedShowOrders">
      <lb-orders :project-uniquekey="projectUniquekey"/>
    </v-white-container>

  </v-container>
</template>

<script setup>

import VBreadcrumbs from "@app-vue/components/Base/VBreadcrumbs.vue";
import VContainer from "@app-vue/components/Base/VContainer.vue";
import {useRoutes} from "@app-vue/stores/useRoutes";
import LbProjectHead from "@app-vue/components/LinkBuilding/LbProjectHead.vue";
import VWhiteContainer from "@app-vue/components/Base/VWhiteContainer.vue";
import {useRecsStore} from "@app-vue/stores/useRecsStore";
import {
  useAutoRenewals,
  useBacklinksRecommendations,
  useLinkBuildProject,
  useLinkBuildProjectOrders
} from "@app-vue/stores/useLinkBuilding";
import LbOrders from "@app-vue/components/LinkBuilding/LbOrders.vue";
import {computed, getCurrentInstance, ref} from "vue";
import {CREDIT_TYPE_BACKLINK, CREDIT_TYPE_BACKLINK_V2} from "@app-vue/stores/useBilling";
import BcBalance from "@app-vue/components/BuyCredits/BcBalance.vue";
import LbProjectHeadDemo from "@app-vue/components/LinkBuilding/LbProjectHeadDemo.vue";
import {useExperiments} from "@app-vue/stores/useExperiments";
import LbStepCart from "@app-vue/components/LinkBuilding/LbStepCart.vue";
import LbProjectOrdersToggle from "@app-vue/components/LinkBuilding/LbProjectOrdersToggle.vue";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);
const isDesktop = computed(() => !proxy.$isMobile.value);

const projectUniquekey = useRoutes().getProjectKey();
const linkBuildOrdersStore = useLinkBuildProjectOrders(projectUniquekey);

const linkBuildProjectStore = useLinkBuildProject(projectUniquekey);

const triggerShowOrders = ref(false);

const computedShowOrders = computed(() => {
  return (
      linkBuildProjectStore.orderCreationStatusIsStart
      || (linkBuildProjectStore.orderCreationStatusIsCreateOrder && triggerShowOrders.value && hasOrders.value)
  );
});

const computedShowOrdersToggle = computed(() => {
  return linkBuildProjectStore.orderCreationStatusIsCreateOrder && hasOrders.value;
});

const downloadProjectAndOrders = async () => {

  let [[projectStatus,projectResponse],[ordersStatus,ordersResponse]] = await Promise.all([
    linkBuildProjectStore.downloadProject(),
    linkBuildOrdersStore.download(),
  ]);
  if(!projectStatus || !ordersStatus){
    downloadProjectAndOrders();
  }else if(
      (
          projectResponse.is_demo
          && projectResponse.demo_status!== 'expired'
          && projectResponse.demo_status!== 'delivered'
      )
      ||
      (
          projectResponse.is_demo
          && (projectResponse.demo_status=== 'expired' || projectResponse.demo_status=== 'delivered')
          && !linkBuildProjectStore.demoOrderHasLink
      )
  ){
    setTimeout(() => {
      downloadProjectAndOrders();
    },2000);
  }

}
downloadProjectAndOrders();

linkBuildProjectStore.updateTestAnalytics();
useAutoRenewals(projectUniquekey).download();
const backlinksRecommendations = useBacklinksRecommendations(projectUniquekey)

useRecsStore(projectUniquekey).fetchUntilNotLoaded().then((iterationNumber) => {
  if(iterationNumber > 1){
    backlinksRecommendations.download({forcedDownloadFromServer:true});
  }else{
    backlinksRecommendations.download();
  }
});

const breadcrumbs = computed(() => {
  let list = [];
  list.push({url: useRoutes().url('backlinks-project.list'), title: 'Backlinks Projects'});
  if(linkBuildProjectStore.name){
    list.push({title: linkBuildProjectStore.name});
  }else{
    list.push({title: 'Project'});
  }
  return list;
});

const hasOrders = computed(() => {
  return linkBuildOrdersStore.ordersManager().ordersSortedByCreatedAt.length > 0;
})

</script>

<style lang="scss" scoped>
.lb-project{
  display: flex;
  flex-direction:column;
  gap:30px;
  align-items: stretch;
  margin-bottom:100px;
  @media(max-width:767px){
    gap:20px;
    margin-bottom:100px;
  }

  .project-content{
    display:flex;
    flex-direction:column;
    align-items:stretch;
    gap:20px;
    &.is-deleted{
      position:relative;
      &:after{
        content:'';
        position:absolute;
        width:100%;
        height:100%;
        top:0px;
        left:0px;
        background:#25587D80;
        z-index:9999;
        border-radius:8px;
      }
    }
    @media(max-width:767px){
      gap:16px;
    }
  }

}
</style>