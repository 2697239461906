<template>
  <div class="v-form-input-number-edit-v2"
       :class="{'is-opened': isOpened, 'is-successful': isSuccessful, 'is-focus': isFocus, 'is-error': isError}">
    <input type="text" v-model="inputData" @focusin="onFocusIn" @focusout="onFocusOut"/>
    <transition name="fade">
      <v-icon-svg as-img as-block name="icon-ok-stroke" class="icon-ok" v-show="isSuccessful"/>
    </transition>
  </div>
</template>

<script setup>

import {computed, ref, watch} from "vue";
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";

const props = defineProps({
  value: {},
});

const emit = defineEmits(['save'])

const isSuccessfulVar = ref(false);
const isSuccessful = computed(() => isSuccessfulVar.value !== false);
const setIsSuccessful = () => isSuccessfulVar.value = Math.random();
const clearIsSuccessful = (id = null) => {
  console.log('clearIsSuccessful', id, isSuccessfulVar.value);
  if(id === null || isSuccessfulVar.value === id) isSuccessfulVar.value = false;
}

const isError = ref(false);
const isFocus = ref(false);

const inputData = ref(props.value);
watch(() => props.value, () => inputData.value = props.value);
const isDiffValue = computed(() => props.value !== inputData.value);

const isOpened = computed(() => {
  return isSuccessful.value || isFocus.value || isDiffValue.value;
});

watch(() => inputData.value, (inputDataForSave) => {
  if(inputDataForSave !== props.value){
    clearIsSuccessful();
    isError.value = false;
  }
  setTimeout(() => {
    if(inputDataForSave === inputData.value && props.value !== inputDataForSave){
      emit('save', inputDataForSave);
    }
  },800);
});

function onFocusIn(){
  isFocus.value = true;
}

function onFocusOut(){
  isFocus.value = false;
  if(isSuccessful.value === true){
    let successfulForClear = isSuccessfulVar.value;
    setTimeout(() => {
      clearIsSuccessful(successfulForClear);
    },3000);
  }
}

function saveWithSuccess(){
  setIsSuccessful();
  isError.value = false;
  if(!isFocus.value){
    let successfulForClear = isSuccessfulVar.value;
    setTimeout(() => {
      clearIsSuccessful(successfulForClear);
    },3000);
  }
}

function saveWithError(){
  clearIsSuccessful();
  isError.value = true;
}

function setContentEditable(value){
  inputData.value = value;
}

defineExpose({saveWithSuccess, saveWithError, setContentEditable});


</script>

<style lang="scss" scoped>
.v-form-input-number-edit-v2{
  @include flex(row,start,center);
  height:44px;
  border: 1px solid #E5ECF9;
  border-radius: 4px;
  background:#fff;
  transition-duration: 0.2s;
  position:relative;

  &.is-focus{
    border-color:$secondary-blue-100;
  }

  &.is-error{
    border-color:$optional-red_100;
  }

  &:hover, &.is-opened{
    //width:114px;
  }

  input{
    height:100%;
    padding:0px 12px;
    border:none;
    outline:none!important;
    width:100%;
    background-color: transparent;
    font:$font-14-semi;
  }

  .icon-ok{
    position:absolute;
    width:12px;
    height:12px;
    right:9px;
    &.fade-enter-from{
      opacity:0;
    }
    &.fade-enter-active{
      transition-duration: 0.2s;
    }
    &.fade-enter-to{
      opacity:1;
    }
    &.fade-leave-from{
      opacity: 1;
    }
    &.fade-leave-active{
      transition-duration: 0.2s;
    }
    &.fade-leave-to{
      opacity:0;
    }
  }

}
</style>