<template>
  <div class="wt-project-demo-bar" :class="mainClass">
    <div class="title">DEMO</div>
    <v-pulsating-bar
        :class="!useProject.demoCompleted && useProject.demoExpired ? 'pulsating-bar expired' : 'pulsating-bar'"
        :value="barValue"
        :type-animation="!useProject.demoCompleted && !useProject.demoExpired? 'snake' : null"
        :nearly-ready="nearlyReady"
    >

      <div v-if="!useProject.demoCompleted && useProject.demoExpired" class="bar-inner">
        <div class="left">
          {{!isMobile ? 'Expired' : 'Demo expired'}}
        </div>
        <div class="right">
        </div>
      </div>

      <div v-else-if="!useProject.demoCompleted" class="bar-inner">
        <div class="left">
          {{isCompact && !isMobile ? 'Demo: ' : ''}}
          Delivered {{useProject.demoCountClicks}}
          {{!isMobile && !isCompact ? pluralize('Hit', useProject.demoCountClicks) : ''}}
        </div>
        <div class="right">
          Will be delivered {{useProject.demoMaxCountClicks}} {{pluralize('Hit', useProject.demoMaxCountClicks)}}
        </div>
      </div>

      <div v-else class="bar-inner executed">
        <div class="left">
          {{!isMobile ? 'Completed' : 'Demo completed'}}
          <v-icon-svg name="like2"/>
        </div>
        <div class="right">
          Delivered {{useProject.demoMaxCountClicks}} {{pluralize('Hit', useProject.demoMaxCountClicks)}}
        </div>
      </div>

    </v-pulsating-bar>
  </div>
</template>

<script setup>

import {useWebsiteTrafficProject} from "../../stores/useWebsiteTraffic";
import {computed, getCurrentInstance} from "vue";
import VPulsatingBar from "../Base/VPulsatingBar.vue";
import VIconSvg from "../Base/VIconSvg.vue";
import {pluralize} from "../../utils/utils";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const props = defineProps({
  projectUniquekey: {},
  isCompact:{default:false,type:Boolean}
});

const useProject = useWebsiteTrafficProject(props.projectUniquekey);

const barValue = computed(() => {
  return useProject.demoCompleted ? 100 : useProject.demoCountClicks / useProject.demoMaxCountClicks * 100
});

const nearlyReady = computed(() => {
  return (useProject.demoMaxCountClicks - useProject.demoCountClicks) <= 1;
});

const mainClass = computed(() => {
  let response = [];
  if(props.isCompact){
    response.push('is-compact');
  }
  return response;
});

</script>

<style lang="scss" scoped>
.wt-project-demo-bar{
  @include flex(row,space-between,center,23px);
  width:100%;
  background:#25587D;
  padding:13px 32.5px;
  border-radius:8px;
  @media(max-width: 767px){
    padding:0px;
    border-radius:4px;
  }
  .title{
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    color:#FFFFFF;
    flex-shrink:0;
    @media(max-width:767px){
      display:none;
    }
  }

  .bar-inner{
    @include flex(row,between,center);
    width:100%;
    color:#fff;
    font-size:14px;
    font-weight:400;
    height:64px;
    padding:0px 20px;

    @media(max-width: 767px){
      height:50px;
      padding:0px 14px;
    }

    .right{
      @media(max-width:767px){
        font-size: 12px;
        color:#FFFFFF66;
      }
    }

    &.executed{
      @include flex(column,center,start,2px);
      .left{
        @include flex(row,start,start,6px);
        line-height:18px;
        font-weight:700;
        @media(max-width: 767px){
          font-weight:400;
        }
      }
    }

  }

  &.is-compact{
    @include flex(row,start,stretch,0);
    padding:0px;
    border-radius: 4px;
    height:40px;
    .title{
      display:none;
    }
    .bar-inner {
      padding:0px 10px;
      height:40px;
      .right{
        font:$font-mini-12-regu;
        color:$white-50;
      }
      &.executed{
        @include flex(row,between,center,0px);
      }
    }
    @media(max-width: 767px){
      .left{
        font:$font-mini-12-regu;
      }
    }
  }

}
</style>