<template>
  <div class="sc-project-keyword-mobile"
       :class="{'is-inactive':!useKeywordsGroup.is_active && !useKeywordsGroup.is_deleted, 'is-deleted': useKeywordsGroup.is_deleted}">

    <div class="header">

      <v-sc-keyword-title class="header__keyword"
                          :class="{'is-deleted': useKeywordsGroup.is_deleted}"
                          :project-id="projectId"
                          :keywords-ids="keywordsIds"
                          @open-modal="refModal.open()"/>

      <v-link v-if="useKeywordsGroup.is_deleted" text="Restore" @click="restoreKeyword()"/>

      <div ref="refEllipsisMenuAnchor" v-if="!useKeywordsGroup.is_deleted">
        <v-icon-svg class="ellipsis-menu__icon"
                    name="ellipsis-menu-v2"
                    @click="refContextMenu.toggle()"/>
      </div>


    </div>

    <div class="deleted-tag"
         v-if="useKeywordsGroup.is_deleted"
         v-text="'Deleted'"/>

    <chart-seo-clicks
        v-if="!useKeywordsGroup.is_deleted"
        :chart="useKeywordsGroup.chart_data"
        class="chart"
        :scale-hover="false"
        is-blue
        is-blue-background
    />

    <div class="footer" v-if="!useKeywordsGroup.is_deleted">

      <div class="footer__title">DAILY LIMIT</div>

      <div class="footer__content">
        <v-form-input-counter-edit class="footer__daily-limit"
                                   ref="refInputDailyLimit"
                                   :min="1"
                                   v-model="dailyLimit"
                                   :disabled="updateDailyLimitLoading"
                                   @save="(value) => saveDailyLimit(value)"/>
        <div class="footer__recommendations" v-if="refInputDailyLimit?.currentState !== 'expanded'">
          <recommendation-top100
              v-if="!useKeywordsGroup.is_deleted && !useKeywordsGroup.is_top100"
              class="footer__recommendation-top-100"
              :keyword-id="useKeywordsGroup.keywordsIds"
              :project-id="projectId"
          />
          <recommendation-daily-limit
              v-else-if="useKeywordsGroup.hasRecomDaily"
              :top1="useKeywordsGroup.recom_daily_top1"
              :top3="useKeywordsGroup.recom_daily_top3"
              :top10="useKeywordsGroup.recom_daily_top10"
              @choose-daily="chooseDaily"
          />
        </div>
      </div>

    </div>

    <v-tooltip v-if="!useKeywordsGroup.is_deleted" :get-linked-block="() => refEllipsisMenuAnchor"
               :offset="[0,0]"
               reset-tooltip
               ref="refContextMenu"
               placement="bottom-end"
               :btn-close-on="false">
      <template #content>
        <div class="context-menu">

          <div class="context-menu__item"
               @click="() => {refContextMenu.hide();refModal.open()}"
               v-text="'Change keyword'"/>

          <div class="context-menu__item"
               v-if="useKeywordsGroup.is_active"
               @click="deactivateKeyword"
               v-text="'Disable Keyword'"/>

          <div class="context-menu__item"
               v-if="!useKeywordsGroup.is_active"
               @click="activateKeyword"
               v-text="'Enable Keyword'"/>

          <div class="context-menu__item context-menu__item--is-red context-menu__item--is-cutoff"
               @click="() => {refContextMenu.hide();deleteModalConfirm.confirm()}"
               v-text="'Delete Keyword'"/>

        </div>
      </template>
    </v-tooltip>

    <v-modal-confirm ref="deleteModalConfirm" @confirm="deleteKeyword">
      <template v-slot:title>
        Delete keyword
      </template>
      <template v-slot:description>
        Do you really want to delete a keyword ? <br>Keyword will be lost
      </template>
    </v-modal-confirm>

    <v-sheet-modal ref="refModal" :height="false" type-modal-close="inner">
      <template #content>
        <sc-project-keyword-group-tooltip-settings :project-id="projectId" :keywords-ids="keywordsIds"/>
      </template>
    </v-sheet-modal>

  </div>
</template>

<script setup>
import {useSeoClicksKeywordsGroup} from "@app-vue/stores/useSeoClicks";
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import ChartSeoClicks from "@app-vue/components/Chart/ChartSeoClicks.vue";
import RecommendationDailyLimit from "@app-vue/components/Blocks/RecommendationDailyLimit.vue";
import {ref, watch} from "vue";
import VFormInputCounterEdit from "@app-vue/components/VForm/VFormInputCounterEdit.vue";
import VTooltip from "@app-vue/components/Base/VTooltip.vue";
import VLink from "@app-vue/components/Base/VLink.vue";
import VModalConfirm from "@app-vue/components/Base/VModalConfirm.vue";
import RecommendationTop100 from "@app-vue/components/Blocks/RecommendationTop100.vue";
import VScKeywordTitle from "@app-vue/components/Base/SeoClicks/VScKeywordTitle.vue";
import VSheetModal from "@app-vue/components/Base/VSheetModal.vue";
import ScProjectKeywordGroupTooltipSettings from "@app-vue/components/SeoClicks/ScProjectKeywordGroupTooltipSettings.vue";

const props = defineProps({
  projectId:{},
  keywordsIds:{},
});

const useKeywordsGroup = useSeoClicksKeywordsGroup(props.projectId,props.keywordsIds);

const refInputDailyLimit = ref();
const refContextMenu = ref();
const refEllipsisMenuAnchor = ref();
const deleteModalConfirm = ref();
const refModal = ref();

const chooseDaily = (number) => {
  refInputDailyLimit.value.setContentEditable(number);
}

const dailyLimit = ref(1);
const updateDailyLimitLoading = ref(false);
watch(() => useKeywordsGroup.max_clicks_per_day, (value) => {
  dailyLimit.value = value;
},{immediate:true});

watch(() => dailyLimit.value, (value) => {
  setTimeout(() => {
    updateDailyLimit(value);
  },700);
});

function updateDailyLimit(value){
  if(parseInt(value) !== parseInt(dailyLimit.value)){
    return;
  }
  if(parseInt(value) !== parseInt(useKeywordsGroup.max_clicks_per_day)){
    updateDailyLimitLoading.value = true;
    useKeywordsGroup.updateDailyLimit(value).then(() => {
      updateDailyLimitLoading.value = false;
      dailyLimit.value = useKeywordsGroup.max_clicks_per_day;
    });
  }
}

function saveDailyLimit(value){
  useKeywordsGroup.updateDailyLimit(value).then(([status]) => {
    dailyLimit.value = useKeywordsGroup.max_clicks_per_day;
    if(status){
      refInputDailyLimit.value.saveWithSuccess();
    }else{
      refInputDailyLimit.value.saveWithError();
    }
  });
}

const restoreKeyword = async () => {
  await useKeywordsGroup.updateIsDeleted(false);
}

const deleteKeyword = async () => {

  let [status,message] = await useKeywordsGroup.updateIsDeleted(true);
  if(status){
    deleteModalConfirm.value.closeWithSuccess();
  }else{
    deleteModalConfirm.value.closeWithError(message);
  }

}

const activateKeyword = async () => {
  refContextMenu.value.hide();
  await useKeywordsGroup.updateIsActive(true);
}

const deactivateKeyword = async () => {
  refContextMenu.value.hide();
  await useKeywordsGroup.updateIsActive(false);
}

</script>

<style lang="scss" scoped>
.sc-project-keyword-mobile{
  @include flex(column,start,stretch,15px);
  padding:12px 12px;
  border:1px solid #E8F2FC;
  border-radius:4px;

  &.is-deleted{
    gap:3px;
  }

  &.is-inactive{
    background-color: $dark-3;
    .chart{
      opacity:0.5;
    }
    .footer{
      opacity:0.5;
    }
    .footer__recommendation-top-100{
      position:relative;
      &:after{
        content:'';
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
      }
    }
  }

  .header{
    @include flex(row,between,start,15px);
    .header__keyword{
      width:calc(100% - 20px - 15px);
      &.is-deleted{
        width:calc(100% - 50px - 15px);
      }
    }
  }

  .footer{
    @include flex(column,start,stretch, 7px);
    .footer__title{
      color:$dark-40;
      font:$font-mini-10-regu;
    }
    .footer__content{
      @include flex(row,between,center,16px);
    }
    .footer__daily-limit{
      flex-shrink:0;
    }
    .footer__recommendations{
      width:100%;
    }
  }

  .deleted-tag{
    font:$font-mini-12-regu;
    color:$dark-30;
  }

  .chart{
    width:100%;
    height:44px;
  }

}
.context-menu{
  @include flex(column,start,stretch,0px);
  background:#fff;
  box-shadow: $box-shadow-default;
  width:150px;
  border-radius:8px;
  .context-menu__item{
    height:44px;
    padding:0px 12px;
    color:#3F4C61;
    @include flex(row,start,center);
    &.context-menu__item--is-red{
      color:$optional-red_120;
    }
    &.context-menu__item--is-cutoff{
      border-top:1px solid #F3F4F5;
    }
  }
}
</style>