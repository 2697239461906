import {defineStore} from "pinia";
import {ApiResource} from "@app-vue/plugins/ApiExtends";
import {useLinkBuildCart, useLinkBuildProject} from "@app-vue/stores/useLinkBuilding";

export const usePayBankTransfer = (projectUniquekey = null) => defineStore(`pay-by-bank-transfer-${projectUniquekey}`, {

    state: () => {
        return {
            projectUniquekey: projectUniquekey,
            windowActive: false,
            bankDetails: [],
            orders: [],
            totalAmount: {},
            userId: null,
            orderId: null,
        }
    },

    getters: {

        /**
         * Есть ли в текущем state order ?
         */
        hasOrder: (state) => {
            return state.bankDetails !== null;
        },

    },

    actions: {

        /**
         * передаем plan_id, добавляем новый ордер, сохраняем его данные в state
         */
        async pay(promoQuantity = 0){

            let options = {
                promoQuantity: promoQuantity,
            }

            if(this.projectUniquekey){
                options.plan_id = useLinkBuildCart(this.projectUniquekey).planOption.id;
                options.quantity = useLinkBuildCart(this.projectUniquekey).packageQuantity;
                options.backlink_project_id = useLinkBuildProject(this.projectUniquekey).id;
            }

            let resource = new ApiResource({
                url: '/api/v1/cart/pay/bank',
                method:'GET',
                params: options
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                let data = {
                    bankDetails:resource.data.bankDetails,
                    orders:resource.data.orders,
                    totalAmount:resource.data.totalAmount,
                    userId:resource.data.userId,
                    orderId:resource.data.orderId,
                }
                this.$patch(data);

                return [true];
            }else{
                return [false, resource.errorMessage]
            }

        },

        /**
         * Открыть окно оплаты
         */
        openWindow(){
            this.$patch({windowActive: true});
        },

        /**
         * Закрыть окно оплаты
         */
        closeWindow(){
            this.$patch({windowActive: false});
        },

        /**
         * Очищаем state ордера
         */
        clearOrder(){

            let resetData = {
                bankDetails: [],
                orders: [],
                totalAmount: {},
                userId: null,
            };

            this.$patch(resetData);

        },

        getPurchaseNameOrder(item, currency){
            let amountValue = item.hasOwnProperty('amount') && item.amount.hasOwnProperty(currency) ? item.amount[currency] : '';
            let amountStrikeValue = item.hasOwnProperty('amountStrike') && item.amountStrike.hasOwnProperty(currency) ? item.amountStrike[currency] : '';

            if(currency.toLowerCase() === 'usd'){
                amountValue = amountValue !== '' ? '$' + amountValue : '';
                amountStrikeValue = amountStrikeValue !== '' ? '$' + amountStrikeValue : '';
            }

            return item.purchaseName
                .replace(/{{price}}/g, amountValue)
                .replace(/{{priceStrike}}/g, amountStrikeValue);
        }

    }

})();