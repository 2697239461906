<template>
  <div class="bc-payer-crypto bcpc" :class="mainClass">

    <transition name="fade" mode="out-in">
      <div v-if="!useCrypto.windowActive">
        <div class="bcpc__choose-crypto">
          <v-form-group class="bcpc__choose-crypto-group">
            <v-form-label data-name="choose-coin">
              Choose a coin
            </v-form-label>
            <v-form-select-custom
                :options="listCryptoCoins"
                v-model="useCrypto.asset_id"
                :strict-value="false"
                :searchable="false"
            />
          </v-form-group>
          <v-form-group class="bcpc__choose-crypto-group">
            <v-form-label data-name="choose-blockchain">
              Choose a blockchain
            </v-form-label>
            <v-form-select-custom
                :options="listCryptoBlockchains"
                v-model="useCrypto.blockchain"
                :strict-value="false"
                :searchable="false"
            />
          </v-form-group>
        </div>

        <div class="bcpc__group">
          <div class="bcpc__icons">
            <img v-for="icon in cryptoIcons" :src="icon.src">
          </div>
          <div class="bcpc__button-wrap">
            <v-button
                tag="a"
                color="green"
                @click="openPayWindow"
                class="bcpc__button"
                :disabled="isDisabledButton"
                :loading="payIsLoading"
            >Pay with Crypto</v-button>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade" mode="out-in">
      <bc-payer-crypto-payment v-if="useCrypto.windowActive" :cart-type="cartType" :project-uniquekey="projectUniquekey"/>
    </transition>

  </div>
</template>

<script setup>

import VButton from "@app-vue/components/Base/VButton.vue";
import {computed, reactive, ref, watch} from "vue";
import VFormLabel from "@app-vue/components/VForm/VFormLabel.vue";
import VFormGroup from "@app-vue/components/VForm/VFormGroup.vue";
import VFormSelectCustom from "@app-vue/components/VForm/VFormSelectCustom.vue";
import {usePayByCrypto} from "@app-vue/stores/usePayByCrypto.js";
import BcPayerCryptoPayment from "@app-vue/components/BuyCredits/BcPayer/BcPayerCryptoPayment.vue";
import {useBillingCart} from "@app-vue/stores/useBilling";
import {useLinkBuildCart} from "@app-vue/stores/useLinkBuilding";

const props = defineProps({
  cartType: {},
  projectUniquekey: {default:null,},
});

const useCrypto = usePayByCrypto(props.projectUniquekey);

const mainClass = computed(() => {
  return 'bcpc--crypto';
});
const payIsLoading = ref(false);

const cryptoIcons = reactive([
  {src:'/img/icons/crypto-logos/bitcoin-btc-logo.svg'},
  {src:'/img/icons/crypto-logos/bitcoin-cash-bch-logo.svg'},
  {src:'/img/icons/crypto-logos/usd-coin-usdc-logo.svg'},
  {src:'/img/icons/crypto-logos/multi-collateral-dai-dai-logo.svg'},
  {src:'/img/icons/crypto-logos/litecoin-ltc-logo.svg'},
  {src:'/img/icons/crypto-logos/dogecoin-doge-logo.svg'},
  {src:'/img/icons/crypto-logos/ethereum-eth-logo.svg'},
  {src:'/img/icons/crypto-logos/tether-logo.svg'},
  {src:'/img/icons/crypto-logos/apecoin-ape-ape-logo.svg'},
]);

const listCryptoCoins = reactive([
  {
    value: 'BTC', name: 'BTC',
    networks: [
      {value: 'BTC',name: 'BTC',},
    ]
  },
  {
    value: 'DOGE', name: 'DOGE',
    networks: [
      {value: 'Dogecoin',name: 'Dogecoin',},
    ]
  },
  {
    value: 'ETH', name: 'ETH',
    networks: [
      {value: 'ETH',name: 'ETH',},
    ]
  },
  {
    value: 'LTC', name: 'LTC',
    networks: [
      {value: 'LTC',name: 'LTC',},
    ]
  },
  {
    value: 'MATIC', name: 'MATIC',
    networks: [
      {value: 'Polygon',name: 'Polygon',},
    ]
  },
  {
    value: 'TRX', name: 'TRX',
    networks: [
      {value: 'Tron',name: 'Tron',},
    ]
  },
  {
    value: 'USDC', name: 'USDC',
    networks: [
      {value: 'Polygon',name: 'Polygon',},
      {value: 'ETH',name: 'ETH',},
    ]
  },
  {
    value: 'USDT', name: 'USDT',
    networks: [
      {value: 'ETH',name: 'ETH',},
      {value: 'Tron',name: 'Tron',},
    ]
  },
]);

const listCryptoBlockchains = reactive([...listCryptoCoins[0].networks]);

watch(() => useCrypto.asset_id, (value, oldValue, onCleanup) => {
  listCryptoBlockchains.splice(0,listCryptoBlockchains.length);
  listCryptoBlockchains.push(...listCryptoCoins.find((item) => item.value === value)?.networks);
  useCrypto.$patch({blockchain: listCryptoBlockchains[0].value});
},{immediate: true,});

const openPayWindow = () => {
  payIsLoading.value = true;
  useCrypto.pay().then((response) => {
    payIsLoading.value = false;
    let [status, message] = response;
    if(status) {
      useCrypto.openWindow();
    }else{
      console.log('error', message);
    }
  });

}

const isDisabledButton = computed(() => {
  switch(props.cartType){
    case 'main' : return useBillingCart().totalPrice === 0;
    case 'link-building' : return useLinkBuildCart(props.projectUniquekey).finalAmount <= 0;
  }
});

</script>

<style scoped lang="scss">

.bc-payer-crypto.bcpc{

  > div{
    display: flex;
    flex-direction: column;
    gap:20px;
  }

  .bcpc__group{
    display:flex;
    flex-direction:column;
    gap:20px;

    @media(max-width: 767px){
      gap:16px;
    }

    .bcpc__icons{
      display:flex;
      justify-content:center;
      align-items:center;
      gap:16px;
      flex-wrap:wrap;
      padding:6px;

      @media(max-width:767px){
        padding:0px;
        gap:6px;
      }

      img {
        width:48px;
        height:48px;
      }


    }

    .bcpc__button-wrap{
      .bcpc__button{
        width:100%;
        font-weight:600;
        cursor:pointer;
      }
    }

  }

  &.bcpc--crypto{
    .bcpc__icons{

      margin-left:auto;
      margin-right:auto;
      margin-bottom:-6px;
      padding:3px;
      @media(max-width:767px){
        max-width:240px;
      }
      img{
        width:38px;
        height:38px;
        margin-bottom:6px;
      }

    }
  }

  .bcpc__choose-crypto{
    display:flex;
    gap:20px;
    justify-content: center;
    .bcpc__choose-crypto-group{
      width:170px;
      margin-bottom:0px;
    }
  }


}

.fade-enter-from{
  opacity:0;
}
.fade-enter-active{
  transition: opacity 0.5s;
}
.fade-enter-to{
  opacity:1;
}
.fade-leave-from{
  display:none;
}
.fade-leave-active{
  display:none;
}
.fade-leave-to{
  display:none;
}

</style>