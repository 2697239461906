let observer;

export default {
    mounted(el, binding) {
        let options = {root: null};
        if(binding.value.hasOwnProperty('options')){
            options = Object.assign(options, binding.value.options)
        }

        observer = new IntersectionObserver((entries) => {
            const entry = entries.reduce((max, obj) => {
                return obj.time > max.time ? obj : max;
            });
            if(binding.value.hasOwnProperty('callback')){
                binding.value.callback(entry.isIntersecting);
            }else if(binding.value.hasOwnProperty('constant')){
                binding.value.constant.value = entry.isIntersecting;
            }

        }, options);

        observer.observe(el);
    },
    unmounted(el) {
        observer.unobserve(el);
    }
}