<template>
  <div class="lb-step-cart-summary-table">

    <table class="cart-summary-table" v-if="!isMobile">
      <thead>
        <tr>
          <th class="cart-summary-table__col-id"></th>
          <th class="cart-summary-table__col-website">Website</th>
          <th class="cart-summary-table__col-keyword">Keyword</th>
          <th class="cart-summary-table__col-type">Type</th>
          <th class="cart-summary-table__col-price">Price</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(cartItem,index) in useCart.items">
          <td>
            <div>
              <span class="cart-summary-table__index">{{index+1}}.</span>
            </div>
          </td>
          <td>
            <div>
              <v-link class="cart-summary-table__website" :href="cartItem.website" is-outer is-ends-ellipsis :text="cartItem.website"/>
            </div>
          </td>
          <td>
            <div>
              <span class="cart-summary-table__keyword" v-text="cartItem.keyword"/>
            </div>
          </td>
          <td>
            <div>
              <span class="cart-summary-table__type" v-text="useBacklinkOptions().getTitleShortById(cartItem.option_id)"/>
            </div>
          </td>
          <td>
            <div>
              <span class="cart-summary-table__price">
                {{cartItem.credits}} {{pluralize('credit',cartItem.credits)}}
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="cart-summary-items" v-if="isMobile">
      <div class="cart-summary-items__item" v-for="(cartItem,index) in useCart.items">
        <div class="cart-summary-items__index">
          {{index+1}}.
        </div>
        <div class="cart-summary-items__content">
          <v-link class="cart-summary-items__website" is-outer is-ends-ellipsis :text="cartItem.website"/>
          <span class="cart-summary-items__keyword" v-text="cartItem.keyword"/>
          <span class="cart-summary-items__content-bottom">
            <span class="cart-summary-items__type" v-text="useBacklinkOptions().getTitleShortById(cartItem.option_id)"/>
            <span class="cart-summary-items__price">{{cartItem.credits}} {{pluralize('credit',cartItem.credits)}}</span>
          </span>
        </div>
      </div>
    </div>

    <div class="cart-summary-total">
      <div class="cart-summary-total__amount">{{useCart.credits}} {{pluralize('credit',useCart.credits)}}</div>
      <div class="cart-summary-total__balance">Account balance: {{useUserBalance().backlinkV2BalancesAmount}} {{pluralize('credit',useUserBalance().backlinkV2BalancesAmount)}}</div>
    </div>

  </div>
</template>

<script setup>

import {useLinkBuildCart} from "@app-vue/stores/useLinkBuilding";
import VLink from "@app-vue/components/Base/VLink.vue";
import {useBacklinkOptions, useUserBalance} from "@app-vue/stores/useStaticData";
import {computed, getCurrentInstance} from "vue";
import {pluralize} from "@app-vue/utils/utils";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const props = defineProps({
  projectUniquekey:{},
});

const useCart = useLinkBuildCart(props.projectUniquekey);

</script>

<style lang="scss" scoped>
.lb-step-cart-summary-table{

  .cart-summary-table{
    width:100%;
    max-width:100%;
    table-layout: fixed;
    border-bottom:1px solid $dark-10;
    thead{
      color:$dark-40;
      th{
        font:$font-13-regu;
        height:44px;
        border-bottom:1px solid $dark-10;
        &:last-child{
          text-align:right;
        }
      }
    }
    tbody{
      color:$dark-80;
      font:$font-13-regu;
      td{
        padding-right:30px;
        height:32px;
        > div{
          @include flex(row,start,start);
        }
        &:last-child{
          padding-right: 0px;
          > div{
            @include flex(row,end,start);
          }
        }
      }
    }
    &__col-id{width:40px}
    &__col-website{width:330px}
    &__col-keyword{width:234px}
    &__col-type{width:75px}
    &__col-price{width:100px}
    &__index{
      font:$font-14-bold;
    }
    &__keyword{
      @include ellipsis();
    }
    &__website{
      color:$dark-80;
      border-bottom:1px dashed;
      position:relative;
      padding-bottom:1px;
    }
  }

  .cart-summary-items{
    border-bottom:1px solid $dark-10;
    &__item{
      @include flex(row,between,start);
      font-size:13px;
      padding:7px 0px;
    }
    &__index{
      flex-shrink:0;
      width:20px;
    }
    &__content{
      @include flex(column,start,start,4px);
      width:calc(100% - 20px);
    }
    &__content-bottom{
      @include flex(row,between,center);
      width:100%;
    }
    &__keyword{
      color:$dark-40;
      @include ellipsis();
    }
    &__type{
      color:$dark-40;
    }
    &__price{
      color:$dark-80;
      font-weight: 600;
    }
    &__website{
      color:$dark-80;
      border-bottom:1px dashed;
      position:relative;
      padding-bottom:1px;
    }
  }

  .cart-summary-total{
    @include flex(column,center,end);
    padding: 12px 0px;

    .cart-summary-total__amount{
      color:$dark-80;
      font:$font-14-bold;
      @media (max-width: 767px) {
        font-weight:600;
      }
    }
    .cart-summary-total__balance{
      color:$dark-40;
      font:$font-mini-11-regu;
      b{
        font-weight: 700;
      }
    }
  }

}
</style>