<template>
  <div class="lb-orders lbo" :class="!linkBuildProjectStore.is_active ? 'is-disabled' : ''">

    <v-tabs v-model="tabActive" v-if="false">
      <v-tabs-item hash="backlinks">Backlinks</v-tabs-item>
      <v-tabs-item hash="activity_history">Activity history</v-tabs-item>
    </v-tabs>

    <lb-orders-backlinks-v2 :project-uniquekey="projectUniquekey"/>

  </div>
</template>

<script setup>

import {ref} from "vue";
import {useLinkBuildProject} from '@app-vue/stores/useLinkBuilding.js';
import VTabs from "@app-vue/components/Base/VTabs.vue";
import VTabsItem from "@app-vue/components/Base/VTabsItem.vue";
import LbOrdersBacklinksV2 from "@app-vue/components/LinkBuilding/LbOrdersBacklinksV2.vue";

const tabActive = ref('backlinks');

const props = defineProps({
  projectUniquekey: {}
});

const linkBuildProjectStore = useLinkBuildProject(props.projectUniquekey);

</script>

<style lang="scss" scoped>
.lb-orders.lbo{

  &.is-disabled{
    position:relative;
    opacity:0.5!important;
    &:after{
      content:'';
      position:absolute;
      top:0px;
      left:0px;
      width:100%;
      height:100%;
    }
  }

}
</style>