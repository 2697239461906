<template>
  <button class="user-notifications-button button button-icon-circle" type="button" :disabled="!loaded || readAll || notifications.length <= 0" @click="show =! show">
    <VIconSvg name="icon_notifications"/>
    <div class="user-notifications-button__number" v-show="!readAll && notifications.length > 0">{{ notifications.length }}</div>
  </button>
  <div class="user-notifications-block js-closed" v-if="loaded && !readAll && notifications.length > 0" :class="{'expanded':show}">
    <div class="user-notifications-block__header">
      <div class="row g-0 align-items-center">
        <div class="col-auto me-10">
          <div class="user-notifications-block__header-title">
            Notifications {{ notifications.length }}
          </div>
        </div>
        <div class="col-auto">
          <div class="user-notifications-block__read-all">
            <button class="button-read-all button button_bg_blue-light" @click="setReadAll(); readAll = true;">Read all</button>
          </div>
        </div>
      </div>
      <div class="user-notifications-block__close" @click="show = false">
        <VIconSvg name="icon_close2"/>
      </div>
    </div>
    <user-notifications-item v-for="notification in notifications"
                             :id="notification.id"
                             :type_id="notification.type_id"
                             :type_name="notification.type_name"
                             :title="notification.title"
                             :message="notification.message"
                             :button_title="notification.button_title"
                             :button_url="notification.button_url"
                             :is_read="notification.is_read"
    />
  </div>
</template>

<script>
import UserNotificationsItem from "./UserNotificationsItem.vue";
import {mapState, mapActions} from "vuex";
import VIconSvg from "./Base/VIconSvg.vue";

let uploadDataNotificationTimeout = null;

export default {
  components: {
    VIconSvg,
    UserNotificationsItem
  },
  data() {
    return {
      show: false,
      readAll: false,
    };
  },
  methods: {
    ...mapActions({
      fetchNotifications: 'userNotifications/fetchNotifications',
      setReadNotifications: 'userNotifications/setReadNotifications',
      setReadNotification: 'userNotifications/setReadNotification'
    }),
    uploadData() {
      this.fetchNotifications().then(() => {
        // clearTimeout(uploadDataNotificationTimeout);
        // uploadDataNotificationTimeout = setTimeout(() => this.uploadData(), 10000);
      });
    },
    setReadAll() {
      this.setReadNotifications().then(() => {
        this.uploadData();
      });
    },
    setRead(id) {
      this.setReadNotification(id).then(() => {
        this.uploadData();
      });
    }
  },
  computed: {
    ...mapState({
      notifications: state => state.userNotifications.notifications,
      loaded: state => state.userNotifications.loaded
    }),
  },
  mounted() {
    this.uploadData();
  }
}
</script>

<style lang="scss" scoped>
.user-notifications-button {
  user-select: none;

  .user-notifications-button__number {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -5px;
    right: 0;
    width: 18px;
    height: 18px;
    font-weight: 600;
    font-size: 10px;
    line-height: 1;
    color: #FFFFFF;
    background: #EF8E34;
    border-radius: 50%;
  }
}

.user-notifications-block {
  position: absolute;
  top: 40px;
  right: -46px;
  width: 320px;
  min-height: 60px;
  max-height: 480px;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0 4px 14px 0 rgba(28, 126, 224, 0.18);
  overflow: hidden;
  overflow-y: auto;
  z-index: 11000;
  opacity: 0;
  transition: all 0.15s ease;
  pointer-events: none;

  .button-read-all {
    height: 26px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 400;
    border: none;
    border-radius: 5px;
  }

  .user-notifications-block__header {
    padding: 16px 10px 16px 16px;
    font-size: 16px;
    font-weight: 600;
    color: #1F293B;
  }

  .user-notifications-block__close {
    position: absolute;
    top: 14px;
    right: 10px;
    padding: 6px;
    cursor: pointer;
    z-index: 10;

    svg {
      display: block;
      width: 11px;
      height: 11px;
      color: rgba(#000, 0.5);
    }

    &:hover {
      svg {
        color: #1C7EE0;
      }
    }
  }

  &.expanded {
    opacity: 1;
    top: 48px;
    pointer-events: auto;
  }

  @media (max-width: 575.98px) {
    position: fixed;
    top: 60px;
    left: 0;
    right: auto;
    width: calc(100% - 20px);
    height: auto;
    max-height: calc(100% - 60px);
    margin: 0 10px;
  }
}
</style>
