<template>
  <div class="lb-step-cart-packages-item" :class="`is-${props.type}`" @click="emit('changeQuantity',props.quantity)">
    <div class="info">
      <div class="info__top">
        <span class="info__title">Top up balance with</span>
        <span class="info__credits">{{quantity}} {{pluralize('credit',quantity)}}</span>
      </div>
      <div class="info__bottom">
        <v-tag-save v-if="type!=='simple'" type="v2">{{discountPercent}}% Discount</v-tag-save>
        <span class="info__price">${{priceFormatted(price/quantity)}} per credit</span>
      </div>
    </div>
    <div v-if="type !== 'simple'" class="icon-dropdown-wrap" :class="dropdownClasses">
      <v-icon-svg-wrapped class="icon-dropdown" :outer-size="20.25" name="arrow-dark-40" as-img/>
    </div>
  </div>
</template>

<script setup>

import VIconSvgWrapped from "@app-vue/components/Base/VIconSvgWrapped.vue";
import VTagSave from "@app-vue/components/Base/VTagSave.vue";
import {pluralize, priceFormatted} from "@app-vue/utils/utils";
import {computed} from "vue";
import {useCreditTypes} from "@app-vue/stores/useBilling";

const props = defineProps({
  /**
   * @type {'button'|'dropdown'|'simple'}
   */
  type: {required:true,},
  quantity: {default:1,},
  /**
   * @type {'open'|'closed'}
   */
  dropdownStatus: {default:'closed'},
});

const emit = defineEmits(['changeQuantity']);

const planOption = computed(() => {
  return useCreditTypes().creditTypesBacklinkV2?.firstPlanOptions
});

const price = computed(() => {
  return planOption.value?.priceForQuantity(props.quantity);
});

const discountAmount = computed(() => {
  return planOption.value?.discountAmountForQuantity(props.quantity);
});

const discountPercent = computed(() => {
  return Math.round(100 * discountAmount.value / (price.value+discountAmount.value));
});

const dropdownClasses = computed(() => {
  let response = [];
  if(props.type !== 'button') response.push('is-hide');
  response.push(`is-${props.dropdownStatus}`)
  return response;
});

</script>

<style lang="scss" scoped>
.lb-step-cart-packages-item{
  @include flex(row,end,center,6px);
  cursor:pointer;
  padding:12px 10px;
  height:58px;
  background:#fff;

  &.is-button{
    border:1px solid $primary-green-100;
    border-radius: 4px;
  }

  &.is-dropdown{
    &:hover{
      transition-duration: 0.2s;
      background:$secondary-blue-5;
    }
  }

  &.is-simple{
    padding:0px;
    cursor:initial;
    height:initial;
  }

  .info{
    @include flex(column,center,flex-end,1px);

    .info__top{
      @include flex(row,right,center,4px);
    }

    .info__credits{
      font:$font-13-regu;
      color:$dark-80;
      font-weight:600;
    }

    .info__title{
      font:$font-mini-12-regu;
      color:$dark-40;
    }

    .info__bottom{
      @include flex(row,right,center,4px);
    }

    .info__price{
      font:$font-mini-12-regu;
      color:$dark-80;
    }

  }

  .icon-dropdown-wrap{
    transform: rotate(90deg);
    flex-shrink: 0;
    transition-duration: 0.2s;
    .icon-dropdown{
      width:20.25px;
    }
    &.is-hide{
      opacity:0;
    }
    &.is-open{
      transform: rotate(-90deg);
    }
  }

}
</style>