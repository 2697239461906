<template>
  <div
      class="scp__project-form-wrap"
      :class="formRequestExecuting ? 'scp__project-form-wrap--loading' : ''"
  >
    <div>

      <div class="scp__project-form-add-keyword-wrap" v-if="formShowStatus==='form'">
        <div class="scp__project-form-add-keyword-wrap-title">
          Add Keywords
        </div>
        <sc-form-group-keywords
            :website="website"
            :errors="useKeywords.errors.addKeywords.errors?.keywords"
            ref="refFormGroupKeywords"
            :search-engine-id="useSeoClicksProject(projectId).searchEngine.id"
            class="scp__project-form-add-keyword-keywords"
            :excluded-project-id="props.projectId"
        />
        <v-form-message-error :message-error="useKeywords.errors.addKeywords.message" class="scpaf__error-message"/>
        <v-button
            class="scp__project-controls-btn"
            v-show="formShowStatus==='form'"
            text="Add Keywords"
            :color="colorMainButton"
            @click="addKeywords"
        />
      </div>

      <div class="scp__success" v-if="formShowStatus==='success'">

        <div class="scp__success-title">
          <span class="scp__success-title-icon">
            <v-icon-svg name="icon-ok-fill"/>
          </span>
          <span class="scp__success-title-text">
            {{successKeywords.length}} keyword{{(successKeywords.length === 1) ? '' : 's'}} successfully added
          </span>
        </div>

        <div class="scp__success-list">

          <ul class="scp__success-list-ul">
            <li v-for="item in (successKeywordsMore ? successKeywords : successKeywords.slice(0,3))">
              <span class="scp__success-list-item">
                <span v-if="item?.countryCode || item?.state_code" class="scp__success-list-flag">
                  <v-icon-flag :code="item.countryCode" v-if="item?.countryCode" :state-code="item?.state_code"/>
                </span>
                <span class="scp__success-list-text">
                  {{item.name}}
                </span>
              </span>
            </li>
          </ul>

          <div
              class="scp__success-list-more"
              v-if="successKeywords.length > 3 && !successKeywordsMore"
              @click="successKeywordsMore=true"
          >
            +{{successKeywords.length-3}} keyword{{((successKeywords.length-3)===1) ? '' : 's'}}
          </div>

        </div>

        <div class="scp__close-wrap" @click="formShowStatus = 'button'">
          <v-icon-svg name="close-icon-blue" class="scp__close-icon"/>
        </div>

      </div>

      <div class="scp__error" v-if="formShowStatus==='error'">
        <div class="scp__error-title">
          <span class="scp__error-title-icon">
            <v-icon-svg name="emotion-bad-red"/>
          </span>
          <span class="scp__error-title-text">Some problems while adding keywords</span>
        </div>
        <div class="scp__error-controls">
          <v-button color="link" class="scp__error-controls-later" @click.prevent="formShowStatus='button'">Do it later</v-button>
          <v-button color="blue" class="scp__error-controls-retry" @click.prevent="formShowStatus='form'">Retry</v-button>
        </div>
      </div>

      <div class="scp__project-controls" v-if="formShowStatus==='button' || formShowStatus==='success'">
        <v-button
            class="scp__project-controls-btn"
            @click.prevent="() => {formShowStatus='form';successKeywordsMore=false;}"
            text="Add Keywords"
            :color="colorMainButton"
        />
      </div>

    </div>
  </div>
</template>

<script setup>

import VButton from "@app-vue/components/Base/VButton.vue";
import {onMounted, onUnmounted, reactive, ref, watch} from "vue";
import VIconFlag from "@app-vue/components/Base/VIconFlag.vue";
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import ScFormGroupKeywords from "@app-vue/components/SeoClicks/ScFormGroupKeywords.vue";
import VFormMessageError from "@app-vue/components/VForm/VFormMessageError.vue";
import {useSeoClicksKeywords, useSeoClicksProject} from "@app-vue/stores/useSeoClicks";
import {useSuggestKeywords} from "@app-vue/stores/useSuggestKeywords";

const refFormGroupKeywords = ref();

const props = defineProps({
  website:{type: String},
  projectId: {},
  colorMainButton: {type:String, default: 'blue',},
});

const useKeywords = useSeoClicksKeywords(props.projectId);

const emit = defineEmits(['updated']);

const successKeywords = reactive([]);
const successKeywordsMore = ref(false);

const formShowStatus = ref('button');//button | form | success | error

watch(() => formShowStatus.value, (value) => {
  if(value === 'form'){
    setTimeout(() => {
      refFormGroupKeywords.value.updateSuggestedKeywords();
    },100);
  }
});

const formRequestExecuting = ref(false);

async function addKeywords(){

  formRequestExecuting.value = true;
  let [status] = await useKeywords.addKeywords(useSuggestKeywords().selectedKeywordsForSend);
  formRequestExecuting.value = false;

  if(status){
    successKeywords.splice(0,successKeywords.length);
    useSuggestKeywords().selectedKeywords.forEach((item) => {
      successKeywords.push(item);
    });
    useSuggestKeywords().$reset();

    formShowStatus.value = 'success';

    emit('updated');
  }

}

const handleEscKey = (event) => {
  if (event.key === 'Escape' || event.key === 'Esc') {
    formShowStatus.value = 'button';
  }
};

onMounted(() => {
  window.addEventListener('keydown', handleEscKey);
});

onUnmounted(() => {
  window.removeEventListener('keydown', handleEscKey);
});

function startAdding(){
  formShowStatus.value='form';
}

defineExpose({startAdding});

</script>

<style scoped lang="scss">
.scp__project-form-wrap{
  position: relative;
  &.scp__project-form-wrap:after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6196078431);
    opacity: 0;
    top: 0;
    left: 0;
    z-index: -1;
  }
  &.scp__project-form-wrap--loading:after{
    opacity: 1;
    z-index: 2;
  }
}
.scp__project-form-add-keyword-wrap{
  padding:30px;
  border-radius: 8px;
  border: 2px solid #EAF1FF;

  @media(max-width:$mobile-width){
    padding:0px;
    border:none;
  }

  .scp__project-form-add-keyword-wrap-title{
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: #384967;
    margin-bottom:20px;
  }
  .scp__project-form-add-keyword-keywords{
    margin-bottom:20px;
  }
  .scp__project-form-add-keyword{
    width:100%;
  }
  @media(max-width:$mobile-width){
    .scp__project-controls-btn{
      width:100%;
    }
  }
}
.scp__project-controls{
  display: flex;
  gap:10px;
  @media(max-width:$mobile-width){
    .scp__project-controls-btn{
      width:100%;
    }
  }
}

.scp__success{
  background-color: #1C7EE00D;
  padding:20px;
  border-radius: 4px;
  position: relative;
  .scp__success-title{
    margin-bottom:20px;
    display: flex;
    gap:10px;
    color:#384967;
    align-items: center;
  }
  margin-bottom:20px;

  .scp__close-wrap{
    position: absolute;
    top:5px;
    right:5px;
    width:20px;
    height:20px;
    cursor: pointer;
    opacity:0.8;
    display: flex;
    justify-content: center;
    align-items:center;

    &:hover{
      opacity:1;
    }
    .scp__close-icon{

    }
  }
}

.scp__success-list{}
.scp__success-list{
  .scp__success-list-ul{
    display: flex;
    flex-direction: column;
    gap:9px;
    list-style: none;
    padding: 0px;
    margin:0px;


    >li{
      .scp__success-list-item{
        padding: 5px 10px;
        background-color: #0D4E8F0D;
        gap:5px;
        border-radius: 4px;
        color:#0D4E8F;
        display: inline-flex;

        .scp__success-list-flag{
          display: flex;
          justify-content: center;
          align-items: center;
          gap:4px;
        }
        .scp__success-list-text{}
      }
    }
  }
}
.scp__success-list-more{
  margin-top:9px;
  color:#0D4E8F;
  cursor: pointer;
  display: inline-block;
}
.scp__error{
  background-color: #F2001D0D;
  border-radius: 4px;
  padding:20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .scp__error-title{
    display: flex;
    align-items: center;
    gap:10px;
    color:#384967;

    .scp__error-title-icon{
      display: block;
    }
    .scp__error-title-text{}
  }
  .scp__error-controls{
    display: flex;
    gap:5px;

    .scp__error-controls-later{}
    .scp__error-controls-retry{}
  }
}
</style>