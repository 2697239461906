<template>
  <div class="top-sites-better">
    <ul class="top-sites-better__list">

      <li class="top-sites-better__item top-sites-better__item--current">
        <span class="top-sites-better__item-icon top-sites-better__item-icon--current" :class="recoStore.currentColor">
          <v-icon-svg :name="recoStore.currentTitleIconClass"/>
        </span>
        <span class="top-sites-better__item-name top-sites-better__item-name--current">
          <a :href="getUrlWithHttp(recsStore.url)" target="_blank" rel="noopener noreferrer">{{getDomainFromUrl(recsStore.url)}}</a>
        </span>
        <span class="top-sites-better__item-value" :class="recoStore.currentColor">
          {{getValueSignForCurrent()}}
        </span>
      </li>

      <li class="top-sites-better__item top-sites-better__item--search-engine">
        <span class="top-sites-better__item-icon">
          <v-icon-svg name="google-icon"/>
        </span>
        <span class="top-sites-better__item-name">
          Top {{recoStore.getTopValueSortedAsc().length}} sites
        </span>
      </li>

      <li
          v-for="(top_value, index) in recoStore.getTopValueSortedAsc()"
          class="top-sites-better__item top-sites-better__item--stat"
      >
        <span class="top-sites-better__item-icon top-sites-better__item-icon--number">
          {{top_value.position}}
        </span>
        <span class="top-sites-better__item-name top-sites-better__item-name--stat">
          <a :href="getUrlWithHttp(top_value.url)" target="_blank" rel="noopener noreferrer">{{getDomainFromUrl(top_value.url)}}</a>
        </span>
        <span
            class="top-sites-better__item-value"
            :class="recoStore.getColorForValue(top_value.value)"
        >
          {{getValueSign(top_value.value)}}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import {useRecsStore} from "@app-vue/stores/useRecsStore";
import {chartTypes} from "@app-vue/constants/recoConstants";

export default {
  components: {VIconSvg},
  props: {
    recoMetric: {type: String, required:true},
    projectUniquekey: {},
  },
  computed:{
    recoStore: (state)=>{
      return state.recsStore.recoStoreByMetric(state.recoMetric);
    }
  },
  methods:{
    getDomainFromUrl(urlString){

      let urlStringURL = urlString.trim();

      if(urlString.toLowerCase().indexOf('http://')===-1 && urlString.toLowerCase().indexOf('https://') === -1){
        urlStringURL = 'http://' + urlString.trim;
      }else{
        urlStringURL = urlString;
      }

      try{
        let url = new URL(urlStringURL);
        return url.hostname;
      }catch(err){
        return urlString
      }

    },
    getUrlWithHttp(urlString){
      urlString = urlString.trim();
      if(urlString.toLowerCase().indexOf('http://')===-1 && urlString.toLowerCase().indexOf('https://') === -1){
        return 'http://' + urlString;
      }else{
        return urlString;
      }
    },
    getValueSign(value){
      if(this.recoStore.chartScaleType === chartTypes.default){
        if(value === -1){
          return 'N/A';
        }else{
          return value;
        }
      }else{
        if(value === 1){
          return 'have';
        }else{
          return 'don\'t have';
        }
      }
    },
    getValueSignForCurrent(){
      if(this.recoStore.not_applicable === 0){
        let r = this.getValueSign(this.recoStore.value);
        return r;
      }else{
        return 'N/A';
      }
    }
  },
  setup(props) {
    return {
      recsStore: useRecsStore(props.projectUniquekey)
    }
  }
}
</script>

<style lang="scss" scoped>
.top-sites-better{}
.top-sites-better__list{
  list-style:none;
  padding:0px;
  margin:0px;
}
.top-sites-better__item{
  display: flex;
  padding: 7px 0px;
  font-size: 14px;
  align-items: center;
}
.top-sites-better__item--current{
  font-size: 13px;
  font-weight: 600;
}
.top-sites-better__item--search-engine{
  font-size: 12px;
  color:#00000080;
  border-top:1px solid #D6E3EF80;
  border-bottom:1px solid #D6E3EF80;
  margin:6px 0px;
  padding:8px 0px;
}
.top-sites-better__item-icon{
  width:26px;
  flex-shrink:0;
  display: flex;
}
.top-sites-better__item-icon--number{
  font-size:14px;
  color: #00000080;
}
.top-sites-better__item-icon--current{
  width:12px;
  margin-right:14px;
  justify-content: center;
}
.top-sites-better__item-icon.color-super-danger svg{fill:$recommendation-super-danger;}
.top-sites-better__item-icon.color-danger svg{fill:$recommendation-danger;}
.top-sites-better__item-icon.color-warning svg{fill:$recommendation-warning;}
.top-sites-better__item-icon.color-success svg{fill:$recommendation-success;}
.top-sites-better__item-icon.color-default svg{fill:#bbb;}
.top-sites-better__item-icon.color-error svg{fill:$recommendation-error;}
.top-sites-better__item-name{
  flex-grow:2;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.top-sites-better__item-name--current{
  font-weight: 600;
}
.top-sites-better__item-name a{
  text-decoration: none;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
}
.top-sites-better__item-value{
  font-weight: 600;
  margin-left:5px;
}
.top-sites-better__item-value-name{
  font-weight:400!important;
}
.top-sites-better__item-value.color-super-danger{color:$recommendation-super-danger;}
.top-sites-better__item-value.color-danger{color:$recommendation-danger;}
.top-sites-better__item-value.color-warning{color:$recommendation-warning;}
.top-sites-better__item-value.color-success{color:$recommendation-success;}
.top-sites-better__item-value.color-error{color:$recommendation-error;}
</style>