<template>
  <div class="recommendation-daily-limit">
    <div class="head" v-if="!isMobile">
      RECOMMENDED DAILY LIMIT
    </div>
    <div class="content">
      <v-icon-svg v-if="isMobile" class="google-icon" name="google" as-img/>
      <div class="cols">
        <div class="col">
          <div class="col-title">
            top 1
          </div>
          <div class="col-value">
            <v-link href="#" v-if="top1 !== null">
              <v-number :value="top1" @click.prevent="chooseDaily(top1)"/>
            </v-link>
            <div class="col-na" v-else>
              N/A
            </div>
          </div>
        </div>
        <div class="col">
          <div class="col-title">
            top 3
          </div>
          <div class="col-value">
            <v-link href="#" v-if="top3 !== null">
              <v-number :value="top3" @click.prevent="chooseDaily(top3)"/>
            </v-link>
            <div class="col-na" v-else>
              N/A
            </div>
          </div>
        </div>
        <div class="col">
          <div class="col-title">
            top 10
          </div>
          <div class="col-value">
            <v-link href="#" v-if="top10 !== null">
              <v-number :value="top10" @click.prevent="chooseDaily(top10)"/>
            </v-link>
            <div class="col-na" v-else>
              N/A
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import VLink from "@app-vue/components/Base/VLink.vue";
import VNumber from "@app-vue/components/Base/VNumber.vue";
import {computed, getCurrentInstance} from "vue";
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const props = defineProps({
  top1: {required:true,},
  top3: {required:true,},
  top10: {required:true,}
});

const emit = defineEmits(['choose-daily']);

const chooseDaily = (number) => {
  emit("choose-daily",number);
}

</script>

<style lang="scss" scoped>
.recommendation-daily-limit{
  display: flex;
  flex-direction: column;
  gap:2px;
  @media (max-width:767px) {
    width:100%;
  }

  .head{
    font-size: 9px;
    font-weight: 500;
    line-height: 12px;
    color: #9FA5B0;
  }

  .google-icon{
    width:15px;
  }

  .content{
    @include flex(row,start,center,15px);
  }

  .cols{
    @include flex(row,start,start,16px);

    @media(max-width:767px){
      width:100%;
      justify-content:space-between;
    }

  }

  .col{
    @media(max-width:767px){
      @include flex(column,start,start,2px);
      width:33.33%;
    }
  }

  .col-title{
    font-size: 9px;
    font-weight: 500;
    line-height: 12px;
    color:#9FA5B0;
    text-transform:uppercase;
    white-space: nowrap;
    @media(max-width: 767px){
      font:$font-mini-10-regu;
    }
  }

  .col-value{
    font-size: 12px;
    font-weight: 400;
    cursor:pointer;
    @media(max-width: 767px){
      font:$font-mini-12-regu;
    }
  }

  .col-na{
    color:#858585;
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
  }

}
</style>