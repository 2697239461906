<template>
  <div class="lb-step-cart">

    <!-- SUCCESS MESSAGE -->
    <div v-if="useOrders.ordersManager().hasNewOrders" class="message-orders-added">
      Your orders have been added
      <v-icon-svg-wrapped name="icon-close-dark-100"
                          :outer-size="16"
                          :inner-size="9.33"
                          class="message-orders-added__close"
                          @click="useOrders.ordersManager().markOrdersAsSeen()"/>
    </div>

    <!-- BUTTON START -->
    <div class="start-section" v-show="useProject.orderCreationStatusIsStart">

      <v-button v-if="useCart.countWithServerId === 0 && useLinkBuildProjectOrders(props.projectUniquekey).ordersManager().ordersSortedByCreatedAt.length > 0"
                class="start-section__button"
                color="green"
                text="Create backlinks"
                is-bold
                @click="useCart.goToEditingCart"/>

      <div class="start-with-draft" v-else-if="useCart.countWithServerId > 0">
        <div class="start-with-draft__head">
          Create order
        </div>
        <div class="start-with-draft__content">
        <span>
          You already have an unfinished order.
        </span>
          <v-link @click="useCart.goToEditingCart">
            Would you like to continue?
          </v-link>
        </div>
        <v-button color="green" class="start-with-draft__button" :click-action="[useCart.cleanAndGoToEditing]">
          Create a new order
        </v-button>
      </div>

      <v-wic-button v-show="!isMobile || !useOrders.ordersManager().hasNewOrders"
                    class="button-what-is"
                    :tooltip="{placement:!isMobile ? 'bottom' : 'bottom-end'}">
        <template #text>
          What is <b>1 backlink ?</b>
        </template>
        <template #tooltip>
          <v-wic-tooltip-link-building/>
        </template>
      </v-wic-button>
    </div>

    <!-- CREATE ORDER -->

    <div v-show="useProject.orderCreationStatusIsCreateOrder" class="cart-builder-wrap">

      <div class="cart-builder-wrap__head">
        <div>
          <div class="cart-builder-wrap__back-button" @click="useProject.orderCreationStatusSetStart()">
            <v-icon-svg name="icon-button-back"/>
            <span>Back</span>
          </div>
          <div class="cart-builder-wrap__head-title">Create backlinks</div>
        </div>
        <v-wic-button class="button-what-is"
                      :tooltip="{placement:!isMobile ? 'bottom' : 'bottom-end'}">
          <template #text>
            What is <b>DR ?</b>
          </template>
          <template #tooltip>
            <v-wic-tooltip-backlink/>
          </template>
        </v-wic-button>
      </div>

      <div class="cart-filling" v-if="useProject.orderCreationStatusIsCreateOrder">

        <lb-step-cart-build :project-uniquekey="projectUniquekey"
                            class="cart-filling__build"
                            :class="{'is-loading':refFinalizeButton?.isLoading}"/>

        <div class="cart-filling__button-wrap">
          <v-form-message-error no-margin :message-error="useCart.errors.check.message"/>
          <v-button class="cart-filling__button-next-step"
                    text="Next"
                    color="green"
                    ref="refFinalizeButton"
                    :click-action="[useCart.saveCartAndNExtFinalize]"/>
        </div>


      </div>

    </div>

    <!-- FINALIZE ORDER -->

    <div v-show="useProject.orderCreationStatusIsFinalizeOrder" class="cart-builder-wrap cart-builder-wrap--finalize">

      <div class="cart-builder-wrap__head">
        <div>
          <div class="cart-builder-wrap__back-button" @click="useProject.orderCreationStatusSetCreateOrder()">
            <v-icon-svg name="icon-button-back"/>
            <span>Back to edit</span>
          </div>
          <div class="cart-builder-wrap__head-title">Finalize</div>
        </div>

      </div>

      <div class="cart-finalize" v-if="useProject.orderCreationStatusIsFinalizeOrder">

        <lb-step-cart-summary-table  :project-uniquekey="projectUniquekey"/>

        <lb-step-cart-order-settings v-if="useUserBalance().backlinkV2BalancesAmount < useCart.credits"
                                     class="cart-finalize__settings"
                                     :project-uniquekey="projectUniquekey"/>

        <div class="cart-finalize__offset-10"/>

        <bc-payer v-if="useUserBalance().backlinkV2BalancesAmount < useCart.credits"
                  cart-type="link-building"
                  ref="refBcPayer"
                  :project-uniquekey="projectUniquekey"/>


        <div class="cart-finalize__finalize-button-wrap">
          <v-form-message-error :message-error="useCart.errors.finalize.message" no-margin/>
          <v-button color="green"
                    is-bold
                    class="cart-finalize__finalize-button"
                    v-if="useUserBalance().backlinkV2BalancesAmount >= useCart.credits"
                    :click-action="[useCart.finalizeAndUpdateCart]"
                    text="Finalize"/>
        </div>


      </div>

    </div>

  </div>
</template>

<script setup>

import VButton from "@app-vue/components/Base/VButton.vue";
import {useLinkBuildCart, useLinkBuildProject, useLinkBuildProjectOrders} from "@app-vue/stores/useLinkBuilding";
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import LbStepCartBuild from "@app-vue/components/LinkBuilding/LbStepCartBuild.vue";
import {useBacklinkOptions, useUserBalance} from "@app-vue/stores/useStaticData";
import LbStepCartSummaryTable from "@app-vue/components/LinkBuilding/LbStepCartSummaryTable.vue";
import LbStepCartOrderSettings from "@app-vue/components/LinkBuilding/LbStepCartOrderSettings.vue";
import {computed, getCurrentInstance, onMounted, ref, watch} from "vue";
import VFormMessageError from "@app-vue/components/VForm/VFormMessageError.vue";
import {useCouponDiscount, useCreditTypes} from "@app-vue/stores/useBilling";
import BcPayer from "@app-vue/components/BuyCredits/BcPayer/BcPayer.vue";
import VLink from "@app-vue/components/Base/VLink.vue";
import VIconSvgWrapped from "@app-vue/components/Base/VIconSvgWrapped.vue";
import VWicButton from "@app-vue/components/Base/WhatIsCredit/VWicButton.vue";
import VWicTooltipBacklink from "@app-vue/components/Base/WhatIsCredit/VWicTooltipBacklink.vue";
import {usePayByCrypto} from "@app-vue/stores/usePayByCrypto";
import {usePayBankTransfer} from "@app-vue/stores/usePayBankTransfer";
import VWicTooltipLinkBuilding from "@app-vue/components/Base/WhatIsCredit/VWicTooltipLinkBuilding.vue";

const props = defineProps({
  projectUniquekey:{},
});

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const refBcPayer = ref();
const refFinalizeButton = ref();

const useProject = useLinkBuildProject(props.projectUniquekey);
const useOrders = useLinkBuildProjectOrders(props.projectUniquekey);
const useCart = useLinkBuildCart(props.projectUniquekey);

useBacklinkOptions().download();
useCreditTypes().download();
useCouponDiscount().download();
useCart.update();

onMounted(() => {
  let useCrypro = usePayByCrypto(props.projectUniquekey)
  useCrypro.check().then(() => {
    if(useCrypro.hasOrder && useCrypro.orderIsPending){
      useCrypro.openWindow();
      useProject.orderCreationStatusSetFinalizeOrder();
      let timeOut = 0;
      if(!refBcPayer.value) timeOut = 200;

      setTimeout(() => {
        if(refBcPayer.value && refBcPayer.value.hasOwnProperty('currentTab')){
          refBcPayer.value.currentTab = '#tab_crypto';
        }
      },timeOut);

    }else if(useCrypro.hasOrder && !useCrypro.orderIsPending){
      useCrypro.$reset();
    }
  });
});

watch(() => useLinkBuildCart(props.projectUniquekey).finalAmount, () => {
  if(usePayByCrypto(props.projectUniquekey).windowActive){
    usePayByCrypto(props.projectUniquekey).closeWindow();
    usePayByCrypto(props.projectUniquekey).cancel().then(() => {
      usePayByCrypto(props.projectUniquekey).clearOrder();
    });
  }
  if(usePayBankTransfer(props.projectUniquekey).windowActive){
    usePayBankTransfer(props.projectUniquekey).closeWindow();
    usePayBankTransfer(props.projectUniquekey).clearOrder();
  }
});

watch(() => useProject.orderCreationStatusIsFinalizeOrder, (isFinalizeOrder) => {
  if(isFinalizeOrder){
    let useCrypro = usePayByCrypto(props.projectUniquekey)
    if(useCrypro.hasOrder && useCrypro.orderIsPending){
      useCrypro.openWindow();
      let timeOut = 0;
      if(!refBcPayer.value) timeOut = 200;

      setTimeout(() => {
        if(refBcPayer.value && refBcPayer.value.hasOwnProperty('currentTab')){
          refBcPayer.value.currentTab = '#tab_crypto';
        }
      },timeOut);
    }
  }
});

</script>

<style lang="scss" scoped>
.lb-step-cart{

  @include flex(column,start,stretch,20px);

  @media(max-width:767px){
    @include flex(column,start,stretch,16px);
  }

  .message-orders-added{
    padding:22px 18px;
    background:#F7F9FF;
    color:$dark-80;
    position:relative;
    .message-orders-added__close{
      top:10px;
      right:10px;
      cursor:pointer;
      position:absolute;
    }
  }

  .start-section{
    @include flex(row,between,start);

    @media(max-width: 767px){
      @include flex(column-reverse,start,stretch,16px);
    }

    .start-section__button{
      height:48px;
      font:$font-16-semi;
      @media (max-width: 767px){
        width:100%;
      }
    }

  }

  .start-with-draft{
    @include flex(column,start,start,16px);
    background:$secondary-blue-5;
    border-radius: 4px;
    padding:20px 16px;
    @media(max-width: 767px){
      padding:20px 12px;
      width:100%;
    }
    .start-with-draft__head{
      font:$font-16-semi;
      color:$dark-80;
      @media(max-width:767px){
        display:none;
      }
    }
    .start-with-draft__content{
      @include flex(row,start,center,5px);
      color:$dark-100;
      font:$font-13-regu;
      @media (max-width:767px) {
        @include flex(column,start,start,0px);
      }
    }
    .start-with-draft__button{
      font:$font-16-semi;
      height:48px;
    }
  }

  .cart-builder-wrap{
    @include flex(column,start,stretch,30px);
    border:1px solid $dark-10;
    border-radius: 4px;
    padding:30px;

    @media(max-width:767px){
      padding:0px;
      border:0px;
      gap:16px;
    }

    &.cart-builder-wrap--finalize{
      gap:10px;
      @media(max-width:767px){
        gap:16px;
      }
    }

    .cart-builder-wrap__head{
      @include flex(row,between,center);
      & > div:first-child{
        @include flex(column,start,start,12px);
        @media(max-width: 767px){
          gap:4px;
        }
      }
    }

    .cart-builder-wrap__head-title{
      font:$font-h2-25-semi;
      @media(max-width: 767px){
        font:$font-h2-22-semi;
      }
    }

    .cart-builder-wrap__back-button{
      @include flex(row,start,center,11px);
      cursor:pointer;
      color:$color-link;
      &:hover{
        color:$color-link-hover;
        :deep(svg path){
          stroke:$color-link-hover;
        }
      }
    }

  }

  .cart-filling{
    @include flex(column,start,center,16px);
    .cart-filling__button-next-step{
      font:$font-16-semi;
      height:48px;
      width:100%;
    }
    @media(max-width:767px){
      gap:16px;
    }
    .cart-filling__build{
      width:100%;
      &.is-loading{
        opacity:0.5;
        position:relative;
        &:after{
          content:'';
          position:absolute;
          top:0px;
          left:0px;
          width:100%;
          height:100%;
        }
      }
    }
    .cart-filling__button-wrap{
      @include flex(column,center,center,16px);
      width:100%;
      max-width:218px;
      @media (max-width: 767px) {
        max-width:100%;
      }
    }
  }

  .cart-finalize{
    @include flex(column,start,stretch,0px);
    .cart-finalize__settings{
      align-self: flex-end;
      width:280px;
    }
    .cart-finalize__offset-10{
      height:10px;
    }
    .cart-finalize__finalize-button-wrap{
      @include flex(column,start,stretch,10px);
    }
  }

  .button-what-is{
    margin-left:auto;
    padding-left:15px;
    padding-right:15px;
    border-radius: 9px;
    height:48px;
    @media(max-width: 767px){
      height:32px;
    }
  }


}
</style>