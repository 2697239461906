<template>
  <div class="sc-project-keyword-group-tooltip-settings">

    <v-form-input-inline v-model="keywordName" :disabled="isGeoAdding"/>

    <div class="geo-targeting" :class="{'is-disabled': isGeoAdding}">
      <v-form-checkbox-slider v-model="keywordIsGeo" size="40"/>
      <span>Geo-Targeting</span>
    </div>

    <v-selected-tags v-show="keywordIsGeo && tagsCountries.length > 0"
                     :tags="tagsCountries"
                     :disabled="isGeoAdding"
                     @remove-tag="(tag) => removeGeo(tag.countryCode,tag.stateCode)"/>

    <v-button-dotted v-show="!isGeoAdding && keywordIsGeo"
                     icon="blue-80-plus-rounded"
                     @click="isGeoAdding=true;"/>

    <div class="select-country" v-show="isGeoAdding && keywordIsGeo">
      <span class="select-country__title">Add new Geo</span>
      <v-sc-select-geo v-model:country="selectedCountry"
                       v-model:state="selectedState"
                       :excluded-geo="excludedGeo"
                       default-country-no-geo
                       default-state-no-geo
                       :default-country-not-selected="{name: 'Not selected', value: 'no_geo'}"
                       :default-state-not-selected="{name: 'Not selected', value: 'no_state'}"/>
      <div class="select-country__group-buttons">
        <v-button color="blue2"
                  class="select-country__button select-country__button--add"
                  @click="addGeo"
                  text="+Add"/>
        <v-button color="blue2-stroke"
                  class="select-country__button select-country__button--cancel"
                  @click="isGeoAdding = false"
                  text="Cancel"/>
      </div>
    </div>

    <v-form-message-error v-if="isDuplicateKeyword" no-margin message-error="This Keyword already exist."/>

    <v-alert-info v-else-if="willBeResetClicksReport">
      Changing the keyword will reset its clicks report
    </v-alert-info>

    <v-button text="Save"
              color="green"
              class="button-controls-save"
              :click-action="[save]"
              :disabled="isDuplicateKeyword || errorNeedCountries || isGeoAdding"/>

  </div>
</template>

<script setup>

import VFormInputInline from "@app-vue/components/VForm/VFormInputInline.vue";
import {computed, getCurrentInstance, inject, onMounted, reactive, ref} from "vue";
import VFormCheckboxSlider from "@app-vue/components/VForm/VFormCheckboxSlider.vue";
import VButton from "@app-vue/components/Base/VButton.vue";
import {useSeoClicksKeywords, useSeoClicksKeywordsGroup, useSeoClicksKeywordsGroups} from "@app-vue/stores/useSeoClicks";
import VScSelectGeo from "@app-vue/components/Base/SeoClicks/VScSelectGeo.vue";
import VSelectedTags from "@app-vue/components/Base/VSelectedTags.vue";
import {compareCountriesAndStates, compareString} from "@app-vue/utils/utils";
import {useStaticData} from "@app-vue/stores/useStaticData";
import VAlertInfo from "@app-vue/components/Base/VAlertInfo.vue";
import VFormMessageError from "@app-vue/components/VForm/VFormMessageError.vue";
import VButtonDotted from "@app-vue/components/Base/VButtonDotted.vue";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const props = defineProps({
  projectId:{},
  keywordsIds:{},
});

const isGeoAdding = ref(false);

const closeModal = inject('closeModal');

const useGroupKeywords = useSeoClicksKeywordsGroup(props.projectId,props.keywordsIds);
const useGroupsKeywords = useSeoClicksKeywordsGroups(props.projectId);

const selectedCountry = ref({name: 'Not selected', value: 'no_geo'});
const selectedState = ref({name: 'Not selected', value: 'no_state'});

const keywordName = ref('');
const keywordIsGeo = ref(false);

const listGeo = reactive([]);

const tagsCountries = computed(() => {
  return listGeo.map((geo) => {
    let country = useStaticData().getRegionByCode(geo?.countryCode);
    return {
      countryCode: geo?.countryCode ? geo.countryCode : null,
      stateCode: geo?.stateCode ? geo.stateCode : null,
      text: country?.name ? country.name : '',
    }
  });
});

const excludedGeo = computed(() => {
  return listGeo.filter((geo) => {
    if(!!geo?.stateCode) return true;

    let country = useStaticData().getRegionByCode(geo?.countryCode);

    if(country.states.length === 0){
      return true;
    }else{
      return false;
    }

  })
});

const keywordNameIsChanged = computed(() => {
  return keywordName.value !== useGroupKeywords.keyword;
});

const keywordIsGeoIsChanged = computed(() => {
  return keywordIsGeo.value !== useGroupKeywords.hasRegion;
});

const listGeoIsChanged = computed(() => {
  if(listGeo.length !== useGroupKeywords.countries.length) return true;

  return useGroupKeywords.countries.some((country) => {
    return !listGeo.some((geo) => {
      return compareCountriesAndStates(country.region, country?.state, geo?.countryCode, geo?.stateCode);
    });
  });
});

const willBeResetClicksReport = computed(() => {
  return keywordNameIsChanged.value || keywordIsGeoIsChanged.value || listGeoIsChanged.value;
});

const isDuplicateKeyword = computed(() => {
  if (!keywordNameIsChanged.value && !keywordIsGeoIsChanged.value) return false;

  return useGroupsKeywords.list.some((keywordsId) => {
    let groupKeyword = useSeoClicksKeywordsGroup(props.projectId, keywordsId);

    // пропускаем из сравнения группу с таким же кейвордом
    if(useGroupKeywords.groupId === groupKeyword.groupId) return false;

    //пропускаем, если найденная группа - удаленная
    if(groupKeyword.is_deleted) return false;

    //если раньше не было региона, а в этой группе есть, то пропускаем
    if(groupKeyword.hasRegion && !useGroupKeywords.hasRegion) return false;

    const isKeywordMatch = compareString(groupKeyword.keyword, keywordName.value);
    const isGeoMatch = groupKeyword.hasRegion === keywordIsGeo.value;

    return isKeywordMatch && isGeoMatch;
  });
});

const errorNeedCountries = computed(() => {
  return keywordIsGeo.value && listGeo.length === 0;
});

function addGeo(){
  let geo = {};
  if(selectedCountry.value?.value && selectedCountry.value?.value!=='no_geo') {
    geo.countryCode = selectedCountry.value.value
  }else{
    return;
  }
  if(selectedState.value?.value && selectedState.value?.value!=='no_state') geo.stateCode = selectedState.value.value;

  if(getIndexGeo(geo?.countryCode, geo?.stateCode) === -1){
    listGeo.push(geo);
    isGeoAdding.value = false;
    let country = useStaticData().getRegionByCode(geo?.countryCode);
    if(country.states.length === 0){
      selectedCountry.value = {name: 'Not selected', value: 'no_geo'};
    }
    selectedState.value = {name: 'Not selected', value: 'no_state'};
  }

}

function getIndexGeo(countryCode,stateCode){
  return listGeo.findIndex((geo) => compareCountriesAndStates(countryCode, stateCode, geo?.countryCode, geo?.stateCode));
}

function removeGeo(countryCode,stateCode){
  let index = getIndexGeo(countryCode,stateCode);
  if(index !== -1) listGeo.splice(index,1);
}

function finishEditing(){
  closeModal();
}

async function save(){
  let [status,response] = await useGroupKeywords.update(keywordName.value, keywordIsGeo.value ? listGeo : []);
  if(status){
    setTimeout(() => {
      let {idCreatedKeywords, packagesUpdatedKeywords} = response;
      if(idCreatedKeywords.length === 0 && packagesUpdatedKeywords.length > 0){
        packagesUpdatedKeywords.forEach((packageUpdatedKeywords) => {
          useSeoClicksKeywords(props.projectId).groupUpdated(packageUpdatedKeywords);
        });
      }else if(idCreatedKeywords.length > 0){
        useSeoClicksKeywords(props.projectId).download();
      }
    },10);
    finishEditing();
  }
}

onMounted(() => {
  keywordName.value = useGroupKeywords.keyword;

  keywordIsGeo.value = useGroupKeywords.hasRegion;

  useGroupKeywords.countries.forEach((country) => {
    if(!!country?.state){
      listGeo.push({
        countryCode: country.region,
        stateCode: country.state,
      });
    }else{
      listGeo.push({
        countryCode: country.region,
      });
    }
  });


});


</script>

<style lang="scss" scoped>
.sc-project-keyword-group-tooltip-settings{
  @include flex(column,start,stretch,20px);
  width:480px;
  background:#fff;
  padding:40px 20px 20px;
  border-radius: 8px;

  @media(max-width:767px){
    width:100%;
    padding:40px 12px 24px;
  }

  .geo-targeting{
    @include flex(row,start,center,8px);
    color:$dark-80;
    &.is-disabled{
      @include state-disabled;
    }
  }

  .select-country{
    @include flex(column,start,stretch,12px);
    padding:16px;
    background:#F8FBFE;
    border-radius: 4px;
    .select-country__title{
      color:$dark-80;
    }
    .select-country__group-buttons{
      @include flex(row,start,center,12px);
      width:100%;
    }
    .select-country__button{
      height:40px;
      padding:0px 16px;
      font-weight: 500;
      &.select-country__button--add{
        padding:0px 45px;
      }
    }
  }

  .button-controls-save{
    width:100%;
    font-weight:500;
  }

}
</style>