<template>
  <div class="lb-step-cart-build-item">
    <div class="cart-item-left">
      <div class="cart-order-number">
        {{orderNumber}}
      </div>
    </div>
    <div class="cart-item-right">
      <div class="cart-item-site">
        <v-form-input-inline ref="refInputSite"
                             placeholder="Url"
                             v-model="useCartItem.website"
                             required
                             :is-error="useCartItem.errors.website"/>
        <v-form-field-list-errors no-margin :errors="useCartItem.errors.website"/>
      </div>
      <div class="cart-item-keyword">
        <v-form-input-inline ref="refInputKeyword"
                             placeholder="Keyword"
                             v-model="useCartItem.keyword"
                             required
                             :is-error="useCartItem.errors.keyword"/>
        <v-form-field-list-errors no-margin :errors="useCartItem.errors.keyword"/>
      </div>
      <v-form-select-backlink-type class="cart-item-type" v-model="typeModel" placeholder="Type"/>
      <div class="cart-item-icon-delete-wrap">
        <v-icon-svg class="cart-item-icon-delete"
                    name="icon-delete-gray-2"
                    as-block
                    @click="deleteItem"/>
      </div>
    </div>
  </div>
</template>

<script setup>

import {useLinkBuildCart, useLinkBuildCartItem, useLinkBuildingType} from "@app-vue/stores/useLinkBuilding";
import VFormInputInline from "@app-vue/components/VForm/VFormInputInline.vue";
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import {computed, ref, watch} from "vue";
import {useBacklinkOptions} from "@app-vue/stores/useStaticData";
import VFormFieldListErrors from "@app-vue/components/VForm/VFormFieldListErrors.vue";
import VFormSelectBacklinkType from "@app-vue/components/VForm/VFormSelectBacklinkType.vue";

const props = defineProps({
  projectUniquekey: {},
  itemLocalKey: {},
  orderNumber: {},
})

const refInputSite = ref();
const refInputKeyword = ref();

const useCart = useLinkBuildCart(props.projectUniquekey);
const useCartItem = useLinkBuildCartItem(props.projectUniquekey, props.itemLocalKey);

const typeOptions = computed(() => {
  return useBacklinkOptions().options.map((option) => {
    return {
      name: useLinkBuildingType().getTitleShort(option.typeConstant),
      value: option.id,
    }
  });
});

function deleteItem(){
  useCart.deleteCartItemById(useCartItem.id);
  if(useCart.items.length === 0){
    useCart.addItemEmpty();
  }
}

const typeModel = ref(useCartItem.option_id);
watch(() => useCartItem.option_id, (option_id) => typeModel.value !== option_id ? typeModel.value = option_id : null);
watch(() => typeModel.value, (typeModel) => {
  if(typeModel?.value !== useCartItem.option_id){
    useCartItem.$patch({option_id: typeModel,});
    useCartItem.save();
  }
});

let timeoutUpdate;
watch(() => [useCartItem.website?.length, useCartItem.keyword?.length], () => {
  useCartItem.check();
  clearTimeout(timeoutUpdate);
  timeoutUpdate = setTimeout(() => {
    if(useCartItem.hasChanges){
      useCartItem.save();
    }
  },1000);
});

</script>

<style lang="scss" scoped>
.lb-step-cart-build-item{
  @include flex(row,between,start);

  @media(max-width:767px){
    background:$secondary-blue-5;
    padding:16px 12px;
    border-radius: 4px;
  }
  .cart-item-left{
    @include flex(row,start,center);
    width:27px;
    flex-shrink: 0;
    height:48px;
    @media (max-width: 767px){
      width:19px;
      align-items: start;
    }
  }
  .cart-item-right{
    @include flex(row,between,start,20px);
    flex-grow: 2;
    @media (max-width: 767px) {
      gap:6px;
      flex-wrap:wrap;
    }
  }

  .cart-order-number{
    color:$dark-80;
    @media (max-width: 767px){
      color:$dark-40;
    }
  }

  .cart-item-site{
    flex-grow: 2;
    @media(max-width: 767px){
      background:#fff;
      width:100%;
    }
  }
  .cart-item-keyword{
    width:185px;
    @media(max-width: 767px){
      background:#fff;
      width:100%;
    }
  }
  .cart-item-type{
    width:105px;
    height:48px;
  }
  .cart-item-icon-delete{
    cursor:pointer;
    stroke:$dark-20;
    transition-duration: 0.2s;
    @media(max-width: 767px){
      stroke:$dark-30;
    }
    &:hover{
      stroke:$optional-red_120;
    }
  }
  .cart-item-icon-delete-wrap{
    @include flex(row,center,center);
    height:48px;
  }
}
</style>