<template>
  <v-white-container-wrap :has-top-bar="projectStore.is_deleted || !projectStore.is_active">
    <template #top-bar>
      <v-project-bar-danger v-if="!projectStore.is_active && !projectStore.is_deleted"
                            class="bar-is-not-active"
                            text="Project paused"
                            :button-props="{
                              clickAction:[projectStore.saveIsActive,true],
                              color:'green',
                              icon:'icon-play-white-v2',
                              text:'Resume',
                            }"/>
    </template>
    <template #content>
      <div class="lb-project-head lbph">

        <v-overlay-deleted-project v-if="projectStore.is_deleted"
                                   :button-props="{clickAction: [projectStore.saveIsDeleted, false]}"/>

        <div class="lbph__top">

          <div class="lbph__top-title">

            <div class="lbph__top-title-left">
              <v-form-string-input
                  v-if="!projectStore.isDisabled"
                  label="Project name"
                  :value="projectStore.name"
                  ref="refProjectTitle"
                  @save="saveName"
                  required
                  data-name="project-name"
              >
                <template v-slot:default>
                  <v-head-title is-ends-ellipsis>
                    {{projectStore.name}}
                  </v-head-title>
                </template>
              </v-form-string-input>
              <v-head-title v-else is-ends-ellipsis>
                {{projectStore.name}}
              </v-head-title>
            </div>

            <div class="lbph__top-title-right" v-if="!projectStore.is_deleted">
              <div class="lbph__controls">
                <template v-if="!isMobile">
                  <v-form-checkbox-slider
                      type="events"
                      :value="projectStore.is_active"
                      ref="refProjectStatus"
                      @save="saveIsActive"
                  />

                  <v-icon-svg class="lbph__controls-delete" name="delete-icon" @click="refDeleteModalConfirm.confirm();"/>

                </template>
                <template v-else>
                  <div class="lbph__controls-kebab" ref="refKebab" @click="refTooltipKebab.toggle()">
                    <v-icon-svg name="kebab" class="lbph__controls-kebab-icon"/>
                  </div>
                  <v-tooltip
                      placement="bottom-end"
                      ref="refTooltipKebab"
                      :style="{
                      width:'100%',
                      maxWidth:'195px',
                      padding:'0px 0px',
                      borderRadius:'4px',
                  }"
                      :btn-close-on="false"
                      class="v-tooltip-select vts"
                      :get-linked-block="() => refKebab"
                      :max-height-visible-area="true"
                  >
                    <template v-slot:content>
                      <div class="lbph__controls-tooltip">
                        <div class="lbph__controls-tooltip-item lbph__controls-tooltip-active">
                          <div class="lbph__controls-tooltip-active-text">
                            Project active
                          </div>
                          <v-form-checkbox-slider
                              type="events"
                              :value="projectStore.is_active"
                              ref="refProjectStatus"
                              @save="saveIsActive"
                          />
                        </div>
                        <div class="lbph__controls-tooltip-item lbph__controls-tooltip-delete" @click="refDeleteModalConfirm.confirm();">
                          <v-icon-svg class="lbph__controls-tooltip-delete-icon" name="delete-icon"/>
                          <div class="lbph__controls-tooltip-delete-text">
                            Delete project
                          </div>
                        </div>
                      </div>
                    </template>
                  </v-tooltip>
                </template>

              </div>
            </div>

          </div>

          <div class="lbph__keyword" :class="projectStore.isDisabled ? 'is-disabled' : ''">
            <div class="lbph__keyword_setting">Keyword: </div>
            <v-form-string-input
                class="lbph__keyword_wrap"
                label="Keyword"
                :value="projectStore.keyword"
                ref="refProjectKeyword"
                @save="saveKeyword"
                required
                data-name="keyword"
            >
              <template v-slot:default>
                <div class="lbph__keyword_inner">
                  {{projectStore.keyword}}
                </div>
              </template>
            </v-form-string-input>
          </div>

          <v-form-string-input
              label="Website"
              :value="projectStore.url"
              ref="refProjectUrl"
              @save="saveUrl"
              required
              :class="projectStore.isDisabled ? 'is-disabled' : ''"
              data-name="url"
          >
            <template v-slot:default>
              <v-link :href="projectStore.correctUrlLink" target="_blank" is-outer>{{projectStore.shortUrl}}</v-link>
            </template>
          </v-form-string-input>

        </div>

        <div class="lbph__body" :class="mainClassBody">
          <div class="lbph__body-left">

            <div class="lbph__settings">

              <div class="lbph__settings-row">
                <div class="lbph__settings-left">
                  Category:
                </div>

                <div class="lbph__settings-right">
                  <v-form-string-select
                      :options="backlinkCategories"
                      :value="projectStore.categoryForSelect"
                      @save="saveCategory"
                      :tooltip-options="{placement:'bottom-end', style:{maxWidth:'194px'}}"
                      ref="refBacklinkCategory"
                      data-name="category_id"
                  >
                    <template v-slot:default>
                  <span class="lbph__settings-category" v-if="projectStore.categoryForSelect?.name">
                    {{projectStore.categoryForSelect?.name}}
                  </span>
                      <span v-else>
                    Not selected
                  </span>
                    </template>
                  </v-form-string-select>
                </div>
              </div>

              <div class="lbph__settings-row lbph__settings-row--comment" :class="projectStore.hasAnalytics ? 'lbph__settings-row--vertical' : ''">
                <div class="lbph__settings-left">
                  Comments:
                </div>

                <div class="lbph__settings-right">
                  <v-form-string-input
                      label="Comments"
                      :value="projectStore.owner_comments"
                      ref="refProjectComment"
                      @save="saveComment"
                      required
                      :can-be-empty="true"
                      data-name="owner_comments"
                  >
                    <template v-slot:default>
                      <div class="lbph__settings-comment">
                        <template v-if="projectStore.owner_comments !== null && projectStore.owner_comments.trim() !== ''">
                          {{projectStore.owner_comments}}
                        </template>
                        <template v-else>
                          No comments
                        </template>
                      </div>
                    </template>
                  </v-form-string-input>
                </div>
              </div>

            </div>

          </div>
          <div class="lbph__body-right" v-if="useLinkBuildProject(projectUniquekey).hasAnalytics">
            <lb-project-analytics :project-uniquekey="projectUniquekey"/>
          </div>
        </div>

        <v-modal-confirm ref="refDeleteModalConfirm" @confirm="deleteProject">
          <template v-slot:title>
            Delete Project
          </template>
          <template v-slot:description>
            Do you really want to delete a project ?
          </template>
        </v-modal-confirm>

      </div>
    </template>
  </v-white-container-wrap>

</template>

<script setup>

import VFormStringInput from "@app-vue/components/VForm/VFormStringInput.vue";
import VHeadTitle from "@app-vue/components/Base/VHeadTitle.vue";
import {computed, getCurrentInstance, ref, watch} from "vue";
import VLink from "@app-vue/components/Base/VLink.vue";
import VFormCheckboxSlider from "@app-vue/components/VForm/VFormCheckboxSlider.vue";
import LbProjectAnalytics from "@app-vue/components/LinkBuilding/LbProjectAnalytics.vue";
import {useLinkBuildProject} from "@app-vue/stores/useLinkBuilding";
import VFormStringSelect from "@app-vue/components/VForm/VFormStringSelect.vue";
import {useStaticData} from "@app-vue/stores/useStaticData";
import VModalConfirm from "@app-vue/components/Base/VModalConfirm.vue";
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import VTooltip from "@app-vue/components/Base/VTooltip.vue";
import VWhiteContainerWrap from "@app-vue/components/Base/VWhiteContainerWrap.vue";
import VProjectBarDanger from "@app-vue/components/Base/VProjectBarDanger.vue";
import VOverlayDeletedProject from "@app-vue/components/Base/VOverlayDeletedProject.vue";

const {proxy} = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const refProjectTitle = ref();
const refProjectKeyword = ref();
const refProjectUrl = ref();
const refProjectStatus = ref();
const refProjectComment = ref();
const refBacklinkCategory = ref();

const props = defineProps({
  projectUniquekey: {}
});

const refDeleteModalConfirm = ref();
const refKebab = ref();
const refTooltipKebab = ref();

const projectStore = useLinkBuildProject(props.projectUniquekey);

const mainClassBody = computed(() => {
  let response = [];
  if (projectStore.hasAnalytics) response.push('lbph__body--has-analytics');
  if (projectStore.isDisabled) response.push('is-disabled');
  return response;
});

watch(() => refProjectKeyword.value?.localValue, (keyword) => {
  let validate = projectStore.validateKeyword(keyword);
  if(validate !== null){
    refProjectKeyword.value.setError(validate);
  }else{
    refProjectKeyword.value.removeError();
  }
});

const saveKeyword = async (keyword) => {
  let [status, message] = await projectStore.saveKeyword(keyword);
  status ? refProjectKeyword.value.saveWithSuccess() : refProjectKeyword.value.saveWithError(message);
}

const saveUrl = async (url) => {
  let [status, message] = await projectStore.saveUrl(url);
  status ? refProjectUrl.value.saveWithSuccess() : refProjectUrl.value.saveWithError(message);
}

const saveComment = async (comment) => {
  let [status, message] = await projectStore.saveComment(comment);
  status ? refProjectComment.value.saveWithSuccess() : refProjectComment.value.saveWithError(message);
}

const saveName = async (name) => {
  let [status, message] = await projectStore.saveName(name);
  status ? refProjectTitle.value.saveWithSuccess() : refProjectTitle.value.saveWithError(message);
}

const saveIsActive = async (is_active) => {
  let [status, message] = await projectStore.saveIsActive(is_active);
  status ? refProjectStatus.value.saveWithSuccess() : refProjectStatus.value.saveWithError(message);
}

const saveCategory = async (category) => {
  let [status, message] = await projectStore.saveCategory(category.value);
  status ? refBacklinkCategory.value.saveWithSuccess() : refBacklinkCategory.value.saveWithError(message);
}

const backlinkCategories = computed(() => {
  return [{'value': null, 'name': 'Not selected'}, ...useStaticData().backlinksCategoriesForSelect];
});

const deleteProject = async () => {
  let [status, message] = await projectStore.saveIsDeleted(true);
  if (status) {
    //window.location.href=useRoutes().url('backlinks-project.list');
    refDeleteModalConfirm.value.closeWithSuccess();
  } else {
    refDeleteModalConfirm.value.closeWithError(message)
  }
}


</script>

<style lang="scss" scoped>
.bar-is-not-active{
  border-radius:0px;
}

.lb-project-head.lbph {
  display: flex;
  gap: 20px;
  flex-direction: column;
  @media(max-width: 767px) {
    gap: 16px;
  }

  .lbph__top {
    display: flex;
    gap: 10px;
    flex-direction: column;
    width: 100%;
    align-items: stretch;
    border-bottom: 1px solid #D6E3EF80;
    padding-bottom: 20px;
    @media(max-width: 767px) {
      padding-bottom: 16px;
      gap: 12px;
    }

    .lbph__top-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;

      .lbph__top-title-left {
        flex-grow: 2;
        min-width:0;
      }

      .lbph__top-title-right {
        flex-shrink: 0;
      }

      @media(max-width: 767px) {
        align-items: flex-start;
      }
    }
  }

  .lbph__body {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    align-items: flex-start;
    @media(max-width: 767px) {
      flex-direction: column;
      align-items: stretch;
      gap: 16px;
    }

    .lbph__body-left {

    }

    .lbph__body-right {
      width: 530px;
      flex-shrink: 0;
      @media(max-width: 767px) {
        width: 100%;
      }
    }

    &.lbph__body--has-analytics {
      .lbph__body-left {
        flex-grow: 2;
      }
    }

  }

  .lbph__keyword {
    display: flex;
    align-items: center;
    gap: 9px;

    .lbph__keyword_setting {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #596C8F;
    }

    .lbph__keyword_wrap {
      flex-grow: 2;
    }

    .lbph__keyword_inner {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #384967;
    }
  }

  .lbph__settings {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .lbph__settings-row {
      display: flex;
      gap: 10px;

      &.lbph__settings-row--vertical {
        flex-direction: column;
        @media(max-width: 767px) {
          gap: 4px;
        }
      }

      @media(max-width: 767px) {
        &.lbph__settings-row--comment {
          flex-direction: column;
          gap: 4px;
        }
      }
    }

    .lbph__settings-left {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #596C8F;
      min-width: 100px;
    }

    .lbph__settings-right {

    }

    .lbph__settings-comment {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #384967;
    }

    .lbph__settings-category {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #384967;
    }
  }

  .lbph__controls {
    display: flex;
    gap: 13px;
    align-items: center;

    .lbph__controls-delete {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      opacity: 0.5;
      transition-duration: 0.2s;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }

    .lbph__controls-kebab {
      width: 32px;
      height: 24px;
      padding-right: 8px;

      .lbph__controls-kebab-icon {
        width: 24px;
        height: 24px;
      }
    }
  }

  .is-disabled {
    position: relative;
    opacity: 0.5 !important;

    &:after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
    }
  }

}
</style>
<style>
.lbph__controls-tooltip {
  .lbph__controls-tooltip-item {
    display: flex;
    height: 44px;
    align-items: center;
    padding: 0px 16px;
    border-bottom: 1px solid #D6E3EF80;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #384967;

    &:last-child {
      border-bottom: 0px;
    }

    &.lbph__controls-tooltip-active {
      justify-content: space-between;
    }

    &.lbph__controls-tooltip-delete {
      justify-content: flex-start;
      gap: 11px;

      .lbph__controls-tooltip-delete-icon {
        opacity: 0.5;
      }
    }
  }
}
</style>