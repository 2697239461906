import {defineStore} from "pinia";
import {ApiResource} from "@app-vue/plugins/ApiExtends";
import {useSecretCodes, useStaticData} from "@app-vue/stores/useStaticData";
import {isArray, isObject, isString} from "lodash";
import {useSeoClicksKeywords} from "@app-vue/stores/useSeoClicks";
import {compareCountries, compareStates} from "@app-vue/utils/utils";

export const useSuggestKeywords = defineStore('extractKeywords', {
    state: () => {
        return {
            currentUrl: null,
            currentLocation: null,
            selectedLocation: {'name': 'No Geo-targeting', value: 'no_geo'},
            selectedState: null,
            optionNoGeo: {'name': 'No Geo-targeting', value: 'no_geo'},
            suggestedKeywordsOriginal: [],
            selectedKeywords: [],
            message: '',
            loading: false,
            hasFirstDownload: false,
            excludedProjectId: null,
        }
    },
    getters: {
        selectedLocationIsNoGeo: (state) => {
            return state.selectedLocation?.value === 'no_geo';
        },

        suggestedKeywords: (state) => {
            return state.suggestedKeywordsOriginal.map((keyword) => {
                if(keyword.geo?.toLowerCase() === 'gb'){
                    keyword.geo = 'uk';
                }
                let is_suggested = (
                    state.selectedLocation?.value?.toLowerCase() === state.currentLocation
                    || (
                        state.selectedLocation?.value === 'no_geo'
                        && !state.currentLocation
                    )
                );
                return {
                    countryCode: state.selectedLocation?.value ?? null,
                    name: keyword.keyword,
                    is_suggested: is_suggested,
                    state_code: state.selectedState ? state.selectedState.value : null,
                }
            })
        },

        remainingSuggestedKeywords(state){
            return this.suggestedKeywords.filter((item) => {
                return !(state.hasSelectedKeyword(item) || state.hasKeywordInExcludedProject(item));
            });
        },

        selectedKeywordsForSend: (state) => {
            let response = [];
            state.selectedKeywords.forEach((item) => {
                if(!item.hasOwnProperty('name')) return

                let responseItem = {
                    keyword: item.name,
                    is_suggested: item?.is_suggested ?? false,
                };

                if(!!item?.countryCode && item.countryCode !== 'no_geo'){
                    responseItem.geo = item.countryCode?.toLowerCase();

                    if(!!item?.state_code){
                        let state = useStaticData().getStateByCode(item.countryCode, item.state_code);
                        if(state){
                            responseItem.state_id = state.id;
                        }
                    }
                }

                response.push(responseItem);
            });
            return response;
        },

        keywordsNotFound(state){
            return state.hasFirstDownload && this.suggestedKeywords.length === 0;
        },

        regionsSelectOptions: (state) => {
            return [state.optionNoGeo].concat(useStaticData().regionsForSelect);
        },

        stateOptions: (state) => {

            let regionCode = state.selectedLocation?.value;
            if(!isString(regionCode)) return [];
            regionCode = regionCode.toLowerCase();

            let region = useStaticData().regions.find((region) => region.code.toLowerCase() === regionCode);
            if(!region || !isObject(region)) return [];

            let states = region?.states;
            if(!Array.isArray(states) || states.length === 0) return [];

            return states.sort((a, b) => {
                if (a.sort_order !== b.sort_order) {
                    return a.sort_order - b.sort_order;
                }else{
                    return a.name.localeCompare(b.name);
                }
            }).map((item) => {
                return {
                    name: item.name,
                    value: item.code,
                }
            });

        },

    },
    actions: {

        async updateSuggestedKeywords(url, geoCode){
            let resource = new ApiResource({
                url:'/api/v2/keywords/get-extract-keywords',
                method:'get',
                params: {
                    url: url,
                    secret: useSecretCodes().apiSecretAuth,
                    location: geoCode,
                }
            });

            this.$patch({loading: true, hasFirstDownload:true});
            await resource.downloadAsync();
            this.$patch({loading: false});

            if(resource.isSuccess()){
                this.$patch((state) => {

                    state.currentUrl = url;
                    if(resource.data.geo?.toLowerCase() === 'gb'){
                        resource.data.geo = 'uk';
                    }
                    state.currentLocation = resource.data.geo;
                    state.message = resource.data.message;
                    state.suggestedKeywordsOriginal = [];

                    if(state.currentLocation!==null && state.selectedLocation?.value?.toLowerCase() !== state.currentLocation?.toLowerCase()){
                        let region = state.currentLocation?.toLowerCase();
                        let foundRegion = this.regionsSelectOptions.find((item) => item.value.toLowerCase() === region);
                        if(foundRegion){
                            state.selectedLocation = foundRegion;
                        }
                    }
                    if(isArray(resource.data.keywords) && resource.data.keywords.length > 0){
                        resource.data.keywords.forEach((keyword) => {
                            state.suggestedKeywordsOriginal.push(keyword);
                        });
                    }

                });
            }else{
                this.reset();
            }

        },

        isSuggestedKeyword(tag){

            return this.suggestedKeywords.find((suggestedTag) => {
                return this.compareSelectedKeywords(suggestedTag,tag) && suggestedTag?.is_suggested
            });

        },

        addSelectedKeyword(tag){
            if(!tag || !tag.hasOwnProperty('name') || !tag.name){return}
            this.$patch((state) => {
                let forInsert = {name: tag.name};
                if(tag.hasOwnProperty('countryCode') && tag.countryCode){
                    forInsert.countryCode = tag.countryCode;
                }

                if(tag.hasOwnProperty('state_code') && tag.state_code){
                    forInsert.state_code = tag.state_code;
                }

                if(this.isSuggestedKeyword(tag)){
                    forInsert.is_suggested = true;
                }


                if(!this.hasSelectedKeyword(forInsert)){
                    state.selectedKeywords.push(forInsert);
                }

            });
        },

        compareSelectedKeywords(tagA, tagB, options = {checkState: false,}){
            options = Object.assign({checkState: false,}, options);

            if(!compareCountries(tagA?.countryCode, tagB?.countryCode)) return false;

            if(options.checkState){
                if(!compareStates(tagA?.state_code, tagB.state_code)){
                    return false;
                }
            }

            return tagA.name === tagB.name;
        },

        hasSelectedKeyword(tag){

            let find = this.selectedKeywords.find((item) => {
                return this.compareSelectedKeywords(tag, item, {checkState: true});
            });
            return find !== undefined;

        },
        /**
         * Проверяем тег, есть ли он в списке кейвордов. Удаленные кейворды не проверяем
         * @param tag - тег, который нужно проверить, есть ли он в списке кейвордов
         * @return {boolean}
         */
        hasKeywordInExcludedProject(tag){
            return Object.values(useSeoClicksKeywords(this.excludedProjectId)._keywords).some((keyword) => {
                if(keyword.is_deleted) return false;
                let tagKeyword = {
                    countryCode:keyword.region,
                    state_code: keyword.state,
                    name: keyword.keyword,
                }
                return this.compareSelectedKeywords(tag,tagKeyword,{checkState: true});
            });
        },

        removeSelectedKeyword(tag){
            let removeIndexes = [];
            this.selectedKeywords.forEach((value,index) => {
                if(this.compareSelectedKeywords(tag,value, {checkState: true})){
                    removeIndexes.push(index);
                }
            });

            removeIndexes.sort((a, b) => b - a);

            this.$patch((state) => {
                removeIndexes.forEach(index => {
                    state.selectedKeywords.splice(index, 1);
                });
            });

        },

        resetSuggestedForSelectedKeywords(){
            this.$patch((state) => {
                state.selectedKeywords.forEach((selectedKeyword, key) => {
                    state.selectedKeywords[key].is_suggested = false;
                });
            });
        },

        resetSelectedState(){
            this.$patch({selectedState: null,});
        },

        updateExcludedProjectId(projectId){
            if(projectId){
                this.$patch({excludedProjectId:projectId});
            }else{
                this.$patch({excludedProjectId: null});
            }
        },

        reset(){
            this.$patch({
                currentUrl: null,
                currentLocation: null,
                suggestedKeywordsOriginal: [],
                message:'',
                loading:false,
                hasFirstDownload:false,
            })
        },

    }
})